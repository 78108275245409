import React, { useContext } from 'react';
import DetailsContext from '@components/workflow/details-context';
import Section from '@components/workflow/section';
import CoreInlineEditField from '@components/workflow/section/core/inline-edit-field';
import InlineEditField from '@components/workflow/section/inline-edit-field';
import { getWorkflowCustomFieldSections } from '@constants/config';
import { canAccessWorkflow, canRespondTaskActivity } from '@utils/permission-utils';
import { getTaskFields } from '@utils/workflow-utils';
import './response.scss';

const Response = () => {
  const { activity, error, task, taskType } = useContext(DetailsContext);

  const section = getWorkflowCustomFieldSections().find(item => item.name === 'Response');
  const { activities } = task;
  const activityIndex = activities.findIndex(act => act.id === activity.id);
  const fields = getTaskFields(section, taskType);
  const canEdit = canAccessWorkflow('taskactivity', 'change') && canRespondTaskActivity(activity, task.roles);
  const data = {
    ...activity,
    owner: task.owner,
    roles: task.roles || []  // inject task assigned teams.
  };
  const fieldPrefix = `activities.${activityIndex}`;
  const props = { data, fieldPrefix, error, section };
  return (
    <div styleName="activities-response">
      <Section id={section.id} name={section.name}>
        {fields.map((field, index) => {
          if (field.core) {
            return <CoreInlineEditField disabled={!canEdit} key={field.id} {...props} {...field} />;
          }
          return <InlineEditField {...props} field={field} key={field.id} position={index} />;
        })}
      </Section>
    </div>
  );
};

export default Response;
