import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { colors, dotmapsDarkRed, dotmapsRed } from '@constants/colors';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import './top-bar-view.scss';

const useStyles = makeStyles({
  root: ({ color }) => ({
    color: colors.neutral.white,
    backgroundColor: color,
    '&:hover': {
      backgroundColor: color
    }
  })
});

const isSelected = (selected, value) => selected === value ? dotmapsDarkRed : dotmapsRed;

const View = ({ day, month, selected, week }) => {
  const classesDay = useStyles({ color: isSelected(selected, 'day')});
  const classesWeek = useStyles({ color: isSelected(selected, 'week')});
  const classesMonth = useStyles({ color: isSelected(selected, 'month')});

  return (
    <div styleName="view-container">
      <Button onClick={day} classes={{ root: classesDay.root }}>Day</Button>
      <Button onClick={week} classes={{ root: classesWeek.root }}>Week</Button>
      <Button onClick={month} classes={{ root: classesMonth.root }}>Month</Button>
    </div>
  );
};

View.propTypes = {
  day: PropTypes.func,
  month: PropTypes.func,
  selected: PropTypes.string,
  week: PropTypes.func
};

export default memo(View);
