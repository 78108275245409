import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getCalendarEntity } from '@constants/config';
import EventIcon from '@icons/event-icon';
import { Icon } from '@mui';
import DefaultDate from '@shared/formatted-date-helper';
import FlexSeparator from '@shared/helpers/flex-separator';
import { canEditEntities } from '@utils/permission-utils';
import './event-details-popup.scss';

const EventDetailsPopup = ({ selectedEvent, windowClose }) => {
  const editEvents = canEditEntities(null, getCalendarEntity());
  return (
    <div styleName={`popup-container ${editEvents ? '' : 'popup-container-read-only-events'}`}>
      <div styleName="row">
        <div><EventIcon /><span styleName="title">{selectedEvent.title}</span></div>
        <FlexSeparator />
        <div>
          <Icon onClick={windowClose}>
            close
          </Icon>
        </div>
      </div>
      <div styleName="row">
        <div styleName="data-label">When</div>
        <DefaultDate value={selectedEvent.start} />
        <span> - </span>
        <DefaultDate value={selectedEvent.end} />
      </div>
      <div styleName="row">
        <div styleName="data-label">Where</div>
        <div>{selectedEvent.address}</div>
        <Link styleName="view-on-map" to={`/map?${getCalendarEntity()}=${selectedEvent.id}`}>
          <Icon onClick={windowClose} color="#2B95FF" style={{ fontSize: '1.5em' }}>
            place
          </Icon>
          View on map
        </Link>
      </div>
      <div styleName="row">
        <div styleName="data-label">Created by</div><div>{selectedEvent.agency}</div>
      </div>
      <div styleName="row">
        <div styleName="data-label">Event type</div><div>{selectedEvent.typeName}</div>
      </div>
    </div>
  );
};

EventDetailsPopup.propTypes = {
  selectedEvent: PropTypes.object,
  windowClose: PropTypes.func
};

export default EventDetailsPopup;
