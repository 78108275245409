// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-shared-___horizontal-drawer__horizontal-drawer-container{background-color:#ffffff;box-shadow:rgba(0,0,0,0.4) 0px 0px 8px 2px;bottom:0px;display:flex;left:0px;height:200px;position:fixed;transition:opacity cubic-bezier(0.23, 1, 0.32, 1);width:100%;z-index:24}
`, "",{"version":3,"sources":["webpack://./src/components/shared/horizontal-drawer.scss"],"names":[],"mappings":"AAGA,yEACE,wBAAyB,CACzB,0CAA8C,CAC9C,UAAW,CACX,YAAa,CACb,QAAS,CACT,YAAa,CACb,cAAe,CACf,iDAAkD,CAClD,UAAW,CACX,UAAW","sourcesContent":["@import '../../styles/vars';\n@import '../../styles/mixins';\n\n.horizontal-drawer-container {\n  background-color: #ffffff;\n  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 8px 2px;\n  bottom: 0px;\n  display: flex;\n  left: 0px;\n  height: 200px;\n  position: fixed;\n  transition: opacity cubic-bezier(0.23, 1, 0.32, 1);\n  width: 100%;\n  z-index: 24;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"horizontal-drawer-container": `src-components-shared-___horizontal-drawer__horizontal-drawer-container`,
	"horizontalDrawerContainer": `src-components-shared-___horizontal-drawer__horizontal-drawer-container`
};
export default ___CSS_LOADER_EXPORT___;
