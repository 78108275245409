import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { colors } from '@constants/colors';
import { DASHBOARD_EDIT_GROUPS } from '@constants/component-configs';
import { Icon, IconButton } from '@mui';
import { hasGroups } from '@utils/permission-utils';
import UserActions from './user-actions';

const ViewActions = ({ dataType, dataId }) => {
  const permissions = DASHBOARD_EDIT_GROUPS[dataType];
  if (permissions && (permissions === true || hasGroups(permissions))) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Link to={`/list/details/${dataType}/${dataId}?edit`}>
          <IconButton tooltip="Edit">
            <Icon color={colors.neutral.white}>edit</Icon>
          </IconButton>
        </Link>
        {dataType === 'user' && <UserActions />}
      </div>
    );
  }
  return null;
};

ViewActions.propTypes = {
  dataId: PropTypes.string,
  dataType: PropTypes.string
};

export default ViewActions;
