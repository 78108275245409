/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLayout } from '@components/settings/actions';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import './settings.scss';

const Layout = () => {
  const { global: { layout } } = useSelector(state => state.config);
  const dispatch = useDispatch();

  const onChange = event => dispatch(updateLayout(event.target.value));

  return (
    <div styleName="settings-form-container">
      <div styleName="title">
        <div styleName="text">Layout</div>
      </div>
      <div styleName="hint">
        Switch between the old and new layout.
      </div>
      <div styleName="form">
        <FormControl component="fieldset">
          <RadioGroup aria-label="layout" name="layout" value={layout} onChange={onChange}>
            <FormControlLabel value="old" control={<Radio color="primary" />} label="Old Design" />
            <FormControlLabel value="default" control={<Radio color="primary" />} label="New Design" />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

export default memo(Layout);
