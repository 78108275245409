import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as colors from '@constants/colors';
import { Icon } from '@mui';
import Tooltipable from '@shared/tooltipable';
import './snap-tooltip.scss';

const renderIcon = props => (
  <Icon
    color={colors.dotmapsBlack60}
    styleName="icon"
    style={{ fontSize: '18px', display: 'block' }}
    {...props}
  >
    help
  </Icon>
);

const SnapTooltip = ({ tooltipStyle }) => {
  const { cityName } = useSelector(state => state.config);
  return (
    <div styleName="snap-tooltip-container">
      <Tooltipable hoverElement={renderIcon} tooltipStyle={tooltipStyle}>
        <div styleName="snap-tooltip-field-tooltip">
          This uses the {cityName || 'City'}'s street centerline data.
          When this switch is turned off, the app will use Google's Geocoding service to return an address.
        </div>
      </Tooltipable>
    </div>
  );
};

SnapTooltip.propTypes = {
  tooltipStyle: PropTypes.object
};

SnapTooltip.defaultProps = {
  tooltipStyle: {}  // Override/reset the default 'Tooltipable' style.
};

export default memo(SnapTooltip);
