import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { push } from 'connected-react-router';
import { includes, isEmpty } from 'lodash';
import { fetchDatatableData } from '@actions/data-table-actions';
import * as colors from '@constants/colors';
import {
  getEntitiesListConfig,
  getEntityTypeLabel
} from '@constants/config';
import { dataTableEmptyResultsIconStyles } from '@constants/mui-theme';
import EmptyResultsIcon from '@icons/empty-results-icon';
import Button from '@material-ui/core/Button';
import { Icon } from '@mui';
import {
  getDataTableColumns,
  getFiltersSelector,
  getLoader,
  optimizeRowsForDataTable
} from '@selectors/data-table-selector';
import Body from '@shared/data-table/body';
import Header from '@shared/data-table/header';
import DotmapsLoader from '@shared/dotmaps-loader';
import { getEmptyLabel } from '@utils/data-table-utils';
import { canAccessEntity, canAccessGroup } from '@utils/permission-utils';
import { shallowEqual } from '@utils/react-utils';
import './data-table.scss';

class DataTable extends Component {
  componentDidMount() {
    const { dataType, filters, subType } = this.props;
    this.props.fetchDatatableData(dataType, subType, filters);
  }

  componentDidUpdate(prevProps) {
    const { dataType, filters, rows, subType } = this.props;
    // If the data type changed, fetch the data if we didn't already did it.
    if (
      dataType !== prevProps.dataType ||
      (dataType === prevProps.dataType && subType !== prevProps.subType)
    ) {
      const isFilteredEmpty = isEmpty(rows);
      const isDataTypeEmpty = this.getIsDataTypeEmpty();
      if (isFilteredEmpty && !isDataTypeEmpty) {
        this.props.fetchDatatableData(dataType, subType, filters);
        return;
      }
    }
    // If all data is already loaded only fetch the data if filters changes:
    let prevRawFilters = prevProps.rawFilters[dataType];
    let thisRawFilters = this.props.rawFilters[dataType];
    if (subType && subType !== dataType) {
      prevRawFilters = R.pathOr({}, [subType], prevRawFilters);
      thisRawFilters = R.pathOr({}, [subType], thisRawFilters);
    }
    if (!shallowEqual(prevRawFilters, thisRawFilters)) {
      this.props.fetchDatatableData(dataType, subType, filters);
    }
  }

  onScroll = event => {
    const table = document.getElementById('table-content-container-fixed');
    // When we scroll add a border and box shadow to the fixed table, to visually
    // limit this table from the scrolling one.
    if (event.currentTarget.scrollLeft > 0) {
      table.style = 'border-right: 2px solid #EBEBEB; box-shadow: 3px 0 5px -2px #EBEBEB;';
    } else {
      table.style = 'border: none: box-shadow: none;';
    }
  };

  handleCreateDataType = () => {
    const { dataType, subType } = this.props;
    if (dataType === 'group') {
      this.props.push(`/group/${subType}/new`);
      return;
    }
    this.props.push(`/${dataType}/new`);
  };

  handleUploadFile = () => {
    // Open the file upload dialog:
    // (it has to be done with a simulated click, since the
    // dialog open state is handled internally in the component,
    // not with Redux like all other dialogs).
    document.getElementById('dataTableBatchUploadButton').click();
  };

  getEmptyDataTypeCreateButton = () => {
    const { dataType, subType } = this.props;
    if (
      (includes(getEntitiesListConfig(), dataType) && canAccessEntity(null, dataType, 'add')) ||
      (dataType === 'group' && canAccessGroup(subType, 'add'))
    ) {
      const label = getEntityTypeLabel(dataType);
      return (
        <Button
          color="primary"
          onClick={this.handleCreateDataType}
          variant="contained"
        >
          create {label}
        </Button>
      );
    }
    if (dataType === 'batch' && canAccessEntity(null, 'batchfile', 'add')) {
      return (
        <Button
          color="primary"
          onClick={this.handleUploadFile}
          variant="contained"
        >
          upload file
        </Button>
      );
    }
    // There's no create button for overlaps.
    return null;
  };

  getIsDataTypeEmpty = () => {
    const { dataType, dataTypesCount, subType } = this.props;
    if (dataTypesCount) {
      if (dataType === 'overlap') {
        return dataTypesCount.overlap[subType] === 0;
      }
      return dataTypesCount[dataType] === 0;
    }
    return false;
  };

  render() {
    const {
      columns,
      dataType,
      embedded,
      embeddedColor,
      loader,
      rows,
      subType
    } = this.props;
    // Use different styling for when this component is embedded:
    const styleName = `datatable-container${embedded ? '-embedded' : ''}`;
    const loaderColor = embedded ? embeddedColor : colors.dotmapsBlue;
    const columnsFixed = columns ? columns.slice(0, 1) : null;
    const columnsScroll = columns ? columns.slice(1) : null;
    // True if there's no data (either the database has no data or there are no results due to filtering).
    const isFilteredDataTypeEmpty = isEmpty(rows);
    // True if there's no data at all in the database for this data type (regardless of filtering).
    const isDataTypeEmpty = this.getIsDataTypeEmpty();
    return (
      <div styleName={styleName}>
        <DotmapsLoader color={loaderColor} display={loader} />
        {!isFilteredDataTypeEmpty && !isDataTypeEmpty &&
          <div id="table-content" styleName="table-content" onScroll={this.onScroll}>
            <table id="table-content-container-fixed" styleName="table-content-container table-content-fixed">
              {columnsFixed && <Header {...{ columns: columnsFixed, dataType, subType }} isFixed />}
              {columnsFixed && <Body {...{ columns: columnsFixed, rows, dataType, embedded }} isFixed subType={subType} />}
            </table>
            <table id="table-content-container-scroll" styleName="table-content-container table-content-scroll">
              {columnsScroll && <Header {...{ columns: columnsScroll, dataType, subType }} />}
              {columnsScroll && <Body {...{ columns: columnsScroll, rows, dataType, embedded }} subType={subType} />}
            </table>
          </div>
        }
        {isFilteredDataTypeEmpty && !loader && !isDataTypeEmpty &&
          <div styleName="empty-results">
            <div styleName="icon">
              <EmptyResultsIcon {...dataTableEmptyResultsIconStyles} />
            </div>
            <div styleName="title">No results found</div>
            <div styleName="text">Try searching again or using different filters.</div>
          </div>
        }
        {isDataTypeEmpty && !loader &&
          <div styleName="empty-results">
            <div styleName="icon">
              <Icon
                color={colors.dotmapsGrayEmptyIconV2}
                style={{ fontSize: '56px' }}
              >
                view_list
              </Icon>
            </div>
            <div styleName="title">{getEmptyLabel(this.props.dataType)}</div>
            <div styleName="text">{this.getEmptyDataTypeCreateButton()}</div>
          </div>
        }
      </div>
    );
  }
}

DataTable.propTypes = {
  columns: PropTypes.array,
  dataType: PropTypes.string,
  dataTypesCount: PropTypes.object,
  /**
   * Flag to tell if this component will be used
   * embedded in other component.
   */
  embedded: PropTypes.bool,
  /**
   * If this component is embedded, use this color
   * for the DotmapsLoader component.
   */
  embeddedColor: PropTypes.string,
  fetchDatatableData: PropTypes.func,
  filters: PropTypes.object,
  loader: PropTypes.bool,
  push: PropTypes.func,
  rawFilters: PropTypes.object,
  rows: PropTypes.array,
  subType: PropTypes.string
};

DataTable.defaultProps = {
  embedded: false
};

const mapStateToProps = (state, props) => {
  const { dataType, embedded, subType } = props;
  const { stats } = state.dataTypes.stats;
  const filters = getFiltersSelector(state, props);
  // For a smoother use only fetch data from the backend when the filters
  // from the current data type changed (since the 'filters' property is only
  // about the current datatype, we need the raw filters, i.e. the ones with
  // all data types, since the data type property also changes).
  const rawFilters = embedded ? { [dataType]: filters } : state.dataTables.filters;
  return {
    dataTypesCount: stats ? stats.count : null,
    columns: getDataTableColumns(state, props),
    filters,
    loader: getLoader(state, props),
    rawFilters,
    rows: optimizeRowsForDataTable(dataType, subType, embedded)(state)
  };
};

export default connect(mapStateToProps, {
  fetchDatatableData,
  push
})(DataTable);

export { DataTable as PureDataTable };
