/*eslint-disable max-len*/
import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'material-ui/SvgIcon';

const LibraryIcon = props => (
  <SvgIcon {...props} style={{...props.style}} viewBox="0 0 26 26">
    <rect width="26" height="26" rx="5" fill="#3A70EA"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M5 18.9998V8.99976L21 5.99976L26.0001 12.6835V21C26.0001 23.7614 23.7615 26 21.0001 26H14.3252L5 18.9998Z" fill="#6A0D82"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M5 18.9998V8.99976L21 5.99976L26.0001 12.6835V21C26.0001 23.7614 23.7615 26 21.0001 26H14.3252L5 18.9998Z" fill="#0054CE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M21 18.9999H5V15.9999H21V18.9999ZM5 6V9H21V6H5ZM21 14.0002H5V11.0002H21V14.0002Z" fill="white"/>
  </SvgIcon>
);

LibraryIcon.propTypes = {
  style: PropTypes.object
};

export default LibraryIcon;
