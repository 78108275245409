import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import FilterList from '@components/calendar-old/filter-list';
import { getCalendarEntity } from '@constants/config';
import './filter-sidebar.scss';

const FilterSidebar = () => {
  const calendarEntity = getCalendarEntity();
  const { map_type } = useSelector(state => state.dataTypes);
  const entity = Object.values(map_type).find(type => type.name === calendarEntity);
  return (
    <div styleName="filter-sidebar-container">
      <FilterList allText={`All ${entity.label}`} headerText={entity.label} />
    </div>
  );
};

export default memo(FilterSidebar);
