/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undefined */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isString } from 'lodash';
import moment from 'moment-timezone';
import { SAFE_ZINDEX } from '@constants/mui-theme';
import Schedule from '@material-ui/icons/Schedule';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { getConfig } from '@utils/config-utils';

const unawareFormat = 'YYYY-MM-DDTHH:mm:ss';

const MomentTimePicker = ({
  disabled,
  errorText,
  floatingLabelText,
  fullWidth,
  id,
  onChange,
  style,
  value
}) => {
  const { timezoneName } = getConfig();

  const onAccept = time => {
    if (time) {
      let newTime = time;
      if (isString(time)) {
        // If date is a string, it means it was manually entered,
        // (i.e. not through the time picker dialog),
        // thus parse it.
        newTime = moment(time, 'hh:mm A');
      }
      const unawareString = moment(newTime).format(unawareFormat);
      const fixedTime = moment.tz(unawareString, timezoneName);
      onChange(fixedTime);
    }
  };

  const pickerProps = {};
  if (errorText) {
    pickerProps.error = true;
    pickerProps.helperText = errorText;
  }

  return (
    <div style={style}>
      <KeyboardTimePicker
        {...pickerProps}
        DialogProps={{ style: SAFE_ZINDEX }}
        KeyboardButtonProps={{ 'aria-label': 'change date' }}
        autoOk
        disabled={disabled}
        id={id}
        keyboardIcon={<Schedule style={{ fontSize: '18px' }} />}
        fullWidth={fullWidth}
        label={floatingLabelText}
        margin="normal"
        mask="__:__ _M"
        onChange={() => undefined}
        onAccept={onAccept}
        onBlur={event => onAccept(event.target.value)}
        value={typeof value === 'undefined' ? null : value}
        inputProps={{style: disabled ? {color: 'rgba(0,0,0,0.3)'} : {}}}
      />
    </div>
  );
};

MomentTimePicker.propTypes = {
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  floatingLabelText: PropTypes.string,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
  value: PropTypes.object
};

export default memo(MomentTimePicker);
