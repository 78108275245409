/* eslint-disable max-depth */
/* eslint-disable react/jsx-boolean-value */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import * as R from 'ramda';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import TextField from 'material-ui/TextField';
import {
  detailEdit,
  formStyles,
  publicFormStyles,
  styleGuide
} from '@constants/mui-theme';
import DataTypesSelect from '@forms/data-types-select';
import Radio from '@forms/radio';
import Toggle from '@forms/toggle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Checkbox } from '@mui';
import FormattedDatePicker from '@shared/formatted-date-picker';
import MomentTimePicker from '@shared/moment-time-picker';
import { shallowEqual } from '@utils/react-utils';
import './forms.scss';

class FormElement extends Component {
  constructor(props) {
    super(props);

    const { value } = props;
    if (moment.isMoment(value)) {
      this.state = {
        date: moment(value),
        time: moment(value)
      };
    } else {
      this.state = {
        date: null,
        time: null
      };
    }
  }

  // Only render the component if the field value or the errors were modified
  // (all other properties are static).
  shouldComponentUpdate(nextProps, nextState) {
    const { dataRelated } = this.props.fieldMeta;
    if (dataRelated) {
      const relatedChanged = dataRelated.some(relatedField => {
        const thisValue = this.props.data[relatedField];
        const nextValue = nextProps.data[relatedField];
        return thisValue !== nextValue;
      });
      if (relatedChanged) {
        return true;
      }
    }
    return (
      !shallowEqual(nextProps.value, this.props.value) ||
      !shallowEqual(nextProps.errors, this.props.errors) ||
      !shallowEqual(nextProps.fieldMeta, this.props.fieldMeta) ||
      this.state !== nextState
    );
  }

  handleError = (fieldName, error) => this.props.onError(fieldName, error, false);

  handleValueChange = (event, value) => {
    const { onChange, fieldName } = this.props;
    onChange(fieldName, value);
  };

  handleSelectChange = (event, index, value) => {
    this.handleValueChange(event, value);
  };

  handleDateTimeChange = () => {
    const { date, time } = this.state;
    if (date && time) {
      const newTime = moment(time);
      newTime.year(date.year());
      newTime.month(date.month());
      newTime.date(date.date());
      this.validateDate(newTime);
      this.handleValueChange(event, newTime);
    } else if (date) {
      this.validateDate(date);
      this.handleValueChange(event, date);
    }
  };

  // Validation for start/end fields (they are validated together).
  validateDate = date => {
    const { dataType, fieldMeta, fieldName } = this.props;
    const { minDate, maxDate, overrideError } = fieldMeta;

    // Only run this validation if this field has an associate,
    // which means it's a start/end dates combo:
    if (fieldMeta.associatedField) {
      // Validate the error if something was entered:
      if (date) {
        // This is the validation, start date must be before
        // the end one:
        if ((minDate && date.isBefore(minDate)) ||
            (maxDate && date.isAfter(maxDate))) {
          const errorMsg = overrideError || `${dataType} must end after it starts`;
          // We only set the error message on the 'end date' field:
          if (fieldMeta.isFirstInAssociation) {
            this.handleError(fieldName, ' ');
            this.handleError(fieldMeta.associatedField, errorMsg);
          } else {
            this.handleError(fieldName, errorMsg);
            this.handleError(fieldMeta.associatedField, ' ');
          }
        } else {
          this.handleError(fieldName, null);
          this.handleError(fieldMeta.associatedField, null);
        }
      } else {
        // If the value was cleared, clear the error:
        this.handleError(fieldName, null);
        this.handleError(fieldMeta.associatedField, null);
      }
    }
  };

  handleDateChange = value => {
    const date = value ? moment(value) : value;
    this.setState({ date }, this.handleDateTimeChange);
  };

  handleTimeChange = value => {
    this.setState({ time: moment(value) }, this.handleDateTimeChange);
  };

  onCheck = event => {
    const name = event.target.name;
    this.handleValueChange(name, !this.props.value);
  };

  getId = prefix => `${prefix}_picker`;

  render() {
    const { data, fieldName, fieldMeta, readOnly, value, errors, style = {} } = this.props;
    const { date, time } = this.state;
    const { componentType } = this.props.templateProps;
    const commonProps = {
      name: fieldName,
      disabled: fieldMeta.read_only || readOnly,
      floatingLabelText: (
        this.props.templateProps.label ||
        fieldMeta.label
      ) + (
        fieldMeta.required ? ' *' : ''
      ),
      onChange: this.handleValueChange,
      errorText: Array.isArray(errors) ? errors.join(', ') : null,
      errorStyle: { ...detailEdit.errors },
      floatingLabelStyle: { whiteSpace: 'nowrap' }
    };
    if (errors) {
      commonProps.id = `error-${fieldName}`;
    }
    const dateProps = {};
    if (fieldMeta.minDate) {
      dateProps.minDate = fieldMeta.minDate.toDate();
    }
    if (fieldMeta.maxDate) {
      dateProps.maxDate = fieldMeta.maxDate.toDate();
    }
    const styleName = this.props.templateProps.style || 'col100';
    const fullStyle = {style, ...detailEdit.columnStyles[styleName]};
    switch (fieldMeta.type) {
    case 'date':
      return (
        <FormattedDatePicker
          {...commonProps}
          {...dateProps}
          clearable={!fieldMeta.required}
          id={this.getId(fieldName)}
          onChange={this.handleDateChange}
          value={date ? date.toDate() : null}
          style={fullStyle}
          styleName={styleName}
          fullWidth
        />
      );
    case 'datetime': {
      let timeFieldText = commonProps.floatingLabelText.replace('date', 'time');
      timeFieldText = timeFieldText.replace('Date', 'Time');
      if (timeFieldText.search('time') < 0 && timeFieldText.search('Time') < 0) {
        timeFieldText = `${timeFieldText} time`;
      }
      const timeFieldCommonProps = {...commonProps, floatingLabelText: timeFieldText};
      switch (styleName) {
      case 'startDatetime':
        return (
          <div style={fullStyle}>
            <FormattedDatePicker
              {...commonProps}
              {...dateProps}
              clearable={!fieldMeta.required}
              id={this.getId(`${fieldName}_date`)}
              onChange={this.handleDateChange}
              value={date ? date.toDate() : null}
              style={{style, ...detailEdit.columnStyles.startDateRangeDate}}
            />
            <MomentTimePicker
              {...timeFieldCommonProps}
              onChange={this.handleTimeChange}
              id={this.getId(`${fieldName}_time`)}
              value={time || null}
              fullWidth
              style={{style, ...detailEdit.columnStyles.startDateRangeTime}}
            />
          </div>
        );
      case 'endDatetime':
        return (
          <div style={fullStyle}>
            <MomentTimePicker
              {...timeFieldCommonProps}
              onChange={this.handleTimeChange}
              id={this.getId(`${fieldName}_time`)}
              value={time || null}
              fullWidth
              style={{style, ...detailEdit.columnStyles.endDateRangeTime}}
            />
            <FormattedDatePicker
              {...commonProps}
              {...dateProps}
              clearable={!fieldMeta.required}
              id={this.getId(`${fieldName}_date`)}
              onChange={this.handleDateChange}
              value={date ? date.toDate() : null}
              style={{style, ...detailEdit.columnStyles.endDateRangeDate}}
            />
          </div>
        );
      case 'fullDatetime':
        return (
          <div>
            <FormattedDatePicker
              {...commonProps}
              {...dateProps}
              clearable={!fieldMeta.required}
              id={this.getId(`${fieldName}_date`)}
              onChange={this.handleDateChange}
              value={date ? date.toDate() : null}
              fullWidth
              style={fullStyle}
            />
            <MomentTimePicker
              {...timeFieldCommonProps}
              onChange={this.handleTimeChange}
              id={this.getId(`${fieldName}_time`)}
              value={time || null}
              fullWidth
              style={fullStyle}
            />
          </div>
        );
      default:
        return (
          <div
            style={fullStyle}
            styleName={styleName}
          >
            <FormattedDatePicker
              {...commonProps}
              {...dateProps}
              clearable={!fieldMeta.required}
              id={this.getId(fieldName)}
              onChange={this.handleDateChange}
              value={date ? date.toDate() : null}
              fullWidth
              style={{...style, ...detailEdit.columnStyles.insideCol50Left}}
            />
            <MomentTimePicker
              {...timeFieldCommonProps}
              onChange={this.handleTimeChange}
              value={time || null}
              fullWidth
              style={{...style, ...detailEdit.columnStyles.insideCol50Right}}
            />
          </div>
        );
      }
    }
    case 'integer':
      return (
        <TextField
          {...commonProps}
          {...styleGuide.textField}
          type="number"
          value={String(value)}
          maxLength={fieldMeta.max_length}
          inputStyle={{ ...styleGuide.textField.inputStyle, width: '100% - 1px' }}
          style={fullStyle}
          styleName={styleName}
        />
      );
    case 'string':
    case 'email':
      return (
        <TextField
          {...commonProps}
          {...styleGuide.textField}
          value={!value && (fieldMeta.read_only || readOnly) ? '-' : value}
          maxLength={fieldMeta.max_length}
          multiLine
          inputStyle={{ ...styleGuide.textField.inputStyle, margin: '0px' }}
          rowsMax={4}
          style={fullStyle}
          styleName={styleName}
        />
      );
    case 'boolean':
      if (componentType === 'toggle') {
        const errorStyle = {
          ...detailEdit.columnStyles[this.props.templateProps.dataCheckErrorStyle]
        };
        return (
          <Toggle
            data={data}
            dataName={this.props.templateProps.data || fieldName}
            dataField={this.props.templateProps.dataField}
            dataExtract={this.props.templateProps.dataExtract}
            dataCheckError={this.props.templateProps.dataCheckError}
            dataCheckErrorStyle={errorStyle}
            dataCheckValue={this.props.templateProps.dataCheckValue}
            labelPosition="right"
            label={this.props.templateProps.label || fieldMeta.label}
            labelStyle={formStyles(fieldMeta.read_only || readOnly).checkbox.labelStyle}
            disabled={fieldMeta.read_only || readOnly}
            name={fieldName}
            onClick={this.onCheck}
            style={{...fullStyle, ...detailEdit.toggle.style}}
            styleName={styleName}
            toggled={value || false}
          />
        );
      } else if (componentType === 'radio') {
        return (
          <div styleName="radio-container">
            <span>{commonProps.floatingLabelText}</span>
            <RadioButtonGroup
              {...commonProps}
              valueSelected={Boolean(value)}
              {...publicFormStyles().radioGroup}
            >
              <RadioButton
                disabled={readOnly}
                value={true}
                label={<div>Yes</div>}
                {...publicFormStyles(readOnly).radio}
              />
              <RadioButton
                disabled={readOnly}
                value={false}
                label={<div>No</div>}
                {...publicFormStyles(readOnly).radio}
              />
            </RadioButtonGroup>
          </div>
        );
      } else if (componentType === 'dropdown') {
        const { defaultNullLabel } = this.props.templateProps;
        const booleanDataSource = {
          null: { id: 'null', name: defaultNullLabel || 'TBD', order: 3 },
          true: { id: 'true', name: 'Yes', order: 1 },
          false: { id: 'false', name: 'No', order: 2 }
        };
        return (
          <DataTypesSelect
            {...commonProps}
            dataSource={booleanDataSource}
            onChange={this.handleSelectChange}
            value={String(value)}
            data={data}
            customItemFormatter={this.props.templateProps.customItemFormatter}
            dataSortField="order"
            dataName={this.props.templateProps.data || fieldName}
            multiple={fieldMeta.multiple || false}
            required={fieldMeta.required}
            style={fullStyle}
            styleName={styleName}
          />
        );
      }
      return (
        <div style={{ ...fullStyle, marginTop: '0.5rem' }}>
          <FormControl error={errors} component="fieldset">
            <Checkbox
              checked={value || false}
              disabled={fieldMeta.read_only || readOnly}
              label={this.props.templateProps.label || fieldMeta.label}
              onChange={this.onCheck}
              name={fieldName}
              size="small"
            />
            <FormHelperText>{errors?.join(',')}</FormHelperText>
          </FormControl>
        </div>
      );
    case 'field':
      if (componentType === 'radio') {
        return (
          <Radio
            categoryTypes={this.props.categoryTypes}
            dataName={this.props.templateProps.data || fieldName}
            disabled={commonProps.disabled}
            label={commonProps.floatingLabelText}
            name={commonProps.name}
            onChange={this.handleValueChange}
            value={value}
          />
        );
      }
      return (
        <DataTypesSelect
          {...commonProps}
          categoryTypes={this.props.categoryTypes}
          onChange={this.handleSelectChange}
          data={data}
          customItemFormatter={this.props.templateProps.customItemFormatter}
          dataSortField={this.props.templateProps.dataSortField}
          dataSource={this.props.dataSource}
          dataName={this.props.templateProps.data || fieldName}
          multiple={fieldMeta.multiple || false}
          required={fieldMeta.required}
          value={value}
          style={fullStyle}
          styleName={styleName}
        />
      );
    case 'nested object': {
      const visibleChildFields = Object.keys(
        R.pickBy(childValue => childValue.style !== 'hidden', fieldMeta.children)
      );
      return (
        <div>
          {visibleChildFields.map(childName => {
            if (childName !== 'id') {
              const childErrors = errors ? errors[childName] || null : null;
              return (
                <FormElement
                  key={childName}
                  fieldName={`${fieldName}.${childName}`}
                  fieldMeta={fieldMeta.children[childName]}
                  onChange={this.props.onChange}
                  value={value[childName] || ''}
                  readOnly={readOnly}
                  templateProps={{
                    ...fieldMeta.children[childName],
                    label: `${fieldMeta.label} ${fieldMeta.children[childName].label.toLowerCase()}`
                  }}
                  errors={childErrors}
                />
              );
            }
            return null;
          })}
        </div>
      );
    }
    default:
      return (
        <TextField
          {...commonProps}
          {...styleGuide.textField}
          {...detailEdit.textField}
          disabled
          value={!value && (fieldMeta.read_only || readOnly) ? '-' : String(value)}
          maxLength={fieldMeta.max_length}
          multiLine
          style={fullStyle}
          styleName={styleName}
        />
      );
    }
  }
}

FormElement.propTypes = {
  categoryTypes: PropTypes.object,
  data: PropTypes.object,
  dataSource: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  dataType: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  fieldMeta: PropTypes.object,
  fieldName: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  readOnly: PropTypes.bool,
  style: PropTypes.object,
  templateProps: PropTypes.object,
  value: PropTypes.any  // eslint-disable-line react/forbid-prop-types
};

export default FormElement;
