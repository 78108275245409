export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
export const AUTH_LOGOUT_RESET = 'AUTH_LOGOUT_RESET';
export const AUTH_PASSWORD_CHANGE_ERROR = 'AUTH_PASSWORD_CHANGE_ERROR';
export const AUTH_PASSWORD_CHANGE_SUCCESS = 'AUTH_PASSWORD_CHANGE_SUCCESS';
export const AUTH_PASSWORD_REQUEST_ERROR = 'AUTH_PASSWORD_REQUEST_ERROR';
export const AUTH_PASSWORD_REQUEST_SUCCESS = 'AUTH_PASSWORD_REQUEST_SUCCESS';
export const AUTH_SET_AGENCY_SUCCESS = 'AUTH_SET_AGENCY_SUCCESS';
export const AUTH_UPDATE_USER = 'AUTH_UPDATE_USER';

export const DATATABLE_FETCH_ROWS_SUCCESS = 'DATATABLE_FETCH_ROWS_SUCCESS';
export const DATATABLE_FETCH_ROWS_ERROR = 'DATATABLE_FETCH_ROWS_ERROR';
export const DATATABLE_FETCH_COLUMNS_ERROR = 'DATATABLE_FETCH_COLUMNS_ERROR';
export const SET_DATATABLE_FILTERS = 'SET_DATATABLE_FILTERS';
export const RESET_DATATABLE_FILTERS = 'RESET_DATATABLE_FILTERS';
export const CLEAR_DATATABLE_ROWS = 'CLEAR_DATATABLE_ROWS';
export const DATATABLE_TOGGLE_LOADER = 'DATATABLE_TOGGLE_LOADER';
export const DATATABLE_AUTOCOMPLETE_SELECTED = 'DATATABLE_AUTOCOMPLETE_SELECTED';
export const DATATABLE_SET_SELECTED_ROW = 'DATATABLE_SET_SELECTED_ROW';
export const AUTOCOMPLETE_VALUES_CHANGED = 'AUTOCOMPLETE_VALUES_CHANGED';
export const AUTOCOMPLETE_LOADER_CHANGED = 'AUTOCOMPLETE_LOADER_CHANGED';

export const DIALOG_DISMISS = 'DIALOG_DISMISS';
export const DIALOG_OPEN = 'DIALOG_OPEN';

export const EXPORT_DOWNLOADING = 'EXPORT_DOWNLOADING';
export const EXPORT_DOWNLOADING_FINISHED = 'EXPORT_DOWNLOADING_FINISHED';

export const GROUPS_FETCH_SUCCESS = 'GROUPS_FETCH_SUCCESS';
export const GROUPS_FETCH_SET_LOADING = 'GROUPS_FETCH_SET_LOADING';
export const GROUPS_REMOVE_ENTITY_SUCCESS = 'GROUPS_REMOVE_ENTITY_SUCCESS';
export const GROUPS_FETCH_POLYGON_START = 'GROUPS_FETCH_POLYGON_START';
export const GROUPS_FETCH_POLYGON_SUCCESS = 'GROUPS_FETCH_POLYGON_SUCCESS';
export const GROUPS_FETCH_POLYGON_FAIL = 'GROUPS_FETCH_POLYGON_FAIL';
export const GROUPS_DISABLE_DRAWING_MANAGER = 'GROUPS_DISABLE_DRAWING_MANAGER';
export const GROUPS_ENABLE_DRAWING_MANAGER = 'GROUPS_ENABLE_DRAWING_MANAGER';
export const GROUP_EDIT_LOADING = 'GROUP_EDIT_LOADING';
export const GET_GROUP_BY_ID_SUCCESS = 'GET_GROUP_BY_ID_SUCCESS';
export const GROUPS_SET_DATE_RANGE = 'GROUPS_SET_DATE_RANGE';
export const CHANGE_GROUP_FIELD_VALUE = 'CHANGE_GROUP_FIELD_VALUE';
export const GROUP_CREATE_MEMBERS_FETCH_SUCCESS = 'GROUP_CREATE_MEMBERS_FETCH_SUCCESS';
export const GROUP_DIALOG_SET_ADD_ITEM = 'GROUP_DIALOG_SET_ADD_ITEM';
export const GROUP_CLEAR_DATE = 'GROUP_CLEAR_DATE';
export const GROUP_SET_LOADING = 'GROUP_SET_LOADING';
export const GROUP_SET_SHAPE = 'GROUP_SET_SHAPE';
export const GROUP_APPLY_SHAPE = 'GROUP_APPLY_SHAPE';
export const GROUPS_ENTITY_OVERLAPS_FETCH_SUCCESS = 'GROUPS_ENTITY_OVERLAPS_FETCH_SUCCESS';
export const GROUPS_DETAIL_SAVING = 'GROUPS_DETAIL_SAVING';
export const GROUPS_DETAIL_SAVING_SUCCESS = 'GROUPS_DETAIL_SAVING_SUCCESS';
export const GROUPS_DETAIL_SAVING_FAIL = 'GROUPS_DETAIL_SAVING_FAIL';
export const GROUPS_DETAIL_MODIFIED = 'GROUPS_DETAIL_MODIFIED';
export const GROUPS_DISMISS_GANTT_DIALOG = 'GROUPS_DISMISS_GANTT_DIALOG';
export const GROUPS_SET_GANTT_FILTER = 'GROUPS_SET_GANTT_FILTER';
export const GROUPS_TOGGLE_GANTT_LOCATION_TYPE = 'GROUPS_TOGGLE_GANTT_LOCATION_TYPE';
export const GROUPS_TOGGLE_BOUNDARIES = 'GROUPS_TOGGLE_BOUNDARIES';
export const GROUPS_TOGGLE_LABELS = 'GROUPS_TOGGLE_LABELS';
export const GROUPS_SET_LAYER_VISIBLE = 'GROUPS_SET_LAYER_VISIBLE';
export const GROUPS_SET_LAYER_BOUNDARIES_VISIBLE = 'GROUPS_SET_LAYER_BOUNDARIES_VISIBLE';
export const GROUPS_SET_PRE_FILL = 'GROUPS_SET_PRE_FILL';
export const GROUPS_CLEAR_PRE_FILL = 'GROUPS_CLEAR_PRE_FILL';
export const GROUP_OPTIONS_FETCH_SUCCESS = 'GROUP_OPTIONS_FETCH_SUCCESS';
export const GROUP_OPTIONS_FETCH_ERROR = 'GROUP_OPTIONS_FETCH_ERROR';

export const MAP_FILTERS_CHANGED = 'MAP_FILTERS_CHANGED';
export const MAP_SET_TYPE_ID = 'MAP_SET_TYPE_ID';
export const MAP_TOGGLE_TRAY = 'MAP_TOGGLE_TRAY';
export const MAP_TOGGLE_LEGEND = 'MAP_TOGGLE_LEGEND';
export const MAP_TOGGLE_LAYERS_MENU = 'MAP_TOGGLE_LAYERS_MENU';
export const MAP_TOGGLE_MARKER_MODAL = 'MAP_TOGGLE_MARKER_MODAL';
export const MAP_TOGGLE_MARKERS = 'MAP_TOGGLE_MARKERS';
export const MAP_TOGGLE_POIS = 'MAP_TOGGLE_POIS';
export const MAP_TOGGLE_MAP_FILTER_TOGGLE = 'MAP_TOGGLE_MAP_FILTER_TOGGLE';
export const MAP_TOGGLE_TRAFFIC = 'MAP_TOGGLE_TRAFFIC';
export const MAP_TOGGLE_BICYCLE = 'MAP_TOGGLE_BICYCLE';
export const MAP_TOGGLE_TRANSIT = 'MAP_TOGGLE_TRANSIT';
export const MAP_TOGGLE_DRAWER = 'MAP_TOGGLE_DRAWER';
export const MAP_TOGGLE_ONLY_SHOW_OVERLAPS = 'MAP_TOGGLE_ONLY_SHOW_OVERLAPS';
export const MAP_SET_MAP_FILTER_TOGGLE = 'MAP_SET_MAP_FILTER_TOGGLE';
export const MAP_PUSH_TRAY = 'MAP_PUSH_TRAY';
export const MAP_POP_TRAY = 'MAP_POP_TRAY';
export const MAP_CLEAR_TRAY = 'MAP_CLEAR_TRAY';
export const MAP_VIEWPORT_CHANGE = 'MAP_VIEWPORT_CHANGE';
export const MAP_FIT_BOUNDS = 'MAP_FIT_BOUNDS';
export const MAP_SET_DRAWING_MODE = 'MAP_SET_DRAWING_MODE';
export const MAP_SET_DRAWING_PROPS = 'MAP_SET_DRAWING_PROPS';
export const MAP_SET_DRAWING_PATH = 'MAP_SET_DRAWING_PATH';
export const MAP_SET_DRAWING_DONE = 'MAP_SET_DRAWING_DONE';
export const MAP_SET_MAP_FILTER_RADIO = 'MAP_SET_MAP_FILTER_RADIO';
export const MAP_SET_MODE = 'MAP_SET_MODE';
export const MAP_SET_GOOGLE_AUTOCOMPLETE_SUGGESTIONS = 'MAP_SET_GOOGLE_AUTOCOMPLETE_SUGGESTIONS';
export const MAP_SET_SEARCH_TERM = 'MAP_SET_SEARCH_TERM';
export const MAP_OVERLAP_TRAY_HOVER = 'MAP_OVERLAP_TRAY_HOVER';
export const MAP_UPDATE_GROUP_TRAY = 'MAP_UPDATE_GROUP_TRAY';
export const RESET_MAP_FILTERS = 'RESET_MAP_FILTERS';
export const MAP_SELECT_TRAY_SEGMENT = 'MAP_SELECT_TRAY_SEGMENT';
export const SET_MAP_SELECTION_POSITION = 'SET_MAP_SELECTION_POSITION';
export const MAP_TRAY_OVERLAP_ENTITY_CACHE_ADD = 'MAP_TRAY_OVERLAP_ENTITY_CACHE_ADD';
export const MAP_TRAY_OVERLAP_ENTITY_CACHE_REMOVE = 'MAP_TRAY_OVERLAP_ENTITY_CACHE_REMOVE';

export const DASHBOARD_DETAIL_DATA_CHANGED = 'DASHBOARD_DETAIL_DATA_CHANGED';
export const DASHBOARD_DETAIL_DATA_VALID_CHANGED = 'DASHBOARD_DETAIL_DATA_VALID_CHANGED';
export const DASHBOARD_DETAIL_DATA_SAVE_ERROR = 'DASHBOARD_DETAIL_DATA_SAVE_ERROR';
export const DASHBOARD_DETAIL_DATA_CLEAR_SAVE_ERROR_FIELD = 'DASHBOARD_DETAIL_DATA_CLEAR_SAVE_ERROR_FIELD';
export const DASHBOARD_DETAIL_DATA_DELETED = 'DASHBOARD_DETAIL_DATA_DELETED';
export const DASHBOARD_DETAIL_DATA_SAVING = 'DASHBOARD_DETAIL_DATA_SAVING';
export const DASHBOARD_DETAIL_UPDATE_FIELD = 'DASHBOARD_DETAIL_UPDATE_FIELD';
export const DASHBOARD_DIALOG_PARAM = 'DASHBOARD_DIALOG_PARAM';
export const DASHBOARD_OPEN_DIALOG = 'DASHBOARD_OPEN_DIALOG';
export const DASHBOARD_CLOSE_DIALOG = 'DASHBOARD_CLOSE_DIALOG';
export const DASHBOARD_TOGGLE_FILTER_TOP_BAR = 'DASHBOARD_TOGGLE_FILTER_TOP_BAR';
export const DASHBOARD_RESOLUTION_LOADING = 'DASHBOARD_RESOLUTION_LOADING';
export const DASHBOARD_SAVE_REQUESTED = 'DASHBOARD_SAVE_REQUESTED';
export const DASHBOARD_POP_MESSAGE = 'DASHBOARD_POP_MESSAGE';
export const DASHBOARD_PUSH_MESSAGE = 'DASHBOARD_PUSH_MESSAGE';

export const DATA_TYPES_FETCH_SUCCESS = 'DATA_TYPES_FETCH_SUCCESS';
export const DATA_TYPES_FETCH_ERROR = 'DATA_TYPES_FETCH_SUCCESS';

export const DATA_DETAIL_OPTIONS_FETCH_SUCCESS = 'DATA_DETAIL_OPTIONS_FETCH_SUCCESS';
export const DATA_DETAIL_OPTIONS_FETCH_ERROR = 'DATA_DETAIL_OPTIONS_FETCH_ERROR';
export const DATA_DETAIL_FETCH_INIT = 'DATA_DETAIL_FETCH_INIT';
export const DATA_DETAIL_FETCH_SUCCESS = 'DATA_DETAIL_FETCH_SUCCESS';
export const DATA_DETAIL_FETCH_ERROR = 'DATA_DETAIL_FETCH_ERROR';
export const DATA_DETAIL_SAVE_INIT = 'DATA_DETAIL_SAVE_INIT';
export const DATA_DETAIL_SAVE_SUCCESS = 'DATA_DETAIL_SAVE_SUCCESS';
export const DATA_DETAIL_SAVE_ERROR = 'DATA_DETAIL_SAVE_ERROR';
export const DATA_DETAIL_ERROR_FIELD = 'DATA_DETAIL_ERROR_FIELD';
export const DATA_DETAIL_UPDATE_FIELD = 'DATA_DETAIL_UPDATE_FIELD';
export const DATA_DETAIL_CREATE_SEGMENT = 'DATA_DETAIL_CREATE_SEGMENT';
export const DATA_DETAIL_SWAP_FIELDS = 'DATA_DETAIL_SWAP_FIELDS';
export const DATA_DETAIL_CLEAR_SCHEDULE = 'DATA_DETAIL_CLEAR_SCHEDULE';
export const DATA_DETAIL_CLONE_SEGMENT = 'DATA_DETAIL_CLONE_SEGMENT';
export const DATA_DETAIL_DELETE_SEGMENT = 'DATA_DETAIL_DELETE_SEGMENT';
export const DATA_DETAIL_SEGMENT_UPDATE_FIELD = 'DATA_DETAIL_SEGMENT_UPDATE_FIELD';
export const DATA_DETAIL_SEGMENT_UPDATE = 'DATA_DETAIL_SEGMENT_UPDATE';
export const DATA_DETAIL_COMPUTE_SEGMENT_START = 'DATA_DETAIL_COMPUTE_SEGMENT_START';
export const DATA_DETAIL_COMPUTE_SEGMENT_SUCCESS = 'DATA_DETAIL_COMPUTE_SEGMENT_SUCCESS';
export const DATA_DETAIL_UPDATE_POLYGON = 'DATA_DETAIL_UPDATE_POLYGON';
export const DATA_DETAIL_COMPUTE_SEGMENT_ERROR = 'DATA_DETAIL_COMPUTE_SEGMENT_ERROR';
export const DATA_DETAIL_DELETE_SUCCESS = 'DATA_DETAIL_DELETE_SUCCESS';
export const DATA_DETAIL_DELETE_ERROR = 'DATA_DETAIL_DELETE_ERROR';
export const DATA_DETAIL_MODIFIED = 'DATA_DETAIL_MODIFIED';
export const DATA_DETAIL_SET_ACTIVE_TAB = 'DATA_DETAIL_SET_ACTIVE_TAB';
export const DATA_DETAIL_FETCH_CYCLE_SUCCESS = 'DATA_DETAIL_FETCH_CYCLE_SUCCESS';
export const DATA_DETAIL_FETCH_CYCLE_ERROR = 'DATA_DETAIL_FETCH_CYCLE_ERROR';
export const DATA_DETAIL_FETCH_OVERLAP_ENTITIES_SUCCESS = 'DATA_DETAIL_FETCH_OVERLAP_ENTITIES_SUCCESS';
export const DATA_DETAIL_FETCH_OVERLAP_ENTITIES_ERROR = 'DATA_DETAIL_FETCH_OVERLAP_ENTITIES_ERROR';
export const DATA_DETAIL_SCROLL_TO_FIELD = 'DATA_DETAIL_SCROLL_TO_FIELD';

export const ATTACHMENT_CLOSE_PREVIEW = 'ATTACHMENT_CLOSE_PREVIEW';
export const ATTACHMENT_DELETE_SUCCESS = 'ATTACHMENT_DELETE_SUCCESS';
export const ATTACHMENT_FETCH_ERROR = 'ATTACHMENT_FETCH_ERROR';
export const ATTACHMENT_FETCH_SUCCESS = 'ATTACHMENT_FETCH_SUCCESS';
export const ATTACHMENT_LOADING = 'ATTACHMENT_LOADING';
export const ATTACHMENT_SHOW_PREVIEW = 'ATTACHMENT_SHOW_PREVIEW';
export const ATTACHMENT_STORE_CLEAR = 'ATTACHMENT_STORE_CLEAR';
export const ATTACHMENT_STORE_SUCCESS = 'ATTACHMENT_STORE_SUCCESS';
export const ATTACHMENT_DELETE_STORED_SUCCESS = 'ATTACHMENT_DELETE_STORED_SUCCESS';
export const ATTACHMENT_TEMPFILE_DELETE_SUCCESS = 'ATTACHMENT_TEMPFILE_DELETE_SUCCESS';
export const ATTACHMENT_UPLOAD_SUCCESS = 'ATTACHMENT_UPLOAD_SUCCESS';
export const ATTACHMENT_UPLOAD_ERROR = 'ATTACHMENT_UPLOAD_ERROR';

export const BATCH_UPLOAD_SUCCESS = 'BATCH_UPLOAD_SUCCESS';
export const BATCH_UPLOAD_FAILURE = 'BATCH_UPLOAD_FAILURE';

export const CALENDAR_FETCH_START = 'CALENDAR_FETCH_START';
export const CALENDAR_FETCH_DATA = 'CALENDAR_FETCH_DATA';
export const CALENDAR_FETCH_SUCCESS = 'CALENDAR_FETCH_SUCCESS';
export const CALENDAR_FETCH_ERROR = 'CALENDAR_FETCH_ERROR';
export const CALENDAR_UPDATE_SELECTED_FILTERS = 'CALENDAR_UPDATE_SELECTED_FILTERS';

export const HANDLE_SERVER_ERROR = 'HANDLE_SERVER_ERROR';

export const PUSH_APPLICATION_MESSAGE = 'PUSH_APPLICATION_MESSAGE';
export const POP_APPLICATION_MESSAGE = 'POP_APPLICATION_MESSAGE';

export const LOGS_CLEAR = 'LOGS_CLEAR';
export const LOGS_FETCH_SUCCESS = 'LOGS_FETCH_SUCCESS';
export const LOGS_LOADING = 'LOGS_LOADING';
export const LOGS_RELOAD = 'LOGS_RELOAD';

export const NOTIFICATIONS_FETCH_SUCCESS = 'NOTIFICATIONS_FETCH_SUCCESS';
export const NOTIFICATIONS_SET_READ_SUCCESS = 'NOTIFICATIONS_SET_READ_SUCCESS';
export const NOTIFICATIONS_UNREAD_FETCH_SUCCESS = 'NOTIFICATIONS_UNREAD_FETCH_SUCCESS';
export const NOTIFICATIONS_LOADING = 'NOTIFICATIONS_LOADING';

export const LAYER_FETCH_SUCCESS = 'LAYER_FETCH_SUCCESS';
export const LAYER_MAP_LOAD_OUTSIDE_VIEWPORT = 'LAYER_MAP_LOAD_OUTSIDE_VIEWPORT';
export const LAYER_MAP_LOADING_COMPLETE = 'LAYER_MAP_LOADING_COMPLETE';
export const LAYER_MAP_SAVE_VIEWPORT = 'LAYER_MAP_SAVE_VIEWPORT';
export const LAYER_LOADING = 'LAYER_LOADING';
export const SET_LAYER_NEXT = 'SET_LAYER_NEXT';
export const SET_LAYER_VISIBLE = 'SET_LAYER_VISIBLE';
export const LAYER_FETCH_CONFIG = 'LAYER_FETCH_CONFIG';

export const OPEN_CONFIRMATION_DIALOG = 'OPEN_CONFIRMATION_DIALOG';
export const CLOSE_CONFIRMATION_DIALOG = 'CLOSE_CONFIRMATION_DIALOG';
export const DISCARD_CHANGES = 'DISCARD_CHANGES';

export const TOOLTIP_FETCH_ERROR = 'TOOLTIP_FETCH_ERROR';
export const TOOLTIP_FETCH_SUCCESS = 'TOOLTIP_FETCH_SUCCESS';

export const SETTINGS_FETCH_SUCCESS = 'SETTINGS_FETCH_SUCCESS';

export const ENTITY_ADD_SINGLE = 'ENTITY_ADD_SINGLE';
export const ENTITY_FILTERS_CHANGED = 'ENTITY_FILTERS_CHANGED';
export const ENTITY_LOADING = 'ENTITY_LOADING';
export const ENTITY_MAP_LOAD_OUTSIDE_VIEWPORT = 'ENTITY_MAP_LOAD_OUTSIDE_VIEWPORT';
export const ENTITY_FETCH_SUCCESS = 'ENTITY_FETCH_SUCCESS';
export const ENTITY_FETCH_COMPLETE = 'ENTITY_FETCH_COMPLETE';
export const ENTITY_SET_INITIAL_BOUNDS = 'ENTITY_SET_INITIAL_BOUNDS';

export const WORKFLOW_CYCLE_FETCH_INIT = 'WORKFLOW_CYCLE_FETCH_INIT';
export const WORKFLOW_CYCLE_FETCH_SUCCESS = 'WORKFLOW_CYCLE_FETCH_SUCCESS';
export const WORKFLOW_CYCLE_FETCH_ERROR = 'WORKFLOW_CYCLE_FETCH_ERROR';
