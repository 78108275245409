import React, { useContext, useMemo } from 'react';
import DetailsContext from '@components/workflow/details-context';
import Section from '@components/workflow/section';
import AttachmentsSection from '@components/workflow/section/attachments';
import LinkedEntitiesSection from '@components/workflow/section/linked-entities';
import LocationsSection from '@components/workflow/section/locations';
import { getWorkflowCustomFieldSections } from '@constants/config';
import { getTaskFields, renderFields } from '@utils/workflow-utils';
import './details-tab.scss';

const DetailsTab = () => {
  const { cycle, taskId, taskType } = useContext(DetailsContext);
  const sections = useMemo(() => getWorkflowCustomFieldSections(), []);

  return (
    <div styleName="details-tab-container">
      {sections.map(section => {
        const fields = getTaskFields(section, taskType);
        if (fields.length > 0 && section.name !== 'Response') {
          return (
            <Section key={section.id} name={section.name}>
              {renderFields(section.id, fields)}
            </Section>
          );
        }
        return null;
      })}
      <AttachmentsSection taskId={taskId} />
      {cycle?.group && <LinkedEntitiesSection />}
      {cycle?.entity && <LocationsSection />}
    </div>
  );
};

export default DetailsTab;
