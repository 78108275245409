import React from 'react';
import * as R from 'ramda';
import { colors } from '@constants/colors';
import FilterSelect from '@shared/filter-select';
import { BaseFilter } from '@shared/top-filter-bar/base-filter';
import { buildFilterMenuItem } from '@utils/filter-utils';

export class BaseSimpleDropdownFilter extends BaseFilter {
  buildStateFromProps(props) {
    return { selected: props.filter };
  }

  getFilters() {
    return this.state.selected;
  }

  filterChange(event) {
    this.setState({ selected: event.target.value });
  }

  getChecked(item) {
    return this.state.selected === item.id;
  }

  render() {
    const { items } = this.props;
    return (
      <div>
        <FilterSelect
          displayEmpty
          isValueSelected={!R.isEmpty(this.state.selected)}
          labelStyles={{
            backgroundColor: 'rgba(255,255,255,0.3)',
            margin: '8px 0 8px 0',
            color: colors.neutral.white
          }}
          multiple={this.getMultiple()}
          onChange={this.filterChange}
          onClose={this.filterClose}
          renderValue={this.renderValue}
          value={this.state.selected}
        >
          {items.map(item => buildFilterMenuItem(this.getChecked(item), null, item))}
        </FilterSelect>
      </div>
    );
  }
}
