import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { getConfig } from '@utils/config-utils';
import styles from './intake.scss';

const IntakeTopBar = ({ title }) => {
  const config = getConfig();
  return (
    <div className={styles.container}>
      {
        config.publicLogo &&
        <Fragment>
          {config.publicLogoLink ?
            <a href={config.publicLogoLink} rel="noopener noreferrer" target="_blank">
              <img src={config.publicLogo} className={styles.logo} alt={config.cityName} />
            </a> :
            <img src={config.publicLogo} className={styles.logo} alt={config.cityName} />
          }
          <span className={styles.separator} />
        </Fragment>
      }
      <span className={styles.entityType}>{title}</span>
    </div>
  );
};

IntakeTopBar.propTypes = {
  title: PropTypes.string
};

export default IntakeTopBar;
