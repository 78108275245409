/* eslint-disable guard-for-in */
/* eslint-disable react/display-name */
/* eslint-disable react/no-danger */
import React from 'react';
import { connect } from 'react-redux';
import { Paper } from 'material-ui';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Alert from '@material-ui/lab/Alert';
import { mapStateToProps as baseMapStateToProps } from '@components/data-detail';
import EditDetail, { editMapDispatchToProps } from '@components/data-detail/edit-detail';
import IntakeTopBar from '@components/data-detail/top-bar/intake';
import NonFieldErrors from '@components/entity/info/drawer/form/non-field-errors';
import ScheduleWarning from '@components/entity/info/drawer/form/form-tab/form-field/schedule-warning';
import SegmentTitle from '@components/entity/info/drawer/form/form-tab/section-title/segment-title';
import { getDetailsConfig, getEntityTypeLabel } from '@constants/config';
import * as colors from '@constants/colors';
import * as dialog from '@constants/dialogs';
import { detailEdit } from '@constants/mui-theme';
import FormElement from '@forms/form-element';
import DateRange from '@forms/date-range';
import SegmentList from '@forms/segment-list';
import { Button } from '@mui';
import BulkScheduleDialog from '@shared/dialogs/bulk-schedule-dialog';
import EditScheduleDialog from '@shared/dialogs/edit-schedule-dialog';
import ViewScheduleDialog from '@shared/dialogs/view-schedule-dialog';
import SubmitPublicFormDialog from '@shared/dialogs/submit-public-form-dialog';
import DotmapsLoader from '@shared/dotmaps-loader';
import Files from './files';
import {
  getMetadata,
  getErrors,
  getTabs,
  getVisibleFields,
  isFieldVisible,
  isErrorsEmpty,
  scrollIntoFirstError
} from '@utils/form-utils';
import { withRouter } from '@utils/router-utils';
import '../../forms/forms.scss';

class PublicDetail extends EditDetail {
  componentDidUpdate = () => {
    const { error } = this.props;
    const metadata = this.getMetadata();
    const visibleFields = getVisibleFields(metadata);
    scrollIntoFirstError(error, metadata, visibleFields);

    if (!this.state.defaultsSet && window.mapInstance) {
      this.setDefaultTools();
    }
  };

  getMetadata = () => {
    const { data, options } = this.props;
    return getMetadata(data, this.getDataType(), options, true);
  };

  setDefaultTools = () => {
    this.setState({defaultsSet: true});
    const entityConfig = getDetailsConfig()[this.getDataType()].form;
    const defaultMapTools = entityConfig.default_map_tools;
    if (defaultMapTools && defaultMapTools.public) {
      if (defaultMapTools.public.includes('bicycle')) {
        this.props.toggleBicycle();
      }
      if (defaultMapTools.public.includes('traffic')) {
        this.props.toggleTraffic();
      }
      if (defaultMapTools.public.includes('transit')) {
        this.props.toggleTransit();
      }
    }
  };

  handleNext = () => {
    const valid = this.validateStep();
    if (valid) {
      const nextStep = this.state.activeStep + 1;
      this.setSegmentActive(nextStep);
      this.setState({activeStep: nextStep});
      document.getElementById('form-container').scrollTop = 0;
    }
  };

  handleBack = () => {
    const prevStep = this.state.activeStep - 1;
    this.setSegmentActive(prevStep);
    this.setState({activeStep: prevStep});
    document.getElementById('form-container').scrollTop = 0;
  };

  setSegmentActive = targetStep => {
    this.setState({ activeStep: targetStep });
    const tabs = this.getPublicTabs();
    const { data } = this.props;
    if (!tabs[targetStep].fields || tabs[targetStep].fields[0] !== 'segments') {
      this.setState({activeSegmentId: null});
    } else {
      this.setState({activeSegmentId: data.segments[0].id});
    }
  };

  handleError = (fieldName, error) => this.props.errorDataField(fieldName, error, false);

  getDataType = () => this.props.entityType;

  getDataTypeDisplayName = () => getEntityTypeLabel(this.props.entityType);

  getTopBar = () => <IntakeTopBar title={getDetailsConfig()[this.getDataType()].form.title.label} />;

  getCreateNew = () => {
    const dataType = this.getDataType();
    const emptySegments = this.getEmptySegments();
    return this.props.createNewDataDetail(dataType, true, emptySegments);
  };

  validateStep = () => {
    const tab = this.getPublicTabs()[this.state.activeStep];
    const metadata = this.getMetadata();
    const error = getErrors(this.props.error);
    const { data } = this.props;
    let valid = true;
    const requiredError = 'This field is required.';
    for (const index in tab.fields) {
      const fieldName = tab.fields[index];
      if (fieldName === 'segments') {
        if (!data.segments[0].shape) {
          this.onSegmentFieldError(data.segments[0].id, 'display_from', 'Please enter an address.');
          this.onSegmentFieldError(data.segments[0].id, 'display_to', 'Please enter an address.');
          valid = false;
        }
      } else
        if (fieldName === '_date_range') {
          if (metadata.end_date.required) {
            if (!data.end_date) {  // eslint-disable-line max-depth
              this.handleError('end_date', requiredError);
            }
          }
          if (metadata.start_date.required) {
            if (!data.start_date) {  // eslint-disable-line max-depth
              this.handleError('start_date', requiredError);
            }
          }
        } else
          if (fieldName === '_date_warning' || fieldName === '_textarea' || fieldName === '_info_message') {
            // eslint-disable-line no-empty
          } else if (metadata[fieldName].required) {
            if (!data[fieldName]) {
              this.handleError(fieldName, requiredError);
              valid = false;
            } else if (data[fieldName] && (!error[fieldName] || error[fieldName].includes(requiredError))) {
              this.handleError(fieldName, null);
            }
          }
    }
    return valid;
  };

  validateField = (field, data) => {
    const metadata = this.getMetadata();
    const error = getErrors(this.props.error);
    if (metadata[field].required) {
      const requiredError = 'This field is required.';
      if (data && (!error[field] || error[field].includes(requiredError))) {
        this.handleError(field, null);
      }
    }
  };

  updateField = (field, data) => {
    this.props.updateDataField(field, data);
    this.validateField(field, data);
  };

  saveData = () => {
    const { data, options } = this.props;
    const dataType = this.getDataType();
    const dataTypeDisplayName = this.getDataTypeDisplayName();
    const saveMetadata = this.getMetadata();
    if (this.state.activeStep < this.getPublicTabs().length - 1) {
      this.props.saveAndStay(dataType, dataTypeDisplayName, data, saveMetadata, options, true).then(
        () => this.handleNext()
      );
    } else {
      const source = `/publicform/${this.getDataType()}/success`;
      this.props.saveAndNavigate(dataType, dataTypeDisplayName, data, saveMetadata, options, source, true);
    }
  };

  goToSuccessPage = () => {
    // if we have a documents page, the save is already done
    // so the "submit" on the documents page just sends the user to the success page
    this.props.navigateAfterSave('entity', this.props.data.id, `/publicform/${this.getDataType()}/success`);
  };

  getSectionTitle = (tab, data, readOnly) => {
    if (tab.type === 'segment') {
      return (
        <div>
          <div styleName="form-title form-stepper-title" id={`tab-section-${tab.id}-title`}>
            <div styleName="form-title-label-large">
              {tab.label}
            </div>
          </div>
          <SegmentTitle
            dataType={this.getDataType()}
            data={data}
            readOnly={readOnly}
            tab={tab}
          />
        </div>
      );
    } else if (tab.type === 'subheader') {
      return (
        <div styleName="form-title" id={`tab-section-${tab.id}-title`}>
          <div styleName="form-title-label-subheader">
            {tab.label}
          </div>
        </div>
      );
    }
    return (
      <div styleName="form-title form-stepper-title" id={`tab-section-${tab.id}-title`}>
        <div styleName="form-title-label-large">
          {tab.label}
        </div>
        {tab.extra && (
          <div styleName="form-title-label-extra">
            {tab.extra}
          </div>
        )}
      </div>
    );
  };

  getReviewSectionTitle = tab => (
    <div styleName="form-title" id={`tab-section-${tab.id}-title`}>
      <div styleName="form-title-label-large">
        {tab.label}
      </div>
    </div>
  );

  renderSpinnerIndicator = () => {
    const { saving } = this.props;
    if (saving) {
      return (
        <div style={{position: 'relative', padding: '1rem'}}>
          <DotmapsLoader color={colors.dotmapsBlue} display small />
        </div>
      );
    }
    return null;
  };

  isSaveDisabled = () => {
    const { data, error, saving } = this.props;
    const emptySegments = this.getEmptySegments();
    return saving || !isErrorsEmpty(data, error, emptySegments);
  };

  getEmptySegments = () => {
    // check to see if we should create entity with empty segments
    // if the location section is hidden, we need to send in a blank segments object to save
    const segmentTab = this.getPublicTabs().filter(tab => tab.type === 'segment');
    return segmentTab && segmentTab[0].hide_public;
  };

  submitFiles = () => {
    const { data } = this.props;
    if (data.id) {
      this.props.uploadStoredAttachments('entity', data.id, true).then(
        () => this.goToSuccessPage()
      );
    }
  };

  renderSubmitButton = activeTab => {
    let label = '';
    let onClick = null;

    if (activeTab.id === 'review') {
      label = 'SUBMIT';
      onClick = () => this.props.openDashboardDialog(dialog.SUBMIT_PUBLIC_FORM, { submitForm: this.saveData });
    } else if (activeTab.id === 'documents') {
      label = 'SUBMIT';
      onClick = () => this.props.openDashboardDialog(dialog.SUBMIT_PUBLIC_FORM, { submitForm: this.submitFiles });
    } else {
      label = 'NEXT';
      onClick = this.handleNext;
    }
    return (
      <Button
        color="primary"
        disabled={this.isSaveDisabled()}
        key="apply"
        onClick={onClick}
        variant="contained"
      >
        {label}
      </Button>
    );
  };

  getPublicTabs = () => {
    const dataType = this.getDataType();
    const detailsConfig = getDetailsConfig()[dataType];
    const tabs = getTabs(dataType).filter(tab => !tab.hide_public);
    tabs.push({
      id: 'review',
      label: 'Review',
      step: tabs.length
    });
    if (detailsConfig.public) {
      detailsConfig.public.forEach(publicStep => tabs.push({step: tabs.length, ...publicStep}));
    }
    return tabs;
  };

  getDrawer = () => {
    const { data } = this.props;
    if (data) {
      const error = getErrors(this.props.error);
      const metadata = this.getMetadata();
      const visibleFields = getVisibleFields(metadata);
      //readOnly if data has been saved
      const readOnly = Boolean(data.id);
      const tabs = this.getPublicTabs();
      const activeTab = tabs[this.state.activeStep];
      return (
        <Paper styleName="drawer-form-container" key="drawer" zDepth={2}>
          <Paper zDepth={0} styleName="details-stepper-container">
            <Stepper activeStep={this.state.activeStep} nonLinear orientation="vertical">
              {tabs.map(tab => (
                <Step key={tab.label}>
                  <StepLabel>{tab.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Paper>
          <div id="form-container" styleName="form-container" >
            <NonFieldErrors />
            {tabs.map(tab => {
              if (tab.step === this.state.activeStep) {
                return (
                  <div key={`tab-section-${tab.id}`} styleName="form-tab">
                    {this.getSectionTitle(tab, data, readOnly)}
                    <div>
                      {tab.fields && tab.fields.map(fieldName => {
                        if (!isFieldVisible(fieldName, visibleFields)) {
                          return null;
                        }
                        switch (fieldName) {
                        case '_date_warning':
                          return <ScheduleWarning data={data} dataType={this.getDataType()} />;
                        case 'segments':
                          return (
                            <div key={fieldName}>
                              <SegmentList
                                data={data}
                                dataType={this.getDataType()}
                                formCallbacks={this.getFormCallbacks()}
                                formState={this.state}
                                style={detailEdit.columnStyles.col100}
                                errors={error.segments || null}
                              />
                            </div>
                          );
                        // Render a textarea with a configured text.
                        case '_textarea':
                          return (
                            <div
                              style={detailEdit.columnStyles.col100}
                              styleName="col100 textarea"
                              dangerouslySetInnerHTML={{__html: tab._textarea_data}}
                            />
                          );
                        // Render an info message.
                        case '_info_message':
                          return (
                            <div style={detailEdit.columnStyles.col100} styleName="col100">
                              <Alert severity="info">
                                <div dangerouslySetInnerHTML={{__html: tab._info_message}} />
                              </Alert>
                            </div>
                          );
                        case '_date_range':
                          return (
                            <DateRange
                              data={data}
                              key={fieldName}
                              fieldNames={tab.date_range_fields}
                              metaData={metadata}
                              onChange={this.updateField}
                              onError={this.props.errorDataField}
                              dataType={this.getDataTypeDisplayName()}
                              readOnly={readOnly}
                              errors={error || null}
                            />
                          );
                        default:
                          return (
                            <div key={fieldName}>
                              {metadata[fieldName].title && this.getSectionTitle(metadata[fieldName].title)}
                              <FormElement
                                data={data}
                                dataType={this.getDataTypeDisplayName()}
                                key={fieldName}
                                fieldName={fieldName}
                                fieldMeta={metadata[fieldName]}
                                onChange={this.updateField}
                                onError={this.props.errorDataField}
                                value={data[fieldName]}
                                readOnly={readOnly}
                                templateProps={metadata[fieldName] || {}}
                                errors={error[fieldName] || null}
                                categoryTypes={this.props.categoryTypes}
                              />
                            </div>
                          );
                        } // end switch
                      })}
                      {tab.id === 'review' &&
                        <div>
                          <div styleName="review-spacer" />
                          {tabs.map(reviewTab => {
                            // re-map through the tabs and fields to build a review page of all the fields
                            if (reviewTab.id === 'review' || reviewTab.id === 'documents') {
                              return null;
                            }
                            return (
                              <div key={`review-tab-section-${reviewTab.id}`}>
                                <div styleName="review-title">{this.getReviewSectionTitle(reviewTab)}</div>
                                {reviewTab.fields.map(fieldName => {
                                  switch (fieldName) {
                                  case '_date_warning':
                                    return null;
                                  case '_textarea':
                                    return (
                                      <div
                                        style={{
                                          ...detailEdit.columnStyles.col100,
                                          color: colors.dotmapsBlack40
                                        }}
                                        styleName="col100 textarea"
                                        dangerouslySetInnerHTML={{__html: reviewTab._textarea_data}}
                                      />
                                    );
                                  // Don't render the info messages on the review step.
                                  case '_info_message':
                                    return null;
                                  case 'segments':
                                    return (
                                      <div key={fieldName}>
                                        <SegmentList
                                          data={data}
                                          dataType={this.getDataType()}
                                          formCallbacks={this.getFormCallbacks()}
                                          formState={this.state}
                                          style={detailEdit.columnStyles.col100}
                                          errors={error.segments || null}
                                          readOnly
                                        />
                                      </div>
                                    );
                                  case '_date_range':
                                    return (
                                      <DateRange
                                        data={data}
                                        key={fieldName}
                                        fieldNames={reviewTab.date_range_fields}
                                        metaData={metadata}
                                        onChange={this.updateField}
                                        onError={this.props.errorDataField}
                                        dataType={this.getDataTypeDisplayName()}
                                        errors={error || null}
                                        readOnly
                                      />
                                    );
                                  default:
                                    return (
                                      <div key={fieldName}>
                                        {metadata[fieldName].title && this.getSectionTitle(metadata[fieldName].title)}
                                        <FormElement
                                          data={data}
                                          dataType={this.getDataTypeDisplayName()}
                                          key={fieldName}
                                          fieldName={fieldName}
                                          fieldMeta={metadata[fieldName]}
                                          onChange={this.props.updateDataField}
                                          onError={this.props.errorDataField}
                                          value={data[fieldName] || ''}
                                          templateProps={metadata[fieldName] || {}}
                                          errors={error[fieldName] || null}
                                          categoryTypes={this.props.categoryTypes}
                                          readOnly
                                        />
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            );
                          })}
                        </div>
                      }
                      {tab.id === 'documents' &&
                        <Files params={tab} />
                      }
                    </div>
                  </div>
                );
              }
              return null;
            })}
            <div styleName="step-buttons">
              {this.renderSpinnerIndicator()}
              {this.state.activeStep !== 0 && activeTab.id !== 'documents' &&
                <Button
                  color="primary"
                  key="previous"
                  onClick={this.handleBack}
                  style={{ marginRight: '1rem' }}
                >
                  PREVIOUS
                </Button>
              }
              {this.renderSubmitButton(activeTab)}
            </div>
          </div>
          <EditScheduleDialog />
          <BulkScheduleDialog />
          <ViewScheduleDialog />
          <SubmitPublicFormDialog />
        </Paper>
      );
    }
    return null;
  };
}

const mapStateToProps = (state, props) => {
  const baseProps = baseMapStateToProps(state);
  const data = baseProps.data || null;
  const { model } = props.match.params;
  const { map_category_type: categoryTypes } = state.dataTypes;
  return {
    ...baseProps,
    entityType: model,
    data,
    categoryTypes
  };
};

export default connect(mapStateToProps, editMapDispatchToProps)(withRouter(PublicDetail));
