/* eslint-disable react/jsx-no-bind */
import React, { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { List } from 'material-ui/List';
import Subheader from 'material-ui/Subheader';
import { updatedSelectedFilters } from '@actions/calendar-actions';
import FilterItem from '@components/calendar-old/filter-item';
import { getCalendarEventTypeKey } from '@utils/calendar-utils';
import { getCalendarEventTypeItems } from '@selectors/calendar-selector';

// A base class for dialogs that shows a list of checkboxes.
const FilterList = ({ allText, headerText }) => {
  const dispatch = useDispatch();
  const dataType = getCalendarEventTypeKey();
  const items = useSelector(state => getCalendarEventTypeItems(dataType)(state));
  const { filters } = useSelector(state => state.calendar);
  const selected = filters[dataType] || [];

  useEffect(() => {
    dispatch(updatedSelectedFilters(dataType, Object.keys(items)));
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  // Returns true if all items are selected
  const isAllSelected = () => {
    // Comparison function
    if (selected) {
      const diff = (a, b) => a - b;  // eslint-disable-line id-length
      const sort = R.sort(diff);  // items might be unordered during selection
      return R.equals(sort(selected), sort(Object.keys(items)));
    }
    return false;
  };

  const onAllCheck = () => {
    if (isAllSelected()) {
      dispatch(updatedSelectedFilters(dataType, []));
    } else {
      dispatch(updatedSelectedFilters(dataType, Object.keys(items)));
    }
  };

  const onCheck = id => {
    if (selected && R.includes(id, selected)) {
      dispatch(updatedSelectedFilters(dataType, R.without([id], selected)));
    } else {
      dispatch(updatedSelectedFilters(dataType, R.uniq(R.append(id, selected || []))));
    }
  };

  return (
    <List>
      <Subheader>{headerText}</Subheader>
      <FilterItem
        item={{name: allText}}
        checked={isAllSelected()}
        onCheck={onAllCheck}
      />
      {Object.keys(items).map(key => (
        <FilterItem
          key={key}
          id={key}
          item={items[key]}
          checked={R.includes(key, selected)}
          onCheck={onCheck}
        />
      ))}
    </List>
  );
};

FilterList.propTypes = {
  allText: PropTypes.string,
  headerText: PropTypes.string
};

export default memo(FilterList);
