/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { colors, dotmapsGreen } from '@constants/colors';
import {
  getEntitiesListConfig,
  getEntityTypeLabel
} from '@constants/config';
import { downloadCSVExport } from '@actions/data-table-actions';
import EntityTypeIcon from '@icons/entity-type-icon';
import { Icon, IconButton } from '@mui';
import {
  canAddEntities,
  isReadOnly
} from '@utils/permission-utils';
import ActionDownloadIconButton from '@shared/action-download-icon-button';
import IconMenu from '@shared/icon-menu';
import IconMenuItem from '@shared/icon-menu-item';
import './actions.scss';

const Actions = () => {
  const dispatch = useDispatch();
  const map = useSelector(state => state.map);
  const { types } = useSelector(state => state.entities);

  const filters = {};
  Object.keys(types).forEach(type => {
    filters[type] = {
      ...map.filters,
      ...types[type].filters
    };
  });

  const entities = [];
  getEntitiesListConfig().forEach(entity => {
    if (canAddEntities(null, entity) && !isReadOnly(entity)) {
      entities.push(entity);
    }
  });

  const goToAdd = dataType => dispatch(push({ pathname: `/${dataType}/new`, state: { source: '/map' } }));

  const exportCSV = () => {
    Object.keys(filters).forEach(filter => {
      dispatch(downloadCSVExport(filter, null, filters[filter]));
    });
  };

  return (
    <div data-testid="map-top-bar-actions" style={{display: 'inline-flex', alignItems: 'center'}}>
      {entities.length > 0 &&
        <IconMenu
          icon={<Icon color={colors.neutral.white}>add</Icon>}
          iconButtonStyles={{}}
          MenuProps={{
            anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
            transformOrigin: { horizontal: 'center', vertical: 'top' }
          }}
          tooltip="Add"
        >
          {entities.map(entity => (
            <IconMenuItem
              key={entity}
              icon={<EntityTypeIcon type={entity} size="1.5rem" />}
              onClick={() => goToAdd(entity)}
            >
              New {getEntityTypeLabel(entity)}
            </IconMenuItem>
          ))}
        </IconMenu>
      }
      <Link to={'/map/print'}>
        <IconButton tooltip="Print">
          <Icon color={colors.neutral.white}>print</Icon>
        </IconButton>
      </Link>
      <ActionDownloadIconButton loaderColor={dotmapsGreen} onClick={exportCSV} />
    </div>
  );
};

export default memo(Actions);
