// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rbc-overlay {
    max-height: 10rem;
    overflow-y: auto;
}

.rbc-overlay .rbc-event {
    max-width: 360px;
    white-space: nowrap;
}

.rbc-overlay .rbc-event * {
    text-overflow: ellipsis;
    overflow: hidden;
}

.rbc-calendar > * {
    border-radius: 2px;
}

.rbc-month-header {
    background: #F7F7F7;
    height: 3rem;
}

.rbc-month-view .rbc-header {
    font-weight: 400;
    padding-top: 1em;
}

.rbc-month-view .rbc-header + .rbc-header {
    border-left: none;
}

.rbc-date-cell {
    font-size: 0.9rem;
    padding: 3px 5px 3px;
}

.rbc-show-more {
    font-weight: 400;
    padding: 3px 5px 3px;
    text-decoration: none;
}

.rbc-time-header-cell-single-day {
    display: block;
}
`, "",{"version":3,"sources":["webpack://./src/components/calendar-old/react-big-calendar-override.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;IACpB,qBAAqB;AACzB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".rbc-overlay {\n    max-height: 10rem;\n    overflow-y: auto;\n}\n\n.rbc-overlay .rbc-event {\n    max-width: 360px;\n    white-space: nowrap;\n}\n\n.rbc-overlay .rbc-event * {\n    text-overflow: ellipsis;\n    overflow: hidden;\n}\n\n.rbc-calendar > * {\n    border-radius: 2px;\n}\n\n.rbc-month-header {\n    background: #F7F7F7;\n    height: 3rem;\n}\n\n.rbc-month-view .rbc-header {\n    font-weight: 400;\n    padding-top: 1em;\n}\n\n.rbc-month-view .rbc-header + .rbc-header {\n    border-left: none;\n}\n\n.rbc-date-cell {\n    font-size: 0.9rem;\n    padding: 3px 5px 3px;\n}\n\n.rbc-show-more {\n    font-weight: 400;\n    padding: 3px 5px 3px;\n    text-decoration: none;\n}\n\n.rbc-time-header-cell-single-day {\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
