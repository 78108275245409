/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { showFilePreview } from '@actions/attachment-actions';
import { Icon } from '@mui';
import { canRespondTaskActivity } from '@utils/permission-utils';
import DeleteButton from '@components/attachments/delete-button';
import { getIconColor, getIconName } from '@components/attachments/actions';
import './card.scss';

const ChipCard = ({
  activity,
  attachment,
  index,
  isReadOnly
}) => {
  const { file_content_type, file_name } = attachment;
  const { roles } = activity;

  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(showFilePreview('task_activity', activity.id, index));
  };

  const isResponder = canRespondTaskActivity(activity, roles);

  return (
    <div styleName="chip-card-container">
      <div styleName="chip-card-icon">
        <Icon color={getIconColor(file_content_type)} onClick={onClick}>
          {getIconName(file_content_type)}
        </Icon>
      </div>
      <div onClick={onClick} role="presentation" styleName="chip-card-text">
        {file_name}
      </div>
      {!isReadOnly && isResponder && (
        <DeleteButton
          attachment={attachment}
          icon={<Icon>close</Icon>}
        />
      )}
    </div>
  );
};

ChipCard.propTypes = {
  activity: PropTypes.object,
  attachment: PropTypes.object,
  index: PropTypes.number,
  isReadOnly: PropTypes.bool
};

export default ChipCard;
