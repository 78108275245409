/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { getPriorityConfig } from '@constants/config';
import { BASE_URL, getBucketEndPoint } from '@constants/endpoints';
import Tooltipable from '@shared/tooltipable';
import { isOldIE } from '@utils/browser-detect';
import { getTaskFieldIcon } from '@utils/workflow-utils';
import './icon-utils.scss';

const getIcon = (url, active = false, scaleFactor = 0) => {
  const icon = {
    anchor: { x: 15, y: active ? 30 : 15 },  // eslint-disable-line id-length
    url
  };

  if (scaleFactor > 0) {
    icon.anchor.x = icon.anchor.x + scaleFactor / 2;  // eslint-disable-line id-length
    icon.anchor.y = icon.anchor.y + scaleFactor / 2;  // eslint-disable-line id-length
    icon.scaledSize = { width: 30 + scaleFactor, height: 30 + scaleFactor };
  }

  if (isOldIE()) {
    // Specify the scaled size to avoid the 'InvalidStateError' on Google map's marker.js,
    // see this for more information:
    //
    //     https://stackoverflow.com/questions/27261346/custom-svg-markers-wont-display-in-ie-11
    //
    // We must also set the optimized property, but that's part of the Marker options,
    // not the icon ones (I added that where we define each Marker).
    //
    // It's better to only do this for IE, since this changes the Marker icon size slightly
    // (only 1px or 2), and it's better to not change anything for Chrome/Safari.
    icon.scaledSize = { width: 30, height: 30 };
  }

  return icon;
};

export const getAgencyIcon = agencyType => {
  if (agencyType) {
    return `${BASE_URL}/icons/agency/${agencyType.id}`;
  }
  return null;
};

export const getCategoryIcon = (type, icon) => `${BASE_URL}/icons/map/${type}/${icon}`;

export const getLayerIconUrl = icon => {
  // If the icon name starts with the special pipe character "|"
  // it means we'll use a generic SVG icon.
  // We have a line, polygon, circle and dotted_line icons,
  // and we can reuse them with different colors.
  if (icon?.startsWith('|')) {
    // Examples:
    //   |line|#FF0000
    //   |polygon|#00FF00
    //   |circle|#00FF00
    //   ...
    const match = icon.match(/\|(\w+)\|(\w+|#[0-9a-fA-F]{6})/);
    if (match) {
      const shapeTypeName = match[1];
      const color = match[2];
      return `${BASE_URL}/icons/layer/${shapeTypeName}/?color=${color.replace(/^#/, '')}`;
    }
  }
  return `${getBucketEndPoint()}/layer/icon/v2/${icon}`;
};

export const getLayerIcon = (icon, scaleFactor = 0) => {
  const url = getLayerIconUrl(icon);
  return getIcon(url, false, scaleFactor);
};

export const filterLayerIcon = icon => {
  const url = getLayerIconUrl(icon);
  return <img src={url} alt={icon} width={15} />;
};

export const getStarIcon = () => `${getBucketEndPoint()}/filter/icon/star.svg`;

export const getEntityStarIcon = active => {
  const icon = getIcon(`${getBucketEndPoint()}/filter/icon/star.svg`, active);
  return {
    ...icon,
    anchor: {
      ...icon.anchor,
      // eslint-disable-next-line id-length
      x: icon.anchor.x - 15  // Move the star to the right.
    }
  };
};

const getPriorityIcon = (priority, size = 18) => `${getBucketEndPoint()}/priority/icon/${size}/${priority}.svg`;

const getPriorityItem = priority => R.values(getPriorityConfig()).find(item => item.value === priority);

export const getPriorityIconUrl = priority => {
  const priorityItem = getPriorityItem(priority);
  if (priorityItem) {
    return getPriorityIcon(priorityItem.name);
  }
  return null;
};

export const renderPriorityIconAndLabel = priority => {
  const priorityItem = getPriorityItem(priority);
  const icon = priorityItem ? getPriorityIcon(priorityItem.name) : null;
  return (
    <div styleName="priority-container">
      {icon && <img styleName="icon" src={icon} alt="" />}
      <div styleName="label">
        {priorityItem ? priorityItem.label : 'TBD'}
      </div>
    </div>
  );
};

export const renderCustomIconAndLabel = (name, icon, id = null) => (
  <div styleName="item-container">
    <div styleName="icon">
      {icon}
    </div>
    <div styleName="label" id={`item_label_${id}`}>
      {name}
    </div>
  </div>
);

export const renderIconAndLabel = (name, icon, id = null) => (
  <div data-testid="icon-and-label" styleName="item-container">
    <img styleName="icon" src={icon} alt="" />
    <div data-testid="icon-and-label-label" styleName="label" id={`item_label_${id}`}>
      {name}
    </div>
  </div>
);

export const renderCustomFieldIconAndLabel = (fieldTypes, item) => {
  const field = fieldTypes[item];
  const icon = getTaskFieldIcon({ type: field.id });
  return renderCustomIconAndLabel(field.name, icon, field.id);
};

export class PriorityIcon extends Tooltipable {
  render() {
    const { priority } = this.props;
    const priorityItem = getPriorityItem(priority);
    const icon = getPriorityIcon(priorityItem.name);
    return (
      <div styleName="priority-container">
        <img
          onMouseOver={this.hoverOn}
          onMouseOut={this.hoverOff}
          src={icon}
          alt=""
        />
        {this.renderTooltip(priorityItem.tooltip)}
      </div>
    );
  }
}

PriorityIcon.propTypes = {
  priority: PropTypes.number
};

const getEntityAgencyMarker = (agencyTypeId, active = false, mode = null) => {
  let url = null;
  if (agencyTypeId) {
    url = `${BASE_URL}/icons/agency/${agencyTypeId}/${mode || 'default'}`;
    if (active) {
      url = `${url}/active`;
    }
  }
  return getIcon(url, active);
};

export const getEntityMarker = (agencyTypeId, type, icon, active = false, mode = null) => {
  if (icon) {
    let url = `${BASE_URL}/icons/map/${type}/${icon}/${mode || 'default'}`;
    if (active) {
      url = `${url}/active`;
    }
    return getIcon(url, active);
  }
  if (agencyTypeId) {
    return getEntityAgencyMarker(agencyTypeId, active, mode);
  }
  return null;
};

export const getGroupIcon = () => `${getBucketEndPoint()}/group/group-legend.svg`;

export const getGroupLabelIcon = () => `${getBucketEndPoint()}/group/group-labels.svg`;

export const getDefaultEntityIcon = (entity, types) => {
  const entityTypeName = entity.type_name;
  const entityType = Object.values(types).find(type => type.name === entityTypeName);
  return entityType.icon;
};

export const getEntityLegend = (agencyType, type, icon) => {
  if (icon) {
    return `${BASE_URL}/icons/map/${type}/${icon}`;
  }
  if (agencyType && agencyType.id) {
    return `${BASE_URL}/icons/agency/${agencyType.id}`;
  }
  return null;
};

export const getEntityCategoryIcon = (entity, types) => {
  const categories = entity.category_dict;
  if (categories) {
    const firstCategoryWithIcon = categories.find(category => category.icon);
    if (firstCategoryWithIcon) {
      return firstCategoryWithIcon.id;
    }
  }
  return getDefaultEntityIcon(entity, types);
};
