import React, { memo } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { dotmapsGreen } from '@constants/colors';
import { isNewLayout } from '@constants/config';
import Header from '@components/map/tray/header';
import Divider from '@material-ui/core/Divider';
import { getTrayTop } from '@selectors/map-selector';
import DetailsTableBlock from '@shared/details-table-block';
import DotmapsLoader from '@shared/dotmaps-loader';
import './tray.scss';

const Layer = () => {
  const tray = useSelector(getTrayTop);

  const trayData = (tray.trayType === 'layer' ? tray.data : null);
  if (!trayData || R.isEmpty(trayData)) {
    return null;
  }

  if (trayData.pending) {
    return (
      <div styleName="map-tray-body-wrapper">
        <Header />
        <DotmapsLoader color={dotmapsGreen} display small />;
      </div>
    );
  }

  const entity = trayData.layer;

  const getAttrs = layer => {
    const fieldList = [];
    for (const field in layer) {
      if (Object.prototype.hasOwnProperty.call(layer, field)) {
        fieldList.push(<DetailsTableBlock key={field} fields={[{label: field, value: layer[field] }]} />);
      }
    }
    return fieldList;
  };

  return (
    <div styleName="map-tray-body-wrapper">
      <Header title={entity.layerType} />
      <Divider />
      <div styleName={isNewLayout() ? 'map-tray-body' : 'map-tray-body map-tray-body-old'}>
        <div styleName="map-tray-content">
          {getAttrs(entity)}
        </div>
      </div>
    </div>
  );
};

export default memo(Layer);
