import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import DefaultDate from '@shared/formatted-date-helper';
import './details-block.scss';

const DetailsBlock = ({ label, info, date, edit }) => {
  const content = date ? <DefaultDate value={info || moment()} /> : info;
  return (
    <div data-testid="details-block" styleName="details-block-container">
      <div data-testid="details-block-label" styleName="label">
        {label}
      </div>
      { edit ? <div styleName="info-edit">{content}</div> :
        <div data-testid="details-block-info" styleName="info">{content}</div> }
    </div>
  );
};

DetailsBlock.propTypes = {
  date: PropTypes.bool,
  edit: PropTypes.bool,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string
};

export default DetailsBlock;
