import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toggleMarkerModal, toggleLegend } from '@actions/map-actions';
import { textColor, secondaryColor } from '@constants/colors';
import PoiMarkerIcon from '@icons/poi-marker-icon';
import MapToolbarIconButton from '@shared/map-toolbar-icon-button';
import { shallowEqual } from '@utils/react-utils';
import './markers-icon.scss';

class MarkersIcon extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.markerModalOpen !== nextProps.markerModalOpen) {
      if (nextProps.markerModalOpen && this.props.legendOpen) {
        this.props.toggleLegend();
      }
    }
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState) {
    return (
      !shallowEqual(nextProps.legendOpen, this.props.legendOpen) ||
      !shallowEqual(nextProps.markerModalOpen, this.props.markerModalOpen)
    );
  }

  render() {
    const { markerModalOpen } = this.props;
    return (
      <div styleName="map-markers-icon">
        <MapToolbarIconButton
          onClick={this.props.toggleMarkerModal}
          iconInactive={!markerModalOpen}
          tooltip="Markers"
        >
          <PoiMarkerIcon
            color={markerModalOpen ? secondaryColor : textColor}
            style={{...(!markerModalOpen && { opacity: 0.5 })}}
          />
        </MapToolbarIconButton>
      </div>
    );
  }
}

MarkersIcon.propTypes = {
  legendOpen: PropTypes.bool,
  markerModalOpen: PropTypes.bool,
  toggleLegend: PropTypes.func,
  toggleMarkerModal: PropTypes.func
};

const mapStateToProps = state => {
  const { legendOpen } = state.map;
  const { markerModalOpen } = state.map.markers;
  return { markerModalOpen, legendOpen };
};

export default connect(mapStateToProps, { toggleMarkerModal, toggleLegend })(MarkersIcon);
