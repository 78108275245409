/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import Section from '@components/workflow/section';
import { getWorkflowCustomFieldSections } from '@constants/config';
import { getTaskFields, renderEditFields } from '@utils/workflow-utils';
import './task-type-preview-form.scss';

const TaskTypePreviewForm = ({ taskType }) => {
  const sections = getWorkflowCustomFieldSections();
  return (
    <div data-testid="task-type-preview-form" styleName="task-type-preview-form">
      {sections.map(section => {
        // If the response fields are not enabled, don't render that section:
        if (section.name === 'Response' && !taskType.response) {
          return null;
        }

        const fields = getTaskFields(section, taskType, null, true);
        if (fields.length > 0) {
          return (
            <Section key={section.id} name={section.name}>
              {renderEditFields(section.id, fields, true)}
            </Section>
          );
        }
        return null;
      })}
    </div>
  );
};

TaskTypePreviewForm.propTypes = {
  taskType: PropTypes.object
};

export default TaskTypePreviewForm;
