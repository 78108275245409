import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'material-ui/SvgIcon';

const DrawLineIcon = props => (
  <SvgIcon {...props} style={{...props.style, height: '24px', width: '24px'}} viewBox="0 0 24 24" fill="none">
    <path fillRule="evenodd" clipRule="evenodd"
      d="M14.5185 9.54815C16.3267 9.54815 17.7925 8.0823 17.7925 6.27407C17.7925 4.46585 16.3267 3 14.5185 3C12.7102
      3 11.2444 4.46585 11.2444 6.27407C11.2444 6.56667 11.2828 6.8503 11.3548 7.1202L7.58535 9.34774C6.99316 8.75751
      6.17623 8.39263 5.27407 8.39263C3.46585 8.39263 2 9.85848 2 11.6667C2 13.4749 3.46585 14.9408 5.27407 14.9408C6.08537
      14.9408 6.82774 14.6457 7.39972 14.157L11.2767 16.5981C11.2554 16.7487 11.2444 16.9027 11.2444 17.0593C11.2444
      18.8675 12.7103 20.3334 14.5185 20.3334C16.3267 20.3334 17.7926 18.8675 17.7926 17.0593C17.7926 16.7576 17.7518
      16.4655 17.6754 16.1881L20.6736 13.9824C21.102 13.6672 21.1938 13.0644 20.8786 12.636C20.5635 12.2077 19.9607 12.1159
      19.5323 12.431L16.6359 14.5619C16.065 14.0775 15.3259 13.7852 14.5185 13.7852C13.5642 13.7852 12.7053 14.1935 12.1069
      14.8449L8.43272 12.5315C8.50797 12.256 8.54815 11.9661 8.54815 11.6667C8.54815 11.4559 8.52823 11.2498 8.49018
      11.0501L12.3787 8.75221C12.9525 9.24817 13.7005 9.54815 14.5185 9.54815ZM14.5185 7.62222C13.7739 7.62222 13.1703 7.01864
      13.1703 6.27407C13.1703 5.52951 13.7739 4.92593 14.5185 4.92593C15.263 4.92593 15.8666 5.52951 15.8666 6.27407C15.8666
      7.01864 15.263 7.62222 14.5185 7.62222Z" fill={props.color}/>
  </SvgIcon>
);

DrawLineIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object
};

export default DrawLineIcon;
