/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable dot-notation */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { includes } from 'lodash';
import IconButton from 'material-ui/IconButton';
import Popover from '@material-ui/core/Popover';
import { Toolbar, ToolbarGroup, ToolbarSeparator } from 'material-ui/Toolbar';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import * as colors from '@constants/colors';
import {
  getCalendarEntity,
  getDefaultEntity,
  getEntitiesListConfig,
  isDashboardPortalEnabled,
  isNewLayout,
  isWorkflowEnabled
} from '@constants/config';
import { ENTITY_DATA_TYPE } from '@components/config/constants';
import { topBarStyles } from '@constants/mui-theme';
import MapIcon from '@icons/header/map-icon';
import AdminIcon from '@icons/header/admin-icon';
import DashboardIcon from '@icons/header/dashboard-icon';
import LibraryIcon from '@icons/header/library-icon';
import CalendarIcon from '@icons/header/calendar-icon';
import WorkflowIcon from '@icons/header/workflow-icon';
import LogoutIcon from '@icons/header/logout-icon';
import SettingsIcon from '@icons/header/settings-icon';
import { Icon } from '@mui';
import NotificationsList from '@shared/header/notifications-list';
import WaffleItem from '@shared/waffle-item';
import { getUserAvatar } from '@utils/data-table/user';
import { isAppAdmin, isSuperuser } from '@utils/permission-utils';
import { openHelpPage } from '@utils/shared-utils';
import { withRouter } from '@utils/router-utils';
import { getFirstWorkflowMenuOption } from '@utils/workflow-utils';
import styles from './action-items.scss';

const CustomBadge = withStyles({
  root: {
    color: topBarStyles.oldIcons.color,
    '& .MuiBadge-badge': {
      backgroundColor: colors.dotmapsBadgeRed
    }
  }
})(Badge);

class ActionItems extends PureComponent {
  state = {
    notificationsOpen: false,
    userOpen: false,
    waffleOpen: false
  };

  notificationsOpen = event => {
    event.preventDefault();

    this.setState({
      notificationsOpen: true,
      notificationsAnchorEl: event.currentTarget
    });
  };

  notificationsClose = () => this.setState({ notificationsOpen: false });

  userOpen = event => {
    event.preventDefault();

    this.setState({
      userOpen: true,
      userAnchorEl: event.currentTarget
    });
  };

  userClose = () => this.setState({ userOpen: false });

  waffleOpen = event => {
    event.preventDefault();

    this.setState({
      waffleOpen: true,
      waffleAnchorEl: event.currentTarget
    });
  };

  waffleClose = () => this.setState({ waffleOpen: false });

  closeAll = () => this.setState({
    notificationsOpen: false,
    userOpen: false,
    waffleOpen: false
  });

  getSelectedRouteIcon = () => {
    const { location } = this.props;
    const path = location.pathname;
    let icon = null;

    if (path.startsWith('/map')) {
      icon = <MapIcon style={topBarStyles.pngIcons} />;
    } else if (path.startsWith('/list')) {
      icon = <LibraryIcon style={topBarStyles.pngIcons} />;
    } else if (path.startsWith('/dashboard')) {
      icon = <DashboardIcon style={topBarStyles.pngIcons} />;
    } else if (path.startsWith('/calendar')) {
      icon = <CalendarIcon style={topBarStyles.pngIcons} />;
    } else if (path.startsWith('/workflow') && isWorkflowEnabled()) {
      icon = <WorkflowIcon style={topBarStyles.pngIcons} />;
    } else if (path.startsWith('/admin') && isAppAdmin()) {
      icon = <AdminIcon style={topBarStyles.pngIcons} />;
    }

    return icon ? <IconButton disabled disableTouchRipple>{icon}</IconButton> : null;
  };

  isCalendarEnabled = () => {
    // Check if we have defined a calendar entity:
    const calendarEntity = getCalendarEntity();
    if (calendarEntity) {
      const entities = getEntitiesListConfig();
      // If the calendar entity exists in the list of entities, the calendar is enabled.
      return includes(entities, calendarEntity);
    }
    return false;
  };

  goTo = pathname => {
    const { location } = this.props;
    const source = location.pathname;
    this.userClose();
    this.props.push({ pathname, state: { source } });
  };

  goToSettings = () => this.goTo('/settings/profile');

  goToLogout = () => this.goTo('/logout');

  render() {
    const { notificationsCount, user } = this.props;
    if (!user) {
      return null;
    }
    const calendarEnabled = this.isCalendarEnabled();
    const userAvatarIcon = getUserAvatar(user, 'large');
    const userAvatarButton = getUserAvatar(user, 'small', this.userOpen);
    return (
      <Toolbar style={topBarStyles.toolbar}>
        <ToolbarGroup>
          <ToolbarSeparator style={topBarStyles.toolbarSeparator.left} />
          {this.getSelectedRouteIcon()}
          <IconButton onClick={this.waffleOpen}>
            <Icon color={topBarStyles.oldIcons.color}>apps</Icon>
          </IconButton>
          <Popover
            open={this.state.waffleOpen}
            anchorEl={this.state.waffleAnchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={this.waffleClose}
          >
            <Toolbar style={topBarStyles.toolbar}>
              <ToolbarGroup>
                <WaffleItem
                  icon={<MapIcon style={topBarStyles.waffleIcons} />}
                  label="Maps"
                  onClick={this.closeAll}
                  to="/map"
                />
                <WaffleItem
                  icon={<LibraryIcon style={topBarStyles.waffleIcons} />}
                  label="Library"
                  onClick={this.closeAll}
                  to={isNewLayout() ? `/library/${ENTITY_DATA_TYPE}/${getDefaultEntity()}` : `/list/data/${getDefaultEntity()}`}
                />
                {isDashboardPortalEnabled() && (
                  <WaffleItem
                    icon={<DashboardIcon style={topBarStyles.waffleIcons} />}
                    label="Dashboard"
                    onClick={this.closeAll}
                    to="/dashboard"
                  />
                )}
                {isWorkflowEnabled() && (
                  <WaffleItem
                    icon={<WorkflowIcon style={topBarStyles.waffleIcons} />}
                    label="Workflow"
                    onClick={this.closeAll}
                    to={getFirstWorkflowMenuOption()}
                  />
                )}
                <WaffleItem
                  disabled={!calendarEnabled}
                  icon={<CalendarIcon style={{...topBarStyles.waffleIcons, opacity: calendarEnabled ? 1 : 0.5}} />}
                  label="Calendar"
                  onClick={calendarEnabled ? this.closeAll : null}
                  to={calendarEnabled ? '/calendar' : null}
                />
                {/*
                The admin portal should appear for app admins only, but I changed it to use
                isSuperuser() so it only appears for SADA admins for now, we should use again
                isAppAdmin() when the whole feature is finished.

                Also, the current admin portal only contains workflow options, so, don't display
                it if the workflow functionality is not enabled.
                */}
                {isSuperuser() && isWorkflowEnabled() && (
                  <WaffleItem
                    icon={<AdminIcon style={topBarStyles.waffleIcons} />}
                    label="Admin"
                    onClick={this.closeAll}
                    to="/admin"
                  />
                )}
              </ToolbarGroup>
            </Toolbar>
          </Popover>
          <ToolbarSeparator style={topBarStyles.toolbarSeparator.right} />
          <IconButton onClick={this.notificationsOpen} style={{ marginRight: '0.5rem' }} role="img" aria-label="notifications">
            <CustomBadge
              badgeContent={notificationsCount}
              color="secondary"
              max={9}
            >
              <NotificationsIcon />
            </CustomBadge>
          </IconButton>
          <Popover
            open={this.state.notificationsOpen}
            anchorEl={this.state.notificationsAnchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={this.notificationsClose}
            classes={{paper: styles['notifications-list-container']}}
          >
            {this.state.notificationsOpen && <NotificationsList />}
          </Popover>
          <IconButton onClick={openHelpPage} style={{ marginRight: '1rem' }} role="img" aria-label="help">
            <Icon color={topBarStyles.oldIcons.color}>help</Icon>
          </IconButton>
          {userAvatarButton}
          <Popover
            open={this.state.userOpen}
            anchorEl={this.state.userAnchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={this.userClose}
          >
            <div className={styles['action-items-container']} >
              <div className={styles['user-card']} >
                <div className={styles.avatar} >{userAvatarIcon}</div>
                <div className={styles.content} >
                  <div className={styles.name} >{user.name}</div>
                  <div className={styles.email} >{user.email}</div>
                  <div>{user.permission}</div>
                </div>
              </div>
              <div className={styles.divider} />
              <div className={styles.list} >
                <div className={styles['list-item']} >
                  <div className={styles.icon} >
                    <SettingsIcon />
                  </div>
                  <div className={styles['link']} onClick={this.goToSettings} role="presentation">Settings</div>
                </div>
                <div className={styles['list-item']}>
                  <div className={styles.icon}>
                    <LogoutIcon />
                  </div>
                  <div className={styles['link']} onClick={this.goToLogout} role="presentation">Logout</div>
                </div>
              </div>
            </div>
          </Popover>
        </ToolbarGroup>
      </Toolbar>
    );
  }
}

ActionItems.propTypes = {
  location: PropTypes.object,
  notificationsCount: PropTypes.number,
  push: PropTypes.func,
  user: PropTypes.object
};

const mapStateToProps = state => {
  const { config: { user } } = state;
  const { unread } = state.notifications;
  return { notificationsCount: unread, user };
};

export default connect(mapStateToProps, { push })(withRouter(ActionItems));
