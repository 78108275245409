/* eslint-disable max-nested-callbacks */
import axios from 'axios';
import { push } from 'connected-react-router';
import { capitalize, isEmpty } from 'lodash';
import * as R from 'ramda';
import { fitBounds, openTrayAction, setDrawingProps } from './map-actions';
import {
  CHANGE_GROUP_FIELD_VALUE,
  GET_GROUP_BY_ID_SUCCESS,
  GROUP_APPLY_SHAPE,
  GROUP_CLEAR_DATE,
  GROUP_CREATE_MEMBERS_FETCH_SUCCESS,
  GROUP_DIALOG_SET_ADD_ITEM,
  GROUP_EDIT_LOADING,
  GROUP_OPTIONS_FETCH_SUCCESS,
  GROUP_OPTIONS_FETCH_ERROR,
  GROUPS_DETAIL_MODIFIED,
  GROUPS_DETAIL_SAVING,
  GROUPS_DETAIL_SAVING_FAIL,
  GROUPS_DETAIL_SAVING_SUCCESS,
  GROUPS_DISMISS_GANTT_DIALOG,
  GROUP_SET_LOADING,
  GROUP_SET_SHAPE,
  GROUPS_FETCH_POLYGON_START,
  GROUPS_FETCH_POLYGON_SUCCESS,
  GROUPS_FETCH_POLYGON_FAIL,
  GROUPS_FETCH_SET_LOADING,
  GROUPS_FETCH_SUCCESS,
  GROUPS_ENTITY_OVERLAPS_FETCH_SUCCESS,
  GROUPS_REMOVE_ENTITY_SUCCESS,
  GROUPS_SET_DATE_RANGE,
  GROUPS_SET_GANTT_FILTER,
  GROUPS_SET_LAYER_BOUNDARIES_VISIBLE,
  GROUPS_SET_LAYER_VISIBLE,
  GROUPS_SET_PRE_FILL,
  GROUPS_CLEAR_PRE_FILL,
  GROUPS_TOGGLE_BOUNDARIES,
  GROUPS_TOGGLE_GANTT_LOCATION_TYPE,
  GROUPS_TOGGLE_LABELS,
  HANDLE_SERVER_ERROR,
  MAP_UPDATE_GROUP_TRAY,
  GROUPS_DISABLE_DRAWING_MANAGER,
  GROUPS_ENABLE_DRAWING_MANAGER
} from '@constants/action-types';
import { GROUP_DATA_TYPE } from '@components/config/constants';
import {
  getEntityTypeLabel,
  getFiltersGlobal,
  getGroupDetailsConfig,
  getGroupTypesConfig,
  isNewLayout
} from '@constants/config';
import * as dialog from '@constants/dialogs';
import { BASE_API_URL } from '@constants/endpoints';
import { GROUP_TRAY } from '@constants/map-trays';
import { buildConfirmableAction } from '@utils/confirmation-utils';
import {
  decodeDetails, getAction, mergeActionWithTemplate, computeMetadataDefaults
} from '@utils/data-detail-utils';
import { optimizeEntitiesForList } from '@utils/entity-utils';
import { generateGroupBounds, validateGroupData, optimizeGroupsForMap } from '@utils/group-utils';
import { createUrlFromFilters } from '@utils/map-utils';
import { overlapUrl } from '@utils/overlap-utils';
import { pagedFetchThunk, pluralize } from '@utils/shared-utils';
import { fetchDataDetail } from './data-detail-actions';
import { discardChangesAction } from './confirmation-actions';
import {
  closeDashboardDialog,
  openDashboardDialogAction,
  pushDashboardMessage,
  setResolutionLoading
} from './dashboard-actions';
import { setLogReload } from './logs-actions';
import { pushApplicationMessage } from './messages-actions';
import { getServerErrorAction } from './shared-actions';

export const getGroupUrl = groupId => `${BASE_API_URL}/group/${groupId ? `${groupId}/` : ''}`;

const getGanttGroupUrl = groupId => `${BASE_API_URL}/gantt_group/${groupId ? `${groupId}/` : ''}`;

export const entityUrl = `${BASE_API_URL}/map/`;

const fetchGroupsSuccess = payload => ({ type: GROUPS_FETCH_SUCCESS, ...payload });

const setGroupsFetchLoadingAction = () => dispatch => {
  dispatch({ type: GROUPS_FETCH_SET_LOADING });
  return Promise.resolve();
};

const removeEntityFromGroup = (response, entityId, groupId) => ({
  type: GROUPS_REMOVE_ENTITY_SUCCESS,
  payload: {
    response,
    groupId,
    entityId
  }
});

const setModifiedAction = modified => ({ type: GROUPS_DETAIL_MODIFIED, modified });

export const setModified = modified => dispatch => {
  dispatch(discardChangesAction(false));
  dispatch(setModifiedAction(modified));
};

const newDateRangeValue = payload => ({ type: GROUPS_SET_DATE_RANGE, payload });

export const setDateRange = dates => dispatch => {
  dispatch(setModified(true));
  dispatch(newDateRangeValue(dates));
  // When setting the date range, apply the shape again to fetch
  // entities within boundary but now for the new date range.
  dispatch({ type: GROUP_APPLY_SHAPE });
};

export const clearGroupDate = () => dispatch => {
  dispatch({ type: GROUP_CLEAR_DATE });
  dispatch({ type: GROUP_APPLY_SHAPE });
};

export const fetchGroupsData = () => (dispatch, getState) => {
  return dispatch(setGroupsFetchLoadingAction()).then(async () => {
    const state = getState();
    const url = createUrlFromFilters(state.groups.filters, 'group');
    await axios
      .get(url)
      .then(
        ({ data }) => {
          const groups = optimizeGroupsForMap(data);
          dispatch(fetchGroupsSuccess({ groups }));
        },
        error => dispatch(getServerErrorAction('group', error))
      );
  });
};

const removeEntityFromGroupWithMessage = (entityId, groupId, successMessage, callback) => dispatch => {
  const url = `${getGroupUrl(groupId)}remove_entities/`;
  const payload = { ids: [entityId] };
  return axios.post(url, payload).then(
    data => {
      if (callback) {
        callback();
      }
      dispatch(removeEntityFromGroup(data, entityId, groupId));
      dispatch(pushApplicationMessage(successMessage));
      return data;
    },
    ({ response }) => {
      dispatch(getServerErrorAction('group', response));
    }
  );
};

export const getConfirmableRemoveEntityFromGroupWithMessage = buildConfirmableAction(
  removeEntityFromGroupWithMessage,
  'groupsActionsRemoveEntityFromGroupWithMessage'
);

const getFetchEntitiesFromPolygonStartAction = type => ({ type: GROUPS_FETCH_POLYGON_START, payload: { type } });

const getFetchEntitiesFromPolygonSuccessAction = payload => ({ type: GROUPS_FETCH_POLYGON_SUCCESS, payload });

const getFetchEntitiesFromPolygonFailAction = () => ({ type: GROUPS_FETCH_POLYGON_FAIL });

export const fetchEntitiesFromPolygon = (filters, type) => dispatch => {
  dispatch(getFetchEntitiesFromPolygonStartAction(type));
  const url = `${entityUrl}?type=${type}&compact=True`;
  return dispatch(pagedFetchThunk(
    url,
    { params: filters },
    data => innerDispatch => {
      const payload = {
        entities: data.results,
        type
      };
      innerDispatch(getFetchEntitiesFromPolygonSuccessAction(payload));
    },
    error => innerDispatch => {
      innerDispatch(getFetchEntitiesFromPolygonFailAction(error));
    }
  ));
};

const setGroupLoadingAction = loading => ({ type: GROUP_SET_LOADING, loading });

export const setGroupLoading = loading => dispatch => {
  dispatch(setGroupLoadingAction(loading));
  return Promise.resolve();
};

export const setGroupApplyShape = () => dispatch => dispatch({ type: GROUP_APPLY_SHAPE });

const getSetGroupShapeAction = shape => ({ type: GROUP_SET_SHAPE, shape });

export const setGroupShape = shape => dispatch => {
  dispatch(getSetGroupShapeAction(shape));
  return Promise.resolve();
};

export const disableDrawingManager = () => dispatch => {
  dispatch({ type: GROUPS_DISABLE_DRAWING_MANAGER });
  return Promise.resolve();
};

export const enableDrawingManager = () => dispatch => {
  dispatch({ type: GROUPS_ENABLE_DRAWING_MANAGER });
  return Promise.resolve();
};

const getGroupNewPath = groupType => `/group/${groupType}/new`;

export const createNewGroupWithEntities = (entityIds, groupType, includeOverlaps) => (dispatch, getState) => {
  const source = getState().router.location.pathname || null;
  const state = {
    entityIds,
    groupType,
    includeOverlaps,
    source
  };
  dispatch(push(
    { pathname: getGroupNewPath(groupType), state }
  ));
};

export const createNewGroupWithGeometry = (shape, groupType) => (dispatch, getState) => {
  const source = getState().router.location.pathname || '/map';
  dispatch(push(
    { pathname: getGroupNewPath(groupType), state: { shape, source } }
  ));
};

const setGroupEditLoading = () => ({ type: GROUP_EDIT_LOADING });

const getGroupByIdSuccessAction = (data, isGantt) => ({
  type: GET_GROUP_BY_ID_SUCCESS,
  payload: {
    data,
    isGantt  // Tells whether to process the data on the store for Gantt chart use.
  }
});

const groupMembersFetchSuccessAction = payload => ({
  type: GROUP_CREATE_MEMBERS_FETCH_SUCCESS,
  payload
});

export const getGanttGroupById = (id, setLoading = true, getBlocks = false) => async dispatch => {
  if (setLoading) {
    dispatch(setGroupEditLoading());
  }
  try {
    const groupUrl = getGanttGroupUrl(id);
    // If we set getBlocks, each entity will return all the blocks
    // that intersect with the defined segments.
    const url = `${groupUrl}${getBlocks ? '?get_blocks=True' : ''}`;
    const { data } = await axios.get(url);
    dispatch(getGroupByIdSuccessAction(data, true));
  } catch (error) {
    dispatch({ type: HANDLE_SERVER_ERROR, payload: { error } });
  }
};

const fetchGroupOptionsSuccess = payload => ({
  type: GROUP_OPTIONS_FETCH_SUCCESS,
  payload
});

const fetchGroupOptionsError = error => ({
  type: GROUP_OPTIONS_FETCH_ERROR,
  payload: error
});

const fetchGroupOptions = (id = null) => {
  const url = getGroupUrl(id);
  const request = axios.options(url);
  return dispatch => request.then(
    payload => {
      dispatch(fetchGroupOptionsSuccess(payload));
      return payload;
    },
    error => {
      dispatch(fetchGroupOptionsError(error.response));
      return Promise.reject(error);
    }
  );
};

export const getGroupById = (id, setLoading = true, reloadTray = false) => async (dispatch, getState) => {
  if (setLoading) {
    dispatch(setGroupEditLoading());
  }
  const optionsDispatch = fetchGroupOptions(id);
  return await dispatch(optionsDispatch).then(
    async optionsPayload => {
      try {
        const { data } = await axios.get(getGroupUrl(id));
        let { action } = getAction(optionsPayload.data);
        const template = getGroupDetailsConfig(data.type_name).form;
        if (template) {
          action = computeMetadataDefaults(
            mergeActionWithTemplate(action, template),
            getState().dataTypes
          );
        }
        const newData = { ...data, ...decodeDetails(data, action) };
        dispatch(getGroupByIdSuccessAction(newData, false));
        const segments = generateGroupBounds(newData);
        if (reloadTray) {
          // If we are realoading the group's data for display on the tray, dispatch
          // the action to update that information on the tray list.
          dispatch({ type: MAP_UPDATE_GROUP_TRAY, group: data });
        } else {
          // If we are not updating the group's data on the tray, call fitBounds
          // to zoom to all the area covered by the entity segments.
          dispatch(fitBounds(segments));
        }
        return Promise.resolve(newData);
      } catch (error) {
        dispatch({ type: HANDLE_SERVER_ERROR, payload: { error } });
        return Promise.reject(error);
      }
    },
    () => {}
  );
};

export const createNewGroup = groupTypeName => async (dispatch, getState) => {
  const optionsDispatch = fetchGroupOptions();
  return await dispatch(optionsDispatch).then(
    async optionsPayload => {
      const template = getGroupDetailsConfig(groupTypeName).form;
      const action = computeMetadataDefaults(
        mergeActionWithTemplate(
          getAction(optionsPayload.data).action, template
        ),
        getState().dataTypes
      );
      const groupTypes = getGroupTypesConfig();
      const { id } = groupTypes.find(type => type.name === groupTypeName);
      const { group_status: groupStatuses } = getState().dataTypes;
      const groupStatus = Object.values(groupStatuses).find(status => status.name === 'Open');

      const { groups: { edit } } = getState();
      const shape = R.path(['auto_area', 'shape'], edit.group);
      const global = getFiltersGlobal();
      const newData = {
        type: id,
        auto_area: {
          start_date: global.end_date__gte,
          end_date: global.start_date__lte,
          shape
        },
        ...decodeDetails({ status: groupStatus.id }, action)
      };
      dispatch(getGroupByIdSuccessAction(newData, false));
      return Promise.resolve(newData);
    },
    () => {}
  );
};

const getEntityOverlaps = async entities => {
  const entityType = Object.keys(entities)[0];
  const id = entities[entityType][0];
  const { data } = await axios.get(overlapUrl(id));
  const entityData = [data.entity, ...data.conflicts];
  return { entities: entityData };
};

export const createGroupWithEntities = (entities, includeOverlaps) => async dispatch => {
  dispatch(setGroupEditLoading());
  try {
    if (includeOverlaps) {
      // If include overlaps is set, we are always adding a single entity
      const overlappingEntities = await getEntityOverlaps(entities);
      dispatch(groupMembersFetchSuccessAction(overlappingEntities));
    } else {
      const promises = [];
      if (!isEmpty(entities.entities)) {
        for (const type of Object.keys(entities.entities)) {  // eslint-disable-line max-depth
          if (!isEmpty(entities.entities[type])) {  // eslint-disable-line max-depth
            promises.push({
              [type]: await axios.get(`${entityUrl}?type=${type}&pk__in=${entities.entities[type].join(',')}`)
            });
          }
        }
      }
      Promise.all(promises).then(values => {
        const results = { entities: [] };
        values.forEach(value => {
          const key = Object.keys(value)[0];
          results.entities = [...results.entities, ...value[key].data.results];
        });
        dispatch(groupMembersFetchSuccessAction(results));
      });
    }
  } catch (error) {
    dispatch({ type: HANDLE_SERVER_ERROR, payload: { error } });
  }
};

const changeGroupFieldValueAction = (key, value) => ({ type: CHANGE_GROUP_FIELD_VALUE, key, value });

export const changeGroupFieldValue = (key, value) => dispatch => {
  dispatch(setModified(true));
  dispatch(changeGroupFieldValueAction(key, value));
};

const groupDialogChangeItem = (itemType, itemId, itemGroups, extra, callback) => ({
  type: GROUP_DIALOG_SET_ADD_ITEM,
  payload: {
    addDataType: itemType,
    addDataId: itemId,
    addDataGroups: itemGroups,
    addDataExtra: extra,
    addCallback: callback
  }
});

export const openGroupAddEntityDialog = (type, entity, callback) => async dispatch => {
  dispatch(groupDialogChangeItem(type, entity.id, entity.group_ids, null, callback));
  dispatch(openDashboardDialogAction(dialog.ADD_TO_GROUP));
};

export const openGroupExcludeOverlapDialog = (detailsDataType, entityType, entityId, groups) => async dispatch => {
  dispatch(groupDialogChangeItem(entityType, entityId, groups, detailsDataType, null));
  dispatch(openDashboardDialogAction(dialog.ADD_TO_GROUP_EXCLUDE_OVERLAP));
};

// Get the number of entities to add:
const getEntitiesCount = payload => {
  // We only need to count the first group, all groups repeats
  // the same entity entries:
  const firstGroup = payload.groups[Object.keys(payload.groups)[0]];
  const counts = firstGroup.length;
  if (payload.includeOverlaps) {
    // We don't know the number of entities including overlap will add
    // but add 1, to flag the total number is not 1.
    return counts + 1;
  }
  return counts;
};

const getEntityLabel = (type, entitiesCount, payload) => {
  // If type is defined, return the type name (using pluralize).
  if (type !== null) {
    const label = getEntityTypeLabel(type);
    return pluralize(entitiesCount, capitalize(label));
  }
  // Else, if it's a single entity, find the entity name:
  if (!payload.includeOverlaps) {
    const labels = { entities: 0 };
    const firstGroup = payload.groups[Object.keys(payload.groups)[0]];
    Object.keys(firstGroup).forEach(entityKey => {
      if (firstGroup[entityKey].length > 0) {
        labels[entityKey] = 1;
      }
    });
    const totalCount = labels.entities;
    if (totalCount === 1) {
      let label = Object.keys(labels).find(key => labels[key] === 1);
      if (label === 'entities') {
        label = 'record';
      }
      label = label.replace(/s+$/, '');  // Remove trailing 's' from label.
      label = getEntityTypeLabel(label);
      return pluralize(entitiesCount, capitalize(label));
    }
  }
  // If there are many entities, use the word 'Records'.
  return pluralize(entitiesCount, 'Record');
};

const buildSnackbarMessage = (type, groupType, payload) => {
  const entitiesCount = getEntitiesCount(payload);
  const entityLabel = getEntityLabel(type, entitiesCount, payload);
  const verb = entitiesCount > 1 ? 'were' : 'was';
  const groupsCount = Object.keys(payload.groups).length;
  if (groupsCount === 1) {
    const groupId = Object.keys(payload.groups)[0];
    return {
      action: 'GO TO GROUP',
      autoHideDuration: 15000,
      message: `${entityLabel} ${verb} successfully added to group`,
      onActionTouchTap: () => {
        location.href = `/group/${groupType}/${groupId}`;
      }
    };
  }
  return {
    autoHideDuration: 15000,
    message: `${entityLabel} ${verb} successfully added to groups`
  };
};

// eslint-disable-next-line max-params
export const addToGroups = (groupType, payload, type, openTray, detailsDataType = null, reloadGroup = false, callback) => async dispatch => {
  try {
    await axios.post(`${getGroupUrl()}add_entities/`, payload);
    dispatch(pushApplicationMessage(buildSnackbarMessage(type, groupType, payload)));
    dispatch(closeDashboardDialog(dialog.ADD_TO_GROUP));
    // If we added records to a single group, while we are on the main map,
    // open it on the tray:
    if (openTray) {
      const groupsCount = Object.keys(payload.groups).length;
      if (groupsCount === 1) {
        const groupId = Object.keys(payload.groups)[0];
        // Retrieve group and open it on the tray:
        axios.get(getGroupUrl(groupId)).then(({ data }) => {
          dispatch(openTrayAction(GROUP_TRAY, data.name, { groupId: data.id, group: data }));
        });
      }
    }
    // Reload the data details page:
    if (detailsDataType && location) {
      const { pathname } = location;
      const parts = pathname.split('/');
      if (parts.length > 2) {
        const entityId = parts[2];
        dispatch(fetchDataDetail(detailsDataType, entityId));
        dispatch(push(pathname));
      }
    }
    if (reloadGroup) {
      const firstGroupId = Object.keys(payload.groups)[0];
      dispatch(getGroupById(firstGroupId, false));
    }
    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch({ type: HANDLE_SERVER_ERROR, payload: { error } });
  }
};

const fetchGroupEntityOverlapsSuccess = payload => ({
  type: GROUPS_ENTITY_OVERLAPS_FETCH_SUCCESS,
  payload
});

export const fetchGroupEntityOverlaps = id => {
  const url = overlapUrl(id);
  const request = axios.get(url);
  return (dispatch, getState) => request.then(
    data => {
      const state = getState();
      const { agency_type, map_type } = state.dataTypes;
      data.data.conflicts = optimizeEntitiesForList(data.data.conflicts, { agency_type, map_type });
      dispatch(fetchGroupEntityOverlapsSuccess(data));
      return Promise.resolve(data);
    },
    error => {
      dispatch(getServerErrorAction('group', error));
      return Promise.reject(error);
    }
  );
};

export const resolveConflict = payload => {
  const { entityId } = payload;
  const url = `${BASE_API_URL}/conflict/${entityId}/resolve/`;
  const params = {
    group_id: payload.groupId,
    notes: payload.notes,
    overlap_id: payload.overlapId,
    both_sides: payload.bothSides,
    reason: payload.reason ? payload.reason.join(', ') : ''
  };
  return dispatch => dispatch(setResolutionLoading(true)).then(() => {
    const request = axios.post(url, params);
    request.then(
      data => {  // eslint-disable-line no-unused-vars
        dispatch(getGroupById(payload.groupId, false)).then(() => {
          // Reload the data:
          dispatch(fetchGroupEntityOverlaps(entityId)).then(() => {
            dispatch(setLogReload()).then(() => {
              dispatch(setResolutionLoading(false)).then(() => {
                dispatch(pushDashboardMessage('Conflict resolved.'));
              });
            });
          });
        });
      }
    );
  });
};

export const revokeConflict = payload => {
  const { entityId } = payload;
  const url = `${BASE_API_URL}/conflict/${entityId}/revoke/`;
  const params = {
    group_id: payload.groupId,
    overlap_id: payload.overlapId
  };
  return dispatch => dispatch(setResolutionLoading(true)).then(() => {
    const request = axios.post(url, params);
    request.then(
      data => {  // eslint-disable-line no-unused-vars
        dispatch(getGroupById(payload.groupId, false)).then(() => {
          // Reload the data:
          dispatch(fetchGroupEntityOverlaps(entityId)).then(() => {
            dispatch(setLogReload()).then(() => {
              dispatch(setResolutionLoading(false)).then(() => {
                dispatch(pushDashboardMessage('Resolution revoked.'));
              });
            });
          });
        });
      }
    );
  });
};

const getGroupsSavingAction = () => ({ type: GROUPS_DETAIL_SAVING });

const getGroupsSavingSuccessAction = payload => ({ type: GROUPS_DETAIL_SAVING_SUCCESS, payload });

const getGroupSavingFailAction = payload => ({ type: GROUPS_DETAIL_SAVING_FAIL, payload });

export const updateGroup = (group, groupType, source) => async dispatch => {
  try {
    const method = group.id ? 'patch' : 'post';
    dispatch(getGroupsSavingAction());
    const error = validateGroupData(group, groupType);
    if (error) {
      dispatch(getGroupSavingFailAction(error));
      return;
    }
    const response = await axios[method](getGroupUrl(group.id), { ...group });
    dispatch(getGroupsSavingSuccessAction(response));
    dispatch(pushApplicationMessage(`Group successfully ${group.id ? 'updated' : 'created'}`));
    dispatch(closeDashboardDialog(dialog.DATE_RANGE));
    dispatch(discardChangesAction());
    if (source) {
      // If we came from the map, return to the map:
      if (source.startsWith('/map') && response.data && response.data.id) {
        // First reload the group list, so it includes the new group (only if it's a new group):
        if (!group.id) {  // eslint-disable-line max-depth
          dispatch(fetchGroupsData());
        }
        // We probably landed on the edit page through a shape tool selection,
        // thus turn it off so it doesn't appear when we return to the main map:
        dispatch(setDrawingProps({ shape: null, mode: '' }));
        // Redirect to main map page, specifying that the saved group must be highlighted:
        dispatch(push(`/map?group=${response.data.id}`));
      } else {
        dispatch(push(source));
      }
    } else {
      // If there's no source defined return to the group listing page by default.
      // (this might happen because we either landed on the edit page by an email link,
      // or we manually entered the URL, thus there's no previous navigation).
      dispatch(push(isNewLayout() ? `/library/${GROUP_DATA_TYPE}/${groupType}` : `/list/data/group/${groupType}`));
    }
  } catch (error) {
    if (error.response.status === 400) {
      // For the 400 error, process server errors about empty
      // or invalid fields:
      dispatch(getGroupSavingFailAction(error.response.data));
    } else {
      dispatch({ type: HANDLE_SERVER_ERROR, payload: { error } });
      dispatch(getGroupSavingFailAction({}));
    }
  }
};

const newGanttFilterValue = (type, filter) => ({
  type: GROUPS_SET_GANTT_FILTER,
  payload: filter,
  dataType: type
});

export const setGanttFilter = (type, filter) => dispatch => dispatch(newGanttFilterValue(type, filter));

export const toggleLocationTypeGroup = locationId => dispatch => dispatch({
  type: GROUPS_TOGGLE_GANTT_LOCATION_TYPE,
  payload: locationId
});

const setBaselineUrl = id => `${BASE_API_URL}/group/${id}/setbaseline/`;
const updateBaselineUrl = id => `${BASE_API_URL}/group/${id}/updatebaseline/`;
const dismissBaselineAlertUrl = id => `${BASE_API_URL}/group/${id}/dismiss_baseline_alert/`;

export const setBaseline = payload => {
  const { groupId } = payload;
  const url = setBaselineUrl(groupId);
  const request = axios.post(url);

  return (dispatch, getState) =>
    request.then(
      data => {  // eslint-disable-line no-unused-vars
        getGroupById(payload.groupId, false)(dispatch, getState);
      }
    );
};

export const updateBaseline = payload => {
  const { groupId } = payload;
  const url = updateBaselineUrl(groupId);
  const request = axios.post(url);

  return (dispatch, getState) =>
    request.then(
      data => {  // eslint-disable-line no-unused-vars
        getGroupById(payload.groupId, false)(dispatch, getState);
      }
    );
};

export const dismissBaselineAlert = groupId => {
  const url = dismissBaselineAlertUrl(groupId);
  const request = axios.post(url);

  return dispatch =>
    request.then(
      data => {  // eslint-disable-line no-unused-vars
        getGroupById(groupId, false)(dispatch);
      }
    );
};

const dismissGanttDialogAction = (dialogType, groupId) => ({
  type: GROUPS_DISMISS_GANTT_DIALOG,
  payload: {
    dialogType,
    value: groupId
  }
});

export const dismissGanttDialog = (dialogType, groupId) => dispatch => dispatch(dismissGanttDialogAction(dialogType, groupId));

export const setGroupsLayerVisible = visible => dispatch => dispatch({ type: GROUPS_SET_LAYER_VISIBLE, visible });

export const setGroupsLayerBoundariesVisible = visible => dispatch => dispatch({ type: GROUPS_SET_LAYER_BOUNDARIES_VISIBLE, visible });

export const toggleGroupLayerBoundaries = () => dispatch => dispatch({ type: GROUPS_TOGGLE_BOUNDARIES });

export const toggleGroupLayerLabels = () => dispatch => dispatch({ type: GROUPS_TOGGLE_LABELS });

export const clearGroupPreFill = () => dispatch => dispatch({ type: GROUPS_CLEAR_PRE_FILL });

export const setGroupPreFill = group => dispatch => dispatch({ type: GROUPS_SET_PRE_FILL, group });
