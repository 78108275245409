import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  updatePolygon,
  updateSegmentEndpoints
} from '@actions/data-detail-actions';
import FloatingBar from '@components/map/floating-bar';
import OldFloatingBar from '@components/map/floating-bar-old';
import { isNewLayout } from '@constants/config';
import { convertFromGoogleMapsShape } from '@utils/map-tools-utils';
import EditMap from './edit-map';
import './map.scss';

const Map = ({ formState }) => {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.dataDetail);

  const {
    activeSegmentId,
    activeType,
    activeStart, setActiveStart,
    activeEnd, setActiveEnd,
    geometryLocked
  } = formState;

  const activeSegment = useMemo(
    () => data?.segments?.find(seg => seg.id === activeSegmentId),
    [data?.segments, activeSegmentId]
  );

  const segments = useMemo(() => data?.segments || null, [data?.segments]);

  const onActiveSegmentChanged = useCallback(
    endPoints => {
      const newEndPoints = {
        ...{ start: activeStart, end: activeEnd },
        ...endPoints
      };
      setActiveStart(newEndPoints.start);
      setActiveEnd(newEndPoints.end);
      dispatch(updateSegmentEndpoints(newEndPoints, activeSegmentId, activeSegment.override_street_centering, 'map'));
    },
    [activeEnd, activeStart, activeSegment, activeSegmentId, dispatch, setActiveEnd, setActiveStart]
  );

  const onActivePolygonChanged = useCallback(
    mapsShape => {
      setActiveStart(null);
      setActiveEnd(null);
      const shape = convertFromGoogleMapsShape(mapsShape);
      dispatch(updatePolygon(activeSegmentId, shape, 'map'));
    },
    [activeSegmentId, dispatch, setActiveEnd, setActiveStart]
  );

  return (
    <div styleName="map-container">
      {isNewLayout() && <FloatingBar legend={false} tools={false} />}
      {!isNewLayout() && <OldFloatingBar legend={false} tools={false} />}
      <EditMap
        activeEnd={activeEnd}
        activeSegmentId={activeSegmentId}
        activeStart={activeStart}
        activeType={activeType}
        segments={segments}
        geometryLocked={geometryLocked}
        onActiveSegmentChanged={onActiveSegmentChanged}
        onPolygonChanged={onActivePolygonChanged}
      />
    </div>
  );
};

Map.propTypes = {
  formState: PropTypes.object
};

export default Map;
