/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useContext } from 'react';
import DrawerFilters from '@components/overlap/drawer-filters';
import DrawerList from '@components/overlap/drawer-list';
import DrawerMainCard from '@components/overlap/drawer-main-card';
import OverlapContext from '@components/overlap/overlap-context';
import MapTray from '@components/map/tray';
import { paper } from '@constants/mui-theme';
import AddToGroupExcludeOverlapDialog from '@shared/dialogs/add-to-group-exclude-overlap-dialog';
import OverlapRevokeDialog from '@shared/dialogs/overlap-revoke-dialog';
import OverlapStatusDialog from '@shared/dialogs/overlap-status-dialog';
import { getItemHeight } from '@utils/overlap-utils';
import '../data-detail/data-detail.scss';

const Drawer = () => {
  const { mainEntity, opportunities, setScrolled, setSticky } = useContext(OverlapContext);

  const onScroll = useCallback(event => {
    setScrolled(true);
    setSticky(event.target.scrollTop > getItemHeight(mainEntity));
  }, [mainEntity, setScrolled, setSticky]);

  return (
    <div styleName="details-content" key="drawer" style={paper}>
      <div styleName="details-content-list-body-wrapper" onScroll={onScroll}>
        <div styleName="details-content-list-body">
          <DrawerMainCard />
          <DrawerFilters />
          <DrawerList />
        </div>
      </div>
      <MapTray />
      <AddToGroupExcludeOverlapDialog />
      <OverlapRevokeDialog />
      <OverlapStatusDialog mainEntity={mainEntity} opportunities={opportunities} />
    </div>
  );
};

export default Drawer;
