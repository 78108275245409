/* eslint-disable react/jsx-no-bind */
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import Row from '@components/workflow/section/view-field-row';
import { getWorkflowCustomFieldTypes } from '@constants/config';
import { autocompleteSearchStyles, detailEdit } from '@constants/mui-theme';
import DataTypesSelect from '@forms/data-types-select';
import { useOnChangeHandlers } from '@hooks/workflow-hooks';
import FormattedDatePicker from '@shared/formatted-date-picker';
import InputSearch from '@shared/input-search';
import MomentTimePicker from '@shared/moment-time-picker';
import { simpleToOptions } from '@utils/autocomplete-utils';
import {
  buildInlineEditCommonProps,
  getCustomFieldValue
} from '@utils/workflow-utils';
import './inline-edit-field.scss';

const CustomEditField = ({
  data,
  errors,
  isInline,
  isReadOnly,
  field,
  onBlur,
  onChange,
  sectionId
}) => {
  const { onSelectChange, onDateChange, onChipSelectChange } = useOnChangeHandlers(onChange);

  // Date/Time pickers only call onChange when we finish editing, not while we are typing,
  // thus call onBlur too if it exists when onChange is called.
  const onDateFieldChange = useCallback(
    value => {
      const date = onDateChange(value);
      if (onBlur) {
        onBlur(null, date);
      }
    },
    [onBlur, onDateChange]
  );

  // The same happens with onSelectChange and the chips one:
  const onSelectFieldChange = useCallback(
    (event, index, value) => {
      onSelectChange(event, index, value);
      if (onBlur) {
        onBlur(event, value);
      }
    },
    [onBlur, onSelectChange]
  );

  const onChipSelectFieldChange = useCallback(
    (event, value) => {
      const values = onChipSelectChange(event, value);
      if (onBlur) {
        onBlur(null, values);
      }
    },
    [onBlur, onChipSelectChange]
  );

  const fieldTypes = getWorkflowCustomFieldTypes();
  const fieldType = fieldTypes[field.type];
  const label = field.name + (field.required ? '*' : '');
  const { value } = getCustomFieldValue(data, field.id, sectionId) || {};
  const hasErrors = Array.isArray(errors);
  const inlineStyles = {};
  let commonProps = {
    ...field.config,
    fullWidth: true,
    id: String(field.id),
    name: field.name,
    onChange,
    value
  };

  if (isInline) {
    commonProps = {
      ...commonProps,
      ...buildInlineEditCommonProps(isReadOnly, hasErrors, errors),
      onBlur
    };
  } else {
    commonProps = {
      ...commonProps,
      errorText: Array.isArray(errors) ? errors.join(', ') : null,
      errorStyle: { ...detailEdit.errors },
      floatingLabelText: label,
      floatingLabelStyle: { whiteSpace: 'nowrap' }
    };
  }

  let component = null;

  if (fieldType === 'Short text') {
    component = <TextField {...commonProps} />;
  } else
    if (fieldType === 'Paragraph text') {
      component = <TextField {...commonProps} multiLine rowsMax={10} />;
    } else
      if (fieldType === 'Single-select dropdown' || fieldType === 'Multi-select dropdown') {
        const selectProps = {};
        if (field.source) {
          selectProps.dataName = field.source;
        } else {
          // Only set the pre-defined options, if the field.source is empty:
          selectProps.dataSource = field.options;
        }
        if (fieldType === 'Multi-select dropdown') {
          selectProps.multiple = true;
        }
        if (typeof value === 'string') {
          // Parse database data (just converts "100" to 100 or "[100, 102]" to [100, 102],
          // i.e string to int or string to array).
          commonProps.value = JSON.parse(value);
        }
        component = <DataTypesSelect {...commonProps} {...selectProps} onChange={onSelectFieldChange} />;
      } else
        if (fieldType === 'Date picker') {
          component = <FormattedDatePicker {...commonProps} onChange={onDateFieldChange} />;
        } else
          if (fieldType === 'Time picker') {
            if (isInline) {
              inlineStyles.style = { margin: '0 0.5rem 0 0.5rem' };
            }
            component = <MomentTimePicker {...commonProps} {...inlineStyles} onChange={onDateFieldChange} />;
          } else
            if (fieldType === 'Numeric') {
              component = (
                <TextField
                  onKeyDown={event => {
                    // Only allow a digit [0-9], backspace and delete keys, and the arrows for
                    // moving the cursor.
                    // This is required since "number" input fields doesn't restrict input on
                    // Firefox, and it restricts input on Chrome, but allows the letter "e"
                    // (for scientific notation), and we don't want to allow the "e" either.
                    const isAllowedKey = /^\d|Backspace|ArrowLeft|ArrowRight|Delete$/.test(event.key);
                    if (!isAllowedKey) {
                      event.preventDefault();
                    }
                  }}
                  type="number"
                  {...commonProps}
                />
              );
            } else
              if (fieldType === 'Currency') {
                component = <TextField {...commonProps} />;
              } else
                if (fieldType === 'Chip multi-select' || fieldType === 'Square chip multi-select') {
                  const options = field.source ? null : field.options;
                  if (isInline) {
                    inlineStyles.containerStyles = { margin: '1rem 0.5rem 1rem 0.5rem' };
                  }
                  component = (
                    <InputSearch
                      {...autocompleteSearchStyles.normal}
                      {...field.config}
                      {...inlineStyles}
                      dataType={field.source}
                      disabled={commonProps.disabled}
                      errors={errors}
                      onChange={onChipSelectFieldChange}
                      options={options}
                      label={label}
                      squareChips={fieldType === 'Square chip multi-select'}
                      toOptions={fieldType === 'Chip multi-select' ? null : simpleToOptions}
                      values={value}
                    />
                  );
                }

  if (isInline) {
    const controlWrapper = <div styleName="control-wrapper">{component}</div>;
    const rowStyles = {
      padding: hasErrors ? '0 0 1.25rem 0' : '0 0 0.5rem 0'
    };

    return <Row label={label} value={controlWrapper} labelStyle={{}} style={rowStyles} />;
  }

  return component;
};

CustomEditField.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  field: PropTypes.object,
  isInline: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  sectionId: PropTypes.number
};

export default memo(CustomEditField);
