/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */
import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import * as R from 'ramda';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import { CircularProgress } from '@material-ui/core';
import * as colors from '@constants/colors';
import {
  UPLOAD_RUNNING,
  UPLOAD_FINISHED
} from '@constants/file';
import {
  formatFileSize,
  getAcceptedFileTypes,
  renderFileIcon
} from '@utils/file-utils';
import './dropzone.scss';

const Dropzone = ({
  accept,
  errors,
  files,
  multiple = true,
  onFilesChange,
  onFileReplace,
  status
}) => {
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    onFilesChange(acceptedFiles);
    setIsDraggedOver(false);
  }, [onFilesChange]);

  const onDragEnter = () => setIsDraggedOver(true);

  const onDragLeave = () => setIsDraggedOver(false);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragEnter,
    onDragLeave,
    multiple,
    accept: accept || getAcceptedFileTypes()
  });

  // If there are no files, render the "drop zone":
  if (R.isEmpty(files)) {
    const iconDraggingColor = isDraggedOver ? colors.secondaryColor : colors.dotmapsDisabledText;
    return (
      <div
        styleName={`dropzone-container ${isDraggedOver ? 'drag' : 'dashed'}`}
        {...getRootProps({ className: 'dropzone-container' })}
      >
        <input {...getInputProps()} />
        <div styleName="empty-container">
          <div styleName="empty-icon">
            <CloudUploadOutlinedIcon htmlColor={iconDraggingColor} style={{ fontSize: '3.5rem' }} />
          </div>
          <div styleName="empty-text">
            Drag and drop files here or <span styleName="empty-link">choose files</span>
          </div>
        </div>
      </div>
    );
  }

  // If files were dropped or selected, render the file list and upload status:
  return (
    <div styleName="dropzone-container">
      <div styleName="file-list-container">
        {files.map((file, index) => (
          <div data-testid="dropzone-file-item" styleName="file-item-container" key={index}>
            <div data-testid="dropzone-file-item-icon" styleName="file-item-icon">
              {renderFileIcon(file.type)}
            </div>
            <div data-testid="dropzone-file-item-name" styleName="file-item-name">
              {file.name}
            </div>
            {errors[index] && errors[index] === 'File already exists.' &&
              <button styleName="action-button" onClick={() => onFileReplace(file, index)} tabIndex="0">
                Replace file
              </button>
            }
            <div data-testid="dropzone-file-item-size" styleName="file-item-size">
              {formatFileSize(file.size)}
            </div>
            <div data-testid="dropzone-file-item-status" styleName="file-item-status">
              {/* CircularProgress is used to display that the file is uploading, we can't use the version
                  which tracks progress, since axios progress reporting and React doesn't smoothly render the
                  progress, it just gets stuck at some value until it finishes. */}
              {!errors[index] && status[index] === UPLOAD_RUNNING && <CircularProgress size="1.5rem" thickness={5} />}
              {!errors[index] && status[index] === UPLOAD_FINISHED && <CheckCircleIcon htmlColor="#0EB050" style={{ fontSize: '1.5rem' }} />}
              {errors[index] && (
                <Tooltip title={errors[index]} placement="bottom">
                  <ErrorIcon htmlColor={colors.dotmapsError} />
                </Tooltip>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

Dropzone.propTypes = {
  /**
   * The accept attribute defines which file types can be uploaded. This is a
   * comma separated list of unique file type specifiers as a string.
   * (e.g. .jpg, .png, .csv, .doc, audio/*, video/*, image/*)
   */
  accept: PropTypes.string,
  /**
   * List of upload status error messages.
   */
  errors: PropTypes.array,
  /**
   * List of uploaded file objects.
   */
  files: PropTypes.array,
  /**
   * Multiple is an optional boolean prop that allows the drag and drop or
   * selection of multiple files. Default to true.
   */
  multiple: PropTypes.bool,
  /**
   * Action to replace existing file.
   */
  onFileReplace: PropTypes.func,
  /**
   * Callback called when files are drag and dropped or selected from device.
   */
  onFilesChange: PropTypes.func,
  /**
   * The upload status of each file (i.e. running or finished).
   */
  status: PropTypes.array
};

export default memo(Dropzone);
