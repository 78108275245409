import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { colors, dotmapsRed } from '@constants/colors';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Icon, IconButton } from '@mui';
import './top-bar-navigation.scss';

const useStyles = makeStyles({
  root: {
    color: colors.neutral.white,
    backgroundColor: dotmapsRed,
    '&:hover': {
      backgroundColor: dotmapsRed
    }
  }
});

const Navigation = ({ next, previous, today, selectedDate }) => {
  const classes = useStyles();
  return (
    <div styleName="navigation-container">
      <Button onClick={today} classes={{ root: classes.root }}>Today</Button>
      <IconButton onClick={previous}>
        <Icon color={colors.neutral.white}>
          keyboard_arrow_left
        </Icon>
      </IconButton>
      <IconButton onClick={next}>
        <Icon color={colors.neutral.white}>
          keyboard_arrow_right
        </Icon>
      </IconButton>
      <div>{selectedDate.format('MMMM YYYY')}</div>
    </div>
  );
};

Navigation.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  selectedDate: PropTypes.object,
  today: PropTypes.func
};

export default memo(Navigation);
