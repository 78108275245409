/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import PeopleIcon from '@material-ui/icons/People';
import SearchIcon from '@material-ui/icons/Search';
import * as colors from '@constants/colors';
import { SAFE_ZINDEX } from '@constants/mui-theme';
import EntityTypeIcon from '@icons/entity-type-icon';
import GroupTypeIcon from '@icons/group-type-icon';
import TaskStatusChip from '@shared/workflow/task-status-chip';
import { getConflictStatus } from '@utils/data-table/overlap';
import { getUserAvatar, getNoUserIconAvatar } from '@utils/data-table/user';
import {
  getAgencyIcon,
  getCategoryIcon,
  getStarIcon,
  renderCustomIconAndLabel,
  renderIconAndLabel
} from '@utils/icon-utils';
import { sortBy } from '@utils/shared-utils';
import './autocomplete-utils.scss';

const uniqueKey = ({ arg }) => R.head(R.keys(arg));

export const getUniqueSearchKeys = suggestions => R.reverse(R.uniqBy(uniqueKey, R.reverse(suggestions)));

const assignKey = (filters, { arg }) => ({ ...filters, ...arg });

export const getFiltersFromSuggestions = suggestions => R.reduce(assignKey, {}, suggestions);

const removeKeys = (filters, suggestion) => ({
  ...filters,
  [uniqueKey(suggestion)]: ''
});

export const getDeletedFilters = suggestions => R.reduce(removeKeys, {}, suggestions);

// Customized TextField component for use with Autocomplete.
const CustomTextField = withStyles({
  root: {
    // Input box text styling:
    '& input': {
      color: colors.dotmapsBlack80
    },
    // Floating label styling (placeholder):
    '& label': {
      color: colors.dotmapsBlack60,
      width: '16rem'
    },
    '& label.Mui-disabled': {
      color: colors.dotmapsBlack40
    },
    '& label.Mui-error': {
      color: colors.dotmapsError
    },
    // Disable icon flipping (rotation), since
    // we are using the SearchIcon.
    '& button.MuiAutocomplete-popupIndicatorOpen': {
      transform: 'none'
    },
    // Reduce the SVG icon size:
    '& button .MuiSvgIcon-root': {
      width: '20px'
    }
  }
})(TextField);

// Customize the Autocomplete component to fix the zIndex problem
// (the popup of the new Material UI library appears below other elements
// of the screen, when it's mixed with components from the old Material UI library).
export const CustomAutocomplete = withStyles({
  popper: SAFE_ZINDEX
})(Autocomplete);

// Returns a function to render the Autocomplete Input box.
export const renderInput = (errors, label, placeHolder, variant, textReadOnly) => params => {
  const inputParams = {...params.InputProps};
  if (textReadOnly && params.disabled) {
    inputParams.disableUnderline = true;
    inputParams.endAdornment = null;
  }
  return (
    <CustomTextField
      {...params}
      error={Array.isArray(errors)}
      helperText={Array.isArray(errors) ? errors.join(', ') : null}
      fullWidth
      variant={variant}
      size="small"
      label={label}
      inputProps={{ ...params.inputProps, placeholder: placeHolder }}
      InputProps={{ ...inputParams }}
    />
  );
};

const getTypeIcon = option => {
  const { type } = option;
  if (type === 'User') {
    return <PeopleIcon />;
  }
  if (type === 'Group') {
    return <GroupTypeIcon type={option.extra.type_name} />;
  }
  return <EntityTypeIcon type={type} />;
};

export const getChipProps = (props, option) => {
  if (props.avatarProps) {
    const { avatarProps: { type, value } } = props;
    if (type === 'star' && value === option.value) {
      return { avatar: <Avatar><img src={getStarIcon()} alt="" /></Avatar> };
    }
    if (type === 'entity') {
      return { avatar: <div style={{ display: 'flex', alignItems: 'center' }}>{getTypeIcon(option)}</div> };
    }
    if (type === 'user') {
      const { title } = option;
      const initial = title ? title.substring(0, 1) : '';
      return { avatar: <Avatar>{initial}</Avatar> };
    }
  }
  return {};
};

getChipProps.propTypes = {
  avatarProps: PropTypes.object
};

const getChipLabel = (props, option) => {
  // If we should display an avatar, check the option type,
  // since if it's not a supported icon type, we must
  // embed the type in the Chip's label.
  if (props.avatarProps) {
    const { avatarProps: { type } } = props;
    if (type === 'search' && option.type_label) {
      return (
        <div styleName="chip-label">
          {option.type_label}: {option.title}
        </div>
      );
    }
  }
  return (
    <div styleName="chip-label">
      {option.title}
    </div>
  );
};

getChipLabel.propTypes = {
  avatarProps: PropTypes.object
};

export const SquareChip = withStyles({
  root: {
    borderRadius: '4px'
  },
  disabled: {
    opacity: '1 !important'
  }
})(Chip);

// Function to render the Chips within the Autocomplete component.
export const renderTags = props => (tagValue, getTagProps) => {
  if (props.squareChips) {
    return tagValue.map((option, index) => {
      const tagProps = {...getTagProps({ index })};
      if (props.disabled) {
        tagProps.onDelete = null;
      }
      return (
        <SquareChip
          key={index}
          label={getChipLabel(props, option)}
          size={props.chipSize || 'small'}
          deleteIcon={props.deleteIcon ? props.deleteIcon : null}
          {...tagProps}
          {...getChipProps(props, option)}
        />
      );
    });
  }
  return tagValue.map((option, index) => (
    <Chip
      key={index}
      label={getChipLabel(props, option)}
      size={props.chipSize || 'small'}
      {...getTagProps({ index })}
      {...getChipProps(props, option)}
    />
  ));
};


// Autocomplete's getOptionLabel function, we build the label based on the option title:
const getOptionLabel = option => {
  if (Array.isArray(option)) {
    if (option.length > 0) {
      return option[0].title;
    }
    return '';
  }
  return option.title;
};

// Autocomplete's getOptionSelected function, it defines how to know if the option is selected:
const getOptionSelected = (option, value) => option.value === value.value;

// Default properties for our Autocomplete component used for Search.
export const defaultAutoCompleteProps = {
  filterSelectedOptions: true,
  fullWidth: true,
  getOptionLabel,
  getOptionSelected,
  multiple: true,
  popupIcon: <SearchIcon />
};

// Function to convert dotmaps data type values from the Redux store
// into options to use with the Autocomplete component, for data
// that contains an 'active' or 'is_active' field.
export const activeToOptions = (entries, filterFunc) => {
  if (!entries) {
    return [];
  }
  const options = [];
  Object.keys(entries).forEach(entry => {
    // Add entry only if it's active.
    // And if there's filtering for this form field, apply it to know if
    // we must add this entry.
    if ((
      entries[entry].is_active || entries[entry].active
    ) && (
      !filterFunc || filterFunc(entries[entry])
    )
    ) {
      const email = entries[entry].email ? ` (${entries[entry].email})` : '';
      options.push({
        title: `${entries[entry].name}${email}`,
        value: entries[entry].id
      });
    }
  });
  return sortBy(options, 'title');
};

// Simpler toOptions method (see activeToOptions):
export const simpleToOptions = entries => {
  if (!entries) {
    return [];
  }
  const options = [];
  Object.keys(entries).forEach(entry => {
    options.push({
      title: entries[entry].name,
      value: entries[entry].id
    });
  });
  return sortBy(options, 'title');
};

// Similar to activeToOptions() but specific for entities
// (it has less code and adds the entity type).
export const activeEntitiesToOptions = entries => {
  const options = [];
  Object.keys(entries).forEach(entry => {
    if (entries[entry].active) {
      options.push({
        title: entries[entry].name,
        type: entries[entry].type_name,
        value: entries[entry].id
      });
    }
  });
  return sortBy(options, 'title');
};

export const renderSearchGroup = params => (
  <div key={params.key}>
    <div styleName="search-group">
      {params.group}
    </div>
    <div styleName="search-list-wrapper">
      {params.children}
    </div>
  </div>
);

const renderUserIcon = (user, size) => {
  if (!user) {
    return getNoUserIconAvatar(size, colors.dotmapsBlack40);
  }
  return getUserAvatar(user, size);
};

const renderUser = (user, size) => (
  <div styleName="user-card">
    <div styleName="avatar">
      {renderUserIcon(user, size)}
    </div>
    {user && (
      <div styleName="content">
        <div styleName="name">{user.highlight || user.name}</div>
        <div styleName="email">{user.email}</div>
      </div>
    )}
    {!user && (
      <div styleName="content">
        <div styleName="name">Unassigned</div>
      </div>
    )}
  </div>
);

export const renderMentionsUser = user => {
  return (
    <div styleName="user-mentions-card">
      <div styleName="avatar">
        {getUserAvatar(user)}
      </div>
      <div styleName="name-agency-wrapper">
        <div styleName="name">
          {user.highlight || user.name}
        </div>
        <div styleName="agency-name">
          {user.agency_name}
        </div>
      </div>
    </div>
  );
};

export const renderUserListItem = (option, users, size) => {
  const user = users[option.value];
  return renderUser(user, size);
};

// Like renderUserListItem() but for entities.
export const renderEntityListItem = (option, entities) => {
  const entity = entities.find(item => item.id === option.value);
  return (
    <div styleName="entity-card">
      <div styleName="header">
        <div styleName="icon">{<EntityTypeIcon type={entity.type_name} />}</div>
        <div styleName="id">ID {entity.id}</div>
        {entity.agency_name && <div styleName="bull">&bull;</div>}
        {entity.agency_name && <div styleName="title">{entity.agency_name}</div>}
      </div>
      <div styleName="content">
        {entity.name}
      </div>
    </div>
  );
};

// Like renderUserListItem() but for roles.
export const renderRoleListItem = (option, roles) => {
  const role = roles[option.value];
  return <SquareChip label={role.name} />;
};

const renderTitle = (dataType, option, agencyTypes, taskStatuses) => {
  if (option.type === 'ConflictStatus') {
    return getConflictStatus(option.title);
  }
  if (option.type === 'Role') {
    return <SquareChip label={option.title} />;
  }
  if (option.type === 'TaskStatus') {
    const taskStatus = taskStatuses[option.id];
    return <TaskStatusChip taskStatus={taskStatus} />;
  }
  if (option.icon) {
    const { id, icon_name, type } = option.icon;
    if (type === 'Agency') {
      const icon = getAgencyIcon(agencyTypes[id]);
      return renderIconAndLabel(option.title, icon, id);
    }
    if (type === 'Category' && icon_name) {
      const icon = getCategoryIcon(dataType, id);
      return renderIconAndLabel(option.title, icon, id);
    }
    if (type === 'Group') {
      const icon = <GroupTypeIcon type={icon_name} />;
      return renderCustomIconAndLabel(option.title, icon, id);
    }
    if (type === 'Entity') {
      const icon = <EntityTypeIcon type={icon_name} />;
      return renderCustomIconAndLabel(option.title, icon, id);
    }
    if (type === 'User') {
      const user = {
        is_active: true,
        name: option.extra
      };
      const icon = getUserAvatar(user);
      return renderCustomIconAndLabel(option.title, icon, id);
    }
  }
  return option.title;
};

export const renderSearchListItem = (dataType, option, agencyTypes, taskStatuses) => (
  <div styleName="search-list-item">
    <div styleName="title">
      {renderTitle(dataType, option, agencyTypes, taskStatuses)}
    </div>
  </div>
);

// Takes the data table filters and returns a query string with
// the filters that can be used for the Autocomplete API.
export const buildSearchFilters = filters => {
  if (filters) {
    // Remove filter parameters that we cannot use for the Autocomplete API:
    const cleanFilters = R.omit(['limit', 'offset', 'ordering', 'search'], filters);
    const filterPairs = R.toPairs(cleanFilters);
    const searchFilters = filterPairs.map(pair => encodeURI(pair.join('='))).join('&');
    return `&${searchFilters}`;
  }
  return '';
};
