import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setDrawingMode } from '@actions/map-actions';
import { textColor, secondaryColor } from '@constants/colors';
import DrawLineIcon from '@icons/draw-line-old-icon';
import DrawCircleIcon from '@icons/draw-circle-old-icon';
import MeasurementIcon from '@icons/measurement-icon';
import { getToolbarConfig } from '@selectors/map-selector';
import MapToolbarIconButton from '@shared/map-toolbar-icon-button';
import { shallowEqual } from '@utils/react-utils';
import './tools.scss';

class Tools extends Component {
  constructor(props) {
    super(props);
    this.setMeasurement = this.setDrawingMode.bind(this, 'measurement');
    this.setPolygon = this.setDrawingMode.bind(this, 'polygon');
    this.setCircle = this.setDrawingMode.bind(this, 'circle');
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState) {
    return !shallowEqual(nextProps.mode, this.props.mode);
  }

  setDrawingMode = mode => {
    this.props.setDrawingMode(this.props.mode === mode ? '' : mode);
  };

  render() {
    const { mode, toolsConfig } = this.props;
    const { measurement = true, polygon = true, circle = true} = toolsConfig;
    return (
      <div data-testid="map-tools-old" styleName="map-tools">
        {measurement && (
          <MapToolbarIconButton
            onClick={this.setMeasurement}
            iconInactive={mode !== 'measurement'}
            tooltip="Measure distance"
          >
            <MeasurementIcon
              color={mode === 'measurement' ? secondaryColor : textColor}
              style={{...(mode !== 'measurement' && { opacity: 0.5 })}}
            />
          </MapToolbarIconButton>
        )}
        {polygon && (
          <MapToolbarIconButton
            onClick={this.setPolygon}
            iconInactive={mode !== 'polygon'}
            tooltip="Select an area"
          >
            <DrawLineIcon
              color={mode === 'polygon' ? secondaryColor : textColor}
              style={{...(mode !== 'polygon' && { opacity: 0.5 })}}
            />
          </MapToolbarIconButton>
        )}
        {circle && (
          <MapToolbarIconButton
            onClick={this.setCircle}
            iconInactive={mode !== 'circle'}
            tooltip="Select an area"
          >
            <DrawCircleIcon
              color={mode === 'circle' ? secondaryColor : textColor}
              style={{...(mode !== 'circle' && { opacity: 0.5 })}}
            />
          </MapToolbarIconButton>
        )}
      </div>
    );
  }
}

Tools.propTypes = {
  mode: PropTypes.string,
  openDashboardDialog: PropTypes.func,
  setDrawingMode: PropTypes.func,
  toolsConfig: PropTypes.object
};

const mapStateToProps = state => {
  const { drawing } = state.map;
  const toolsConfig = getToolbarConfig(state);
  return { ...drawing, toolsConfig };
};

export default connect(mapStateToProps, { setDrawingMode })(Tools);
