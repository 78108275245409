import React, { Fragment, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import Header from '@components/map/tray/header';
import ListHeader from '@components/shared/ui-library/list/list-header';
import EntityCard from '@components/shared/list-card/entity-card';
import GroupCard from '@components/shared/list-card/group-card';
import CityLayerCard from '@components/shared/list-card/city-layer-card';

import { isNewLayout } from '@constants/config';

import { SELECT_MODE_DETAILS } from '@shared/ui-library/card/entity-data-card';
import { entityCount } from '@utils/markers-utils';
import { canEditGroups } from '@utils/permission-utils';

import { openAddToGroupTray } from '@actions/map-actions';

import { getOverlapTrayItemsWithEntityDetails } from '../selectors';
import { getConfig } from '@components/config/selectors';

import styles from './overlap-tray.scss';
import '../tray.scss';

const OverlapTray = () => {
  const dispatch = useDispatch();
  const {entity_types: entityTypes} = useSelector(getConfig);
  const layerTypes = Object.values(useSelector(state => state.layers));
  const trayItems = useSelector(getOverlapTrayItemsWithEntityDetails);
  const [hasEntities, hasGroups, hasLayers] = useMemo(() => {
    return [
      Object.values(trayItems.entities).some(items => items.length > 0),
      trayItems.groups.length > 0,
      Object.values(trayItems.layers).some(items => items.length > 0)
    ];
  }, [trayItems]);
  const {entities = {}, layers = {}, groups = []} = trayItems;

  const handleAddToGroup = useCallback(() => {
    dispatch(openAddToGroupTray({
      'add-to-group': {}
    }));
  }, [dispatch]);

  const length = entityCount({entities, layers, groups});
  const title = (
    <div styleName="map-tray-heading-title">
      <div styleName="label">Identify results</div>
      <div styleName="counter">{length}</div>
    </div>
  );
  return (
    <div styleName="map-tray-body-wrapper">
      <Header title={title} />
      <Divider />
      <div styleName={isNewLayout() ? 'map-tray-body' : 'map-tray-body map-tray-body-old'}>
        { (hasEntities || hasGroups || hasLayers) &&
          <ul styleName="list">
            {hasEntities && entityTypes.map(({id, name, label}) => {
              if (entities[name] && entities[name].length > 0) {
                return (
                  <Fragment key={id}>
                    <ListHeader
                      text={label}
                      count={entities[name].length}
                      className={styles.listHeader}
                    />
                    {entities[name].map(entity => (
                      <EntityCard
                        key={entity.id}
                        entity={entity}
                        selectMode={SELECT_MODE_DETAILS}
                        trayHover
                      />
                    ))}
                  </Fragment>
                );
              }
              return null;
            })}
            { hasGroups &&
              <Fragment>
                <ListHeader
                  text="Groups"
                  count={groups.length}
                  className={styles.listHeader}
                />
                {groups.map(group => (
                  <GroupCard
                    key={group.id}
                    group={group}
                    selectMode={SELECT_MODE_DETAILS}
                    trayHover
                  />
                ))}
              </Fragment>
            }
            {hasLayers &&
              <Fragment>
                <ListHeader
                  text="City Layers"
                  count={Object.values(layers).reduce((acc, items) => acc + items.length, 0)}
                  className={styles.listHeader}
                />
                {layerTypes.map(({name}) => {
                  if (layers[name] && layers[name].length > 0) {
                    return layers[name].map(layerItem => (
                      <CityLayerCard
                        key={`${name}:${layerItem.id}`}
                        layerItem={layerItem}
                        trayHover
                        type={name}
                      />
                    ));
                  }
                  return null;
                })}
              </Fragment>
            }
          </ul>
        }
        { !(hasEntities || hasGroups || hasLayers) &&
          <div styleName="tray-empty-list">
            No records found within the selection.
          </div>
        }
      </div>
      {canEditGroups() && (
        <div styleName="map-tray-bottom-action">
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddToGroup}
            color="primary"
            fullWidth
          >
            ADD TO GROUP
          </Button>
        </div>
      )}
    </div>
  );
};

export default memo(OverlapTray);
