import React from 'react';
import PropTypes from 'prop-types';
import LayerControls from './layer-controls';
import TransportationLayers from './transportation-layers';
import LegendIcon from './legend-icon';
import MarkersIcon from './markers-icon';
import Tools from './tools';
import Separator from './separator';
import './floating-bar.scss';

const FloatingBar = ({ legend, tools }) => (
  <div data-testid="map-floating-bar-old" styleName="map-floating-bar-container">
    <LayerControls />
    <Separator />
    <MarkersIcon />
    <Separator />
    <TransportationLayers />
    {tools && <Separator />}
    {tools && <Tools />}
    {legend && <Separator />}
    {legend && <LegendIcon />}
  </div>
);

FloatingBar.propTypes = {
  legend: PropTypes.bool,
  tools: PropTypes.bool
};

FloatingBar.defaultProps = {
  legend: true,
  tools: true
};

export default FloatingBar;
