/* eslint-disable react/jsx-no-bind */
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { fetchCalendar, updatedSelectedFilters } from '@actions/calendar-actions';
import Content from '@components/calendar-old/content';
import FilterSidebar from '@components/calendar-old/filter-sidebar';
import TopBar from '@components/calendar-old/top-bar';
import { dotmapsRed } from '@constants/colors';
import { getCalendarEventTypeItems } from '@selectors/calendar-selector';
import DotmapsLoader from '@shared/dotmaps-loader';
import { getCalendarEventTypeKey, getFilters } from '@utils/calendar-utils';
import './calendar.scss';

const Calendar = () => {
  const dispatch = useDispatch();
  const { filters: { selectedDate, selectedView }, loading } = useSelector(state => state.calendar);
  const dataType = getCalendarEventTypeKey();
  const eventTypeKeys = Object.keys(useSelector(state => getCalendarEventTypeItems(dataType)(state)));
  const selectedDateMoment = moment(selectedDate);

  useEffect(() => {
    const filters = getFilters(selectedDateMoment, selectedView);
    dispatch(fetchCalendar(filters));
  }, [dispatch, selectedDate, selectedView]);  // eslint-disable-line react-hooks/exhaustive-deps

  const setView = view => dispatch(updatedSelectedFilters('selectedView', view));
  const setDate = date => dispatch(updatedSelectedFilters('selectedDate', date));

  const viewDay = () => setView('day');
  const viewWeek = () => setView('week');
  const viewMonth = () => setView('month');

  const navNext = () => setDate(selectedDateMoment.add(1, selectedView));
  const navPrevious = () => setDate(selectedDateMoment.subtract(1, selectedView));
  const navToday = () => setDate(moment());

  const resetAllFilters = () => {
    dispatch(updatedSelectedFilters(dataType, eventTypeKeys));
    setView('month');
    setDate(moment());
  };

  return (
    <div styleName="calendar-container">
      <TopBar
        viewDay={viewDay}
        viewWeek={viewWeek}
        viewMonth={viewMonth}
        navNext={navNext}
        navPrevious={navPrevious}
        navToday={navToday}
        selectedDate={selectedDateMoment}
        selectedView={selectedView}
        resetAllFilters={resetAllFilters}
      />
      <div styleName="calendar-content">
        <FilterSidebar />
        <div styleName="content">
          <DotmapsLoader display={loading} color={dotmapsRed} />
          <Content
            selectedDate={selectedDateMoment.toDate()}
            selectedView={selectedView}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(Calendar);
