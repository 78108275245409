import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setMapTypeId } from '@actions/map-actions';
import { textColor, secondaryColor } from '@constants/colors';
import MapToolbarIconButton from '@shared/map-toolbar-icon-button';
import { shallowEqual } from '@utils/react-utils';
import './layer-controls.scss';

class LayerControls extends Component {
  constructor(props) {
    super(props);

    this.setRoadmap = () => this.props.setMapTypeId('roadmap');
    this.setHybrid = () => this.props.setMapTypeId('hybrid');
    this.setTerrain = () => this.props.setMapTypeId('terrain');
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState) {
    return !shallowEqual(nextProps.mapTypeId, this.props.mapTypeId);
  }

  render() {
    const { mapTypeId } = this.props;
    return (
      <div data-testid="map-layer-controls-old" styleName="map-layer-controls">
        <MapToolbarIconButton
          onClick={this.setRoadmap}
          color={mapTypeId === 'roadmap' ? secondaryColor : textColor}
          icon="map"
          iconInactive={mapTypeId !== 'roadmap'}
          tooltip="Map"
        />
        <MapToolbarIconButton
          onClick={this.setHybrid}
          color={mapTypeId === 'hybrid' ? secondaryColor : textColor}
          icon="satellite"
          iconInactive={mapTypeId !== 'hybrid'}
          tooltip="Satellite"
        />
        <MapToolbarIconButton
          onClick={this.setTerrain}
          color={mapTypeId === 'terrain' ? secondaryColor : textColor}
          icon="terrain"
          iconInactive={mapTypeId !== 'terrain'}
          tooltip="Terrain"
        />
      </div>
    );
  }
}

LayerControls.propTypes = {
  mapTypeId: PropTypes.string,
  setMapTypeId: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { mapTypeId } = state.map;
  return { mapTypeId };
};

export default connect(mapStateToProps, { setMapTypeId })(LayerControls);
