import { BaseSimpleDropdownFilter } from '@shared/top-filter-bar/base-simple-dropdown-filter';

export class BaseDropDownFilter extends BaseSimpleDropdownFilter {
  setFilter(filter) {
    const { name } = this.props;
    this.props.setFilter(name, filter);
  }
}

export const baseMapStateToProps = (state, { lazyItems, name }) => {
  const filter = state.groups.gantt.filters[name];
  // The items are lazy loaded, since it requires the backend config data loaded.
  const items = lazyItems();
  return { filter, items, name };
};
