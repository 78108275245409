/* eslint-disable react/jsx-no-bind */
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { routerWillLeave } from '@actions/confirmation-actions';
import Section from '@components/workflow/section';
import AttachmentsSection from '@components/workflow/section/attachments';
import LinkedEntitiesSection from '@components/workflow/section/linked-entities';
import LocationsSection from '@components/workflow/section/locations';
import { getWorkflowCustomFieldSections } from '@constants/config';
import { getTaskFields, renderEditFields } from '@utils/workflow-utils';
import AddTaskHeader from './add-task-header';
import AddTaskFooter from './add-task-footer';
import './add-task.scss';

const AddTask = ({ assignTempTaskId, taskId }) => {
  const dispatch = useDispatch();
  const { taskTypeId } = useParams();
  const { task_type } = useSelector(state => state.dataTypes);
  const { modified } = useSelector(state => state.dataDetail);
  const { cycle, loading } = useSelector(state => state.workflow);
  const { discard } = useSelector(state => state.confirmation);

  useEffect(() => {
    if (!taskId) {
      assignTempTaskId(`temp-${uuid()}`);
    }
  }, [assignTempTaskId, dispatch, taskId]);

  if (!taskTypeId || loading || !cycle) {
    return null;
  }

  const taskType = task_type[taskTypeId];
  const sections = getWorkflowCustomFieldSections();

  return (
    <div styleName="add-task-container">
      <AddTaskHeader taskType={taskType} taskId={taskId} />
      <div styleName="add-task-sections">
        {sections.map(section => {
          const fields = getTaskFields(section, taskType);
          // Only display a section if it has fields and it's not
          // the "Response" one, since the response fields are edited
          // per activity.
          if (fields.length > 0 && section.name !== 'Response') {
            return (
              <Section key={section.id} name={section.name}>
                {renderEditFields(section.id, fields)}
              </Section>
            );
          }
          return null;
        })}
        <AttachmentsSection edit taskId={taskId} />
        {cycle?.group && <LinkedEntitiesSection edit />}
        {cycle?.entity && <LocationsSection edit />}
      </div>
      <AddTaskFooter />
      <Prompt when={modified && !discard} message={nextLocation => routerWillLeave(dispatch, nextLocation)} />
    </div>
  );
};

AddTask.propTypes = {
  assignTempTaskId: PropTypes.func,
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default memo(AddTask);
