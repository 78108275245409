import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './horizontal-drawer.scss';

const HorizontalDrawer = ({ children }) => {
  const { drawerOpen } = useSelector(state => state.map);
  if (!drawerOpen) {
    return null;
  }

  return (
    <div data-testid="horizontal-drawer" styleName="horizontal-drawer-container">
      {children}
    </div>
  );
};

HorizontalDrawer.propTypes = {
  children: PropTypes.node
};

export default memo(HorizontalDrawer);
