/* eslint-disable react/jsx-no-bind */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import * as R from 'ramda';
import { includes, get } from 'lodash';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { fetchDataDetail } from '@actions/data-detail-actions';
import { openDialog } from '@actions/dialogs-actions';
import {
  CONFLICT_FILTERS,
  DEFAULT_FILTERS
} from '@components/overlap/constants';
import Drawer from '@components/overlap/drawer';
import Map from '@components/overlap/map';
import OverlapContext from '@components/overlap/overlap-context';
import TopBar from '@components/overlap/top-bar';
import { getEntityType } from '@constants/config';
import { nonOverlapingEntitiesGroups } from '@selectors/groups-selector';
import {
  getOverlapsSelector,
  optimizeOverlapConflictsForListSelector,
  optimizeOverlapOpportunitiesForListSelector,
  optimizeEntityForListSelector
} from '@selectors/overlap-selector';
import {
  getEntityStatus,
  FRONTEND_CONFLICT_STATUS
} from '@utils/data-detail/conflicts';
import { getUrlParamEntity } from '@utils/overlap-utils';
import '../data-detail/data-detail.scss';

const OverlapDetail = () => {
  const dispatch = useDispatch();
  const { dataId } = useParams();
  const location = useLocation();
  const [filter, setFilter] = useState(DEFAULT_FILTERS);
  const [highlightGeometry, setHighlightGeometry] = useState(true);
  // When the page is loaded with a focused entity, we must scroll into
  // that entity on the list, something that also happens if we update the component,
  // so this flag avoids that, so we don't scroll into view, if we already scrolled at least once.
  const [scrolled, setScrolled] = useState(false);
  // Sets the sticky flag so the overlap dropdown item in the list becomes sticky while scrolling.
  const [sticky, setSticky] = useState(false);
  const { data, loading } = useSelector(state => state.dataDetail);
  const overlaps = useSelector(getOverlapsSelector);
  const conflicts = useSelector(optimizeOverlapConflictsForListSelector);
  const opportunities = useSelector(optimizeOverlapOpportunitiesForListSelector);
  const mainEntity = useSelector(optimizeEntityForListSelector)[0];
  const { trays } = useSelector(state => state.map);
  const { open: dialogOpen } = useSelector(state => state.dialogs);
  const groups = useSelector(state => nonOverlapingEntitiesGroups(state, { entity: mainEntity }));

  const onMapLoad = useCallback(() => {
    dispatch(fetchDataDetail('overlap', dataId));
  }, [dataId, dispatch]);

  useEffect(() => {
    // The tray was closed, remove 'open' from URL.
    if (trays?.length === 0) {
      const { pathname, search } = location;
      const searchParams = new URLSearchParams(search);
      searchParams.delete('open');
      dispatch(push(`${pathname}?${searchParams.toString()}`));
    }
  }, [dispatch, trays?.length]);  // eslint-disable-line react-hooks/exhaustive-deps

  const allEntities = useMemo(() => [mainEntity, ...overlaps].filter(entity => entity), [mainEntity, overlaps]);

  // Return selected entity (or the main one if none is selected):
  const selectedEntity = useMemo(() => getUrlParamEntity(allEntities, location, 'focus') || mainEntity, [allEntities, location, mainEntity]);

  const selectedSegmentIds = useMemo(() => R.pipe(R.propOr([], 'segments'), R.pluck('id'))(selectedEntity), [selectedEntity]);

  useEffect(() => {
    // Check if the 'focus' entity id exists in the current overlap list, show the warning
    // dialog if it's not on that list.
    if (!dialogOpen && !loading && data) {
      const entity = getUrlParamEntity(allEntities, location, 'focus');
      if (!entity) {
        const searchParams = new URLSearchParams(location.search);
        const paramType = searchParams.get('type');
        if (paramType) {
          const message = `This ${paramType} is no longer part of the overlap.`;
          dispatch(openDialog(paramType, location.pathname, message));
        }
      }
    }
  }, [dispatch, allEntities, data, dialogOpen, loading]);  // eslint-disable-line react-hooks/exhaustive-deps

  const isSelected = useCallback(id => selectedEntity?.id === id, [selectedEntity]);

  const getItemId = useCallback(
    entity => `overlap-list-item-${entity.id}-${isSelected(entity.id) ? 'selected' : ''}`,
    [isSelected]
  );

  useEffect(() => {
    if (selectedEntity && !scrolled) {
      const id = getItemId(selectedEntity);
      const element = document.getElementById(id);
      if (element) {
        scrollIntoView(element, { scrollMode: 'if-needed' });
      }
    }
  }, [getItemId, scrolled, selectedEntity]);

  // Return a unique list of entity types of all entities overlapping the main entity
  // that doesn't have enabled the resolution workflow.
  const nonActionableOverlappingEntityTypes = useMemo(() => {
    // Get a unique list of all entity types in all overlapping entities:
    const entityTypeNames = [...new Set(Object.values(overlaps).map(entity => entity.type_name))];

    // Get entityTypes and remove types unknown to UI.
    let entityTypes = entityTypeNames.map(typeName => getEntityType(typeName)).filter(entity => entity);

    // And also remove the ones that doesn't allows resolution.
    if (getEntityType(mainEntity?.type_name)?.overlaps['allow-resolution']) {
      entityTypes = entityTypes.filter(entityType => !entityType.overlaps['allow-resolution']);
    }
    return entityTypes.map(entityType => entityType.name);
  }, [mainEntity, overlaps]);

  const conflictFilter = useCallback((entity, filterStatus) => {
    if (!mainEntity || !entity) {
      return false;
    }
    if (includes(nonActionableOverlappingEntityTypes, entity.type_name)) {
      return false;
    }
    const { status } = getEntityStatus(mainEntity.overlap_status, entity.overlap_status);
    return entity.overlap_type === 'Conflict' && status === filterStatus;
  }, [mainEntity, nonActionableOverlappingEntityTypes]);

  const getOpenConflicts = useCallback(
    entities => entities.filter(entity => conflictFilter(entity, FRONTEND_CONFLICT_STATUS.resolve)),
    [conflictFilter]
  );

  const getOpportunities = useCallback(
    entities => entities.filter(entity => entity.overlap_type === 'Opportunity'),
    []
  );

  const getPendingConflicts = useCallback(
    entities => entities.filter(entity => conflictFilter(entity, FRONTEND_CONFLICT_STATUS.pending)),
    [conflictFilter]
  );

  const getResolvedConflicts = useCallback(
    entities => entities.filter(entity => conflictFilter(entity, FRONTEND_CONFLICT_STATUS.resolved)),
    [conflictFilter]
  );

  // For each overlapping entity option in the filter dropdown, include all overlapping entities of that type
  // if that entity type is selected to be included in the list.
  const getNonActionableOverlappingEntitiesByType = useCallback(entities => {
    const filtered = [];
    nonActionableOverlappingEntityTypes.forEach(item => {
      if (get(filter, item, true)) {
        filtered.push(entities.filter(entity => entity.type_name === item));
      }
    });
    return [].concat(...filtered);
  }, [filter, nonActionableOverlappingEntityTypes]);

  // Filter entities based on the selected filter.
  const filterEntities = useCallback(entities => entities && [
    ...((get(filter, CONFLICT_FILTERS.OPEN, true) && getOpenConflicts(entities)) || []),
    ...((get(filter, CONFLICT_FILTERS.OPPORTUNITIES, true) && getOpportunities(entities)) || []),
    ...((get(filter, CONFLICT_FILTERS.PENDING, true) && getPendingConflicts(entities)) || []),
    ...((get(filter, CONFLICT_FILTERS.RESOLVED, true) && getResolvedConflicts(entities)) || []),
    ...getNonActionableOverlappingEntitiesByType(entities)
  ], [
    filter,
    getNonActionableOverlappingEntitiesByType,
    getOpenConflicts,
    getOpportunities,
    getPendingConflicts,
    getResolvedConflicts
  ]);

  const filteredEntities = useMemo(
    () => [].concat(mainEntity, filterEntities([].concat(conflicts, opportunities))).filter(entity => entity),
    [conflicts, filterEntities, mainEntity, opportunities]
  );

  const segments = useMemo(() => {
    if (filteredEntities?.length > 0) {
      // Entities may have more than one segment, we must display them all:
      return [].concat(...filteredEntities.map((entity, entityIndex) => entity?.segments.map((segment, index) => ({
        ...segment,
        // Inject the agency type, so we can display the icon.
        agency_type: entity?.agency_type,
        // Inject the entity for the tray.
        entity,
        // Only show the marker and tray for the first segment for each entity:
        showMarker: index === 0,
        mainEntitySegments: mainEntity?.segments,
        overlapType: {
          isLead: entityIndex === 0,
          isOpen: conflictFilter(entity, FRONTEND_CONFLICT_STATUS.resolve),
          isOpportunity: entity.overlap_type === 'Opportunity',
          isPending: conflictFilter(entity, FRONTEND_CONFLICT_STATUS.pending),
          isResolved: conflictFilter(entity, FRONTEND_CONFLICT_STATUS.resolved)
        }
      }))));
    }
    return null;
  }, [conflictFilter, filteredEntities, mainEntity]);

  const buildSelectQuery = useCallback(entity => {
    const { id } = entity;
    const query = { focus: id, type: entity.entity_type };
    if (isSelected(id)) {
      query.open = id;
    }
    return query;
  }, [isSelected]);

  const selectEntity = useCallback(entity => {
    const { pathname, search } = location;
    const searchParams = new URLSearchParams(search);
    const query = new URLSearchParams({
      ...Object.fromEntries(searchParams),
      ...buildSelectQuery(entity)
    });
    dispatch(push(`${pathname}?${query.toString()}`));
  }, [buildSelectQuery, dispatch, location]);

  const contextValue = {
    conflicts,
    data,
    filter,
    filterEntities,
    filteredEntities,
    getItemId,
    getOpenConflicts,
    getOpportunities,
    getPendingConflicts,
    getResolvedConflicts,
    groups,
    isSelected,
    highlightGeometry,
    loading,
    mainEntity,
    nonActionableOverlappingEntityTypes,
    onMapLoad,
    opportunities,
    segments,
    selectEntity,
    selectedSegmentIds,
    setFilter,
    setHighlightGeometry,
    setScrolled,
    setSticky,
    sticky
  };

  return (
    <OverlapContext.Provider value={contextValue}>
      <div styleName="data-details-container">
        <div styleName="data-details-top-bar">
          <TopBar />
        </div>
        <div styleName="data-details-body">
          <Drawer />
          <Map />
        </div>
      </div>
    </OverlapContext.Provider>
  );
};

export default memo(OverlapDetail);
