import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import memoize from 'memoize-one';
import { getEntityTypeLabel } from '@constants/config';
import { getConfig } from '@utils/config-utils';
import { withRouter } from '@utils/router-utils';
import './public-detail-success.scss';

const getStyle = memoize(config => ({
  successBackground: {
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${
      (config && config.publicFormSuccessBackgroundPath) ||
      'https://storage.googleapis.com/coordinate-assets/background/public_form_success.png'
    })`
  }
}));

class PublicDetailSuccess extends Component {
  render() {
    const config = getConfig();
    const styles = getStyle(config);

    const { entityType } = this.props;
    return (
      <div styleName="container">
        <div styleName="inner-container" style={styles.successBackground}>
          <div styleName="info-box">
            <div styleName="title">Submission successful!</div>
            <div styleName="info">
              Your application has submitted successfully and is under review.
              You will be reached out to via email about further progress.
            </div>
            <Link to={`/publicform/${entityType}`}>APPLY FOR ANOTHER {getEntityTypeLabel(entityType)}</Link>
          </div>
        </div>
      </div>
    );
  }
}

PublicDetailSuccess.propTypes = {
  entityType: PropTypes.string
};

const mapStateToProps = (state, props) => {
  const { model } = props.match.params;
  return { entityType: model };
};

export default connect(mapStateToProps)(withRouter(PublicDetailSuccess));
