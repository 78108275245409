/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { colors } from '@constants/colors';
import { getFiltersGlobal } from '@constants/config';
import * as dialog from '@constants/dialogs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Checkbox } from '@mui';
import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';
import FormattedDatePicker from '@shared/formatted-date-picker';
import { generateDateFilters, generateSingleDateFilters } from '@utils/date-utils';
import { NotImplemented } from '@utils/shared-utils';

class BaseDateRangeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  resetFilters = () => {
    const { end_date__gte, start_date__lte } = getFiltersGlobal();
    this.setState({
      start: moment(end_date__gte).toDate(),
      end: moment(start_date__lte).toDate(),
      contain: false
    });
  };

  getDefaultState = () => {
    const { start, end, contain } = this.props;
    return {
      start: start && moment(start).toDate(),
      end: end && moment(end).toDate(),
      contain
    };
  };

  getFilters = () => {
    if (this.isMultiple()) {
      return generateDateFilters(this.state);
    }
    const { fieldName = 'created' } = this.props.dialogParams || {};
    return generateSingleDateFilters(this.state, fieldName);
  };

  // Implement in subclass:
  getSetFiltersFunction = () => NotImplemented();

  handleStartChange = start => {
    const momentDate = start && moment(start).toDate();
    this.setState({ start: momentDate });
  };

  handleEndChange = end => {
    const momentDate = end && moment(end).toDate();
    this.setState({ end: momentDate });
  };

  toggleContain = () => this.setState({ contain: !this.state.contain });

  // Implement in subclass:
  getDataTypeDisplayName = () => null;

  isMultiple = () => true;

  showContain = () => true;

  getId = prefix => `${prefix}_picker`;

  closeDialog = () => this.props.closeDialog(dialog.DATE_RANGE);

  handleCancel = () => {
    this.setState(this.getDefaultState());
    this.closeDialog();
  };

  handleApply = () => {
    this.getSetFiltersFunction()(this.getFilters());
    this.closeDialog();
  };

  getActions = () => (
    <DialogActions
      onCancel={this.handleCancel}
      onSubmit={this.handleApply}
      submitLabel="APPLY"
    />
  );

  render() {
    const { start, end, contain } = this.state;
    const { activeDialogs } = this.props;
    if (!activeDialogs[dialog.DATE_RANGE]) {
      return null;
    }
    return (
      <Dialog
        actions={this.getActions()}
        maxWidth="xs"
        onClose={this.handleCancel}
        title="Custom date range"
      >
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
          <FormattedDatePicker
            clearable
            hintText="From"
            floatingLabelText="From"
            fullWidth
            value={start}
            id={this.getId('dialog_from')}
            onChange={this.handleStartChange}
            maxDate={end || null}
          />
          <FormattedDatePicker
            clearable
            floatingLabelText="To"
            hintText="To"
            fullWidth
            value={end}
            id={this.getId('dialog_to')}
            onChange={this.handleEndChange}
            minDate={start || null}
          />
          {this.showContain() &&
            <div style={{ color: colors.opacity.black0_8, paddingTop: '2rem' }}>
              <Checkbox
                checked={contain}
                label={`Show only fully contained ${this.getDataTypeDisplayName()}s`}
                onChange={this.toggleContain}
              />
            </div>
          }
        </MuiPickersUtilsProvider>
      </Dialog>
    );
  }
}

BaseDateRangeDialog.propTypes = {
  activeDialogs: PropTypes.object,
  closeDialog: PropTypes.func,
  contain: PropTypes.bool,
  dialogParams: PropTypes.object,
  end: PropTypes.string,
  setFilter: PropTypes.func,
  start: PropTypes.string
};

export default BaseDateRangeDialog;
