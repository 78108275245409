import React, { cloneElement, Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import EnhancedButton from 'material-ui/internal/EnhancedButton';

class WaffleItem extends Component {
  render() {
    const styles = {
      label: {
        fontSize: '0.8em',
        padding: '0 1em 1em 1em'
      },
      icon: {
        display: 'block',
        height: 40,
        padding: 10,
        minWidth: 80,
        width: 40
      }
    };

    const {
      label,
      icon,
      onClick,
      style,
      to,
      ...other
    } = this.props;

    const { prepareStyles } = this.context.muiTheme;

    const styledIcon = cloneElement(icon, {
      style: Object.assign({}, styles.icon, icon.props.style),
      color: icon.props.color || styles.iconColor
    });

    return (
      <Link to={to} onClick={onClick}>
        <EnhancedButton {...other} style={Object.assign({}, styles.root, style)}>
          {styledIcon}
          <div style={prepareStyles(styles.label)}>
            {label}
          </div>
        </EnhancedButton>
      </Link>
    );
  }
}

WaffleItem.propTypes = {
  /**
   * Set the icon representing the view for this item.
   */
  icon: PropTypes.node,
  /**
   * Set the label describing the view for this item.
   */
  label: PropTypes.node,
  /**
   * OnClick handler.
   */
  onClick: PropTypes.func,
  /**
   * @ignore
   * Override the inline-styles of the root element.
   */
  style: PropTypes.object,
  /**
   * The link path.
   */
  to: PropTypes.string
};

WaffleItem.contextTypes = {
  muiTheme: PropTypes.object.isRequired
};

export default WaffleItem;
