/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMapDrawer } from '@actions/map-actions';
import { colors, dotmapsDarkGreen, dotmapsGreen } from '@constants/colors';
import {
  getDefaultEntity,
  getEntityType,
  showHorizontalDrawer
} from '@constants/config';
import Avatar from '@material-ui/core/Avatar';
import { Icon, IconButton } from '@mui';
import FlexSeparator from '@shared/helpers/flex-separator';
import Actions from './actions';
import './top-bar.scss';

const TopBar = () => {
  const dispatch = useDispatch();
  const { drawerOpen } = useSelector(state => state.map);

  const onListClick = () => dispatch(toggleMapDrawer());

  const entityType = getEntityType(getDefaultEntity());
  const showDrawer = showHorizontalDrawer();

  return (
    <div data-testid="map-top-bar" styleName="map-top-bar-container">
      <div data-testid="map-top-bar-title" name="title">Map</div>
      <FlexSeparator />
      <Actions />
      {showDrawer && <div styleName="border-separator" />}
      {showDrawer &&
        <IconButton
          onClick={onListClick}
          tooltip={`${entityType.label} List`}
          style={{ padding: 0 }}
        >
          <Avatar style={{
            backgroundColor: drawerOpen ? dotmapsDarkGreen : dotmapsGreen
          }}>
            <Icon color={colors.neutral.white}>list</Icon>
          </Avatar>
        </IconButton>
      }
    </div>
  );
};

export default memo(TopBar);
