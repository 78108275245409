import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import VerticalTab from '@shared/vertical-tab';
import { mapTabsConfig } from '@constants/component-configs';
import { getTrayDepth } from '@selectors/map-selector';
import './tab-bar.scss';

// Don't show a box-shadow when the tray is open.
const getDepth = trayOpen => trayOpen ? 1 : 3;

const TabBar = ({ trayOpen }) => (
  <Paper data-testid="map-tab-bar" styleName="map-tab-bar-container" zDepth={getDepth(trayOpen)}>
    <VerticalTab {...mapTabsConfig.tabs.filters} />
    <VerticalTab {...mapTabsConfig.tabs.search} />
    <VerticalTab {...mapTabsConfig.tabs.details} />
    <VerticalTab {...mapTabsConfig.tabs.recent} />
  </Paper>
);

TabBar.propTypes = {
  trayOpen: PropTypes.bool
};

const mapStateToProps = state => {
  const depth = getTrayDepth(state);
  return { trayOpen: depth > 0 };
};

export default connect(mapStateToProps, { })(TabBar);
