import React from 'react';
import { connect } from 'react-redux';
import { setDatatableFilter } from '@actions/data-table-actions';
import { getFiltersDashboard } from '@constants/config';
import TaskStatusChip from '@shared/workflow/task-status-chip';
import { buildCustomMenuItem } from '@utils/filter-utils';
import { baseMapStateToProps, DashboardBaseDropDownFilter } from './dashboard-base-dropdown-filter';

class FieldFilter extends DashboardBaseDropDownFilter {
  getDisplayName() {
    return this.props.label;
  }
  buildMenuItem(primaryTextIcon, item) {
    const { sourceDataType, taskStatuses } = this.props;
    if (sourceDataType === 'task_status') {
      const checked = this.getChecked(item);
      const taskStatus = taskStatuses[item.id];
      const content = (
        <div style={{ padding: '0.25rem 0 0.25rem'}}>
          <TaskStatusChip taskStatus={taskStatus} />
        </div>
      );
      return buildCustomMenuItem(checked, content, item);
    }
    return super.buildMenuItem(primaryTextIcon, item);
  }
}

const mapStateToProps = (state, props) => {
  const { dataType, items, sourceDataType, sort, subType } = props;
  const params = {
    dataType,
    subType,
    items,
    lazyName: () => {
      let filters = getFiltersDashboard()[dataType];
      const types = ['activity', 'group', 'overlap', 'task'];
      if (types.includes(dataType)) {
        filters = filters[subType];
      }
      return filters.find(filter => filter.name === props.name).params[0];
    },
    sort,
    sourceDataType
  };
  return {
    ...baseMapStateToProps(state, params),
    taskStatuses: state.dataTypes.task_status
  };
};

export default connect(mapStateToProps, { setFilter: setDatatableFilter })(FieldFilter);
