/*eslint-disable max-len*/
import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'material-ui/SvgIcon';

const AdminIcon = props => (
  <SvgIcon {...props} style={{...props.style}} viewBox="0 0 26 26">
    <path d="M21.9375 0H4.0625C1.81884 0 0 1.9401 0 4.33333V21.6667C0 24.0599 1.81884 26 4.0625 26H21.9375C24.1812 26 26 24.0599 26 21.6667V4.33333C26 1.9401 24.1812 0 21.9375 0Z" fill="#F9A825"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M26 15.5L18 7.5L8.5 17.5L18.2143 26H21.9375C24.1812 26 26 24.0599 26 21.6667V15.5Z" fill="#C0821E"/>
    <path d="M17.2 15.896C17.6948 15.896 18.096 15.4949 18.096 15C18.096 14.5052 17.6948 14.104 17.2 14.104C16.7051 14.104 16.304 14.5052 16.304 15C16.304 15.4949 16.7051 15.896 17.2 15.896Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.2 16.6C16.616 16.6 15.448 16.888 15.408 17.464C15.808 18.032 16.464 18.4 17.2 18.4C17.936 18.4 18.592 18.032 18.992 17.464C18.952 16.888 17.784 16.6 17.2 16.6Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M18 11.472V7.616L12 5L6 7.616V11.544C6 15.176 8.56 18.576 12 19.4C12.44 19.296 12.864 19.144 13.28 18.96C14.144 20.192 15.576 21 17.2 21C19.848 21 22 18.848 22 16.2C22 13.824 20.272 11.856 18 11.472ZM17.2 19.4C15.432 19.4 14 17.968 14 16.2C14 14.432 15.432 13 17.2 13C18.968 13 20.4 14.432 20.4 16.2C20.4 17.968 18.968 19.4 17.2 19.4Z" fill="white"/>
  </SvgIcon>
);

AdminIcon.propTypes = {
  style: PropTypes.object
};

export default AdminIcon;
