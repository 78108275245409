/*eslint-disable max-len*/
import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'material-ui/SvgIcon';

const CalendarIcon = props => (
  <SvgIcon {...props} style={{...props.style}} viewBox="0 0 26 26">
    <path fillRule="evenodd" clipRule="evenodd" d="M18.9286 2.51875H7.07139V0H4.24283V2.51875H2.82856C1.25871 2.51875 0.0141428 3.65219 0.0141428 5.0375L0 23.4812C0 24.8666 1.25871 26 2.82856 26H23.1714C24.7271 26 26 24.8666 26 23.4812V5.0375C26 3.65219 24.7271 2.51875 23.1714 2.51875H21.7572V0H18.9286V2.51875Z" fill="#FF5722"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.7666 21.2334L15.2275 26.0001H23.3999C24.8359 26.0001 25.9999 24.836 25.9999 23.4001V11.9014L20.4365 8.66675L4.7666 21.2334Z" fill="#BC4621"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.78076 21.2728V8.66675H20.4367V21.2728H4.78076Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.42089 14.6805H10.1272C10.4636 14.6805 10.7128 14.5958 10.8749 14.4265C11.0369 14.2573 11.118 14.0326 11.118 13.7525C11.118 13.4817 11.0377 13.2709 10.8772 13.1201C10.7166 12.9693 10.4957 12.8939 10.2144 12.8939C9.96059 12.8939 9.74807 12.9639 9.57683 13.1039C9.4056 13.244 9.31998 13.4263 9.31998 13.651H7.99443C7.99443 13.3001 8.08845 12.9854 8.27651 12.7069C8.46456 12.4284 8.72753 12.2106 9.06542 12.0537C9.40331 11.8967 9.77559 11.8182 10.1823 11.8182C10.8886 11.8182 11.4421 11.9883 11.8427 12.3284C12.2432 12.6684 12.4435 13.137 12.4435 13.7341C12.4435 14.0418 12.3503 14.325 12.1637 14.5835C11.9772 14.842 11.7326 15.0405 11.4299 15.179C11.806 15.3145 12.0865 15.5176 12.2715 15.7884C12.4565 16.0592 12.549 16.3793 12.549 16.7486C12.549 17.3457 12.3327 17.8243 11.9 18.1844C11.4673 18.5444 10.8947 18.7245 10.1823 18.7245C9.51568 18.7245 8.97063 18.5475 8.54712 18.1936C8.12362 17.8397 7.91187 17.3719 7.91187 16.7902H9.23742C9.23742 17.0426 9.33145 17.2488 9.5195 17.4088C9.70756 17.5688 9.93918 17.6488 10.2144 17.6488C10.5293 17.6488 10.7763 17.565 10.9551 17.3973C11.134 17.2295 11.2235 17.0072 11.2235 16.7302C11.2235 16.0592 10.8565 15.7238 10.1227 15.7238H9.42089V14.6805ZM16.6816 18.6322H15.3561V13.4894L13.7737 13.9834V12.8985L16.5394 11.9013H16.6816V18.6322Z" fill="#FF5722"/>
  </SvgIcon>
);

CalendarIcon.propTypes = {
  style: PropTypes.object
};

export default CalendarIcon;
