/* eslint-disable react/jsx-no-bind */
import React, { memo, useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { downloadCSVExport } from '@actions/data-table-actions';
import OverlapContext from '@components/overlap/overlap-context';
import TopBarActions from '@components/overlap/top-bar-actions';
import { colors } from '@constants/colors';
import { Icon, IconButton } from '@mui';
import Skeleton from '@material-ui/lab/Skeleton';
import ActionDownloadIconButton from '@shared/action-download-icon-button';
import FlexSeparator from '@shared/helpers/flex-separator';
import { getDashboardBackURL } from '@utils/navigation-utils';
import './top-bar.scss';

const TopBar = () => {
  const { data, mainEntity } = useContext(OverlapContext);
  const dispatch = useDispatch();
  const location = useLocation();

  const onBackClick = useCallback(() => {
    const source = getDashboardBackURL(
      location,
      'overlap',
      '/list/data/overlaps/conflict'
    );
    dispatch(source);
  }, [dispatch, location]);

  const goToActivityLog = useCallback(() => {
    dispatch(push({
      pathname: `/logs/overlap/conflict/${data.id}/`,
      state: {source: `/overlap/${data.id}`}
    }));
  }, [data?.id, dispatch]);

  const onExportClick = useCallback(() => {
    // When exporting overlaps as CSV, supply the entity type parameter,
    // so the backend knows the type of the main overlap, so it can
    // pull the entity type configuration from the database.
    const filters = { type: mainEntity?.entity_type };
    dispatch(downloadCSVExport('overlap', mainEntity?.entity_type, filters, data.id));
  }, [data?.id, dispatch, mainEntity?.entity_type]);

  return (
    <div styleName="top-bar-container" data-testid="overlap-top-bar">
      <IconButton onClick={onBackClick}>
        <Icon color={data ? colors.neutral.white : colors.opacity.white0_5}>arrow_back</Icon>
      </IconButton>
      <div styleName="top-bar-title" data-testid="overlap-top-bar-title">
        {data && <span>Overlaps of {data.name}</span>}
        {!data && <Skeleton variant="text" width="100%" />}
      </div>
      <FlexSeparator />
      <ActionDownloadIconButton disabled={!data} onClick={onExportClick} />
      <TopBarActions data={data} goToActivityLog={goToActivityLog} />
    </div>
  );
};

export default memo(TopBar);
