// Utility functions to store backend permissions.
// I'm using cookies for that (we can store 4k of data per cookie),
// if we need more space, we'll have to switch to local storage.
import { includes, isString } from 'lodash';
import * as R from 'ramda';
import { getEntityType, isWorkflowEnabled } from '@constants/config';
import {
  PERMISSION_AGENCY_ID,
  PERMISSION_GROUPS,
  PERMISSION_TEAMS,
  PERMISSION_GROUP_ADMIN,
  PERMISSION_GROUP_MANAGER,
  PERMISSION_IS_ADMIN_AGENCY,
  PERMISSION_CAN_CREATE_TASKS_AGENCY,
  PERMISSION_IS_SUPERUSER,
  PERMISSION_IS_APP_ADMIN,
  PERMISSION_USER_ATTRS,
  PERMISSION_USER_ID
} from '@constants/permission';
import { getConfigKey } from '@utils/config-utils';
import { FRONTEND_CONFLICT_STATUS } from '@utils/data-detail/conflicts';
import { getCookies } from '@utils/shared-utils';
import { isAssignee } from '@utils/workflow-utils';

// Clear permissions (used by logout).
export const clearPermissions = () => {
  getCookies().remove(PERMISSION_GROUPS);
  getCookies().remove(PERMISSION_TEAMS);
};

// Store permissions.
export const setPermissions = properties => {
  const {
    agency_id,
    groups,
    teams,
    is_app_admin,
    is_admin_agency,
    can_create_tasks_agency,
    superuser,
    user_attrs,
    user_id
  } = properties;
  const path = { path: '/' };

  getCookies().set(PERMISSION_AGENCY_ID, agency_id, path);
  getCookies().set(PERMISSION_USER_ATTRS, user_attrs, path);
  getCookies().set(PERMISSION_USER_ID, user_id, path);
  getCookies().set(PERMISSION_GROUPS, groups, path);
  getCookies().set(PERMISSION_TEAMS, teams, path);
  getCookies().set(PERMISSION_IS_ADMIN_AGENCY, is_admin_agency, path);
  getCookies().set(PERMISSION_CAN_CREATE_TASKS_AGENCY, can_create_tasks_agency, path);
  getCookies().set(PERMISSION_IS_SUPERUSER, superuser, path);
  getCookies().set(PERMISSION_IS_APP_ADMIN, is_app_admin, path);
};

export const setAgencyPermission = agencyId => {
  const path = { path: '/' };
  getCookies().set(PERMISSION_AGENCY_ID, agencyId, path);
};

// Returns the AgencyUser agency id:
export const getAgencyId = () => parseInt(getCookies().get(PERMISSION_AGENCY_ID), 10);

// Return user attributes:
export const getUserAttributes = () => getCookies().get(PERMISSION_USER_ATTRS);

// Returns the current logged in user id:
export const getUserId = () => parseInt(getCookies().get(PERMISSION_USER_ID), 10);

// Return true if the user belongs to an agency which is an admin one:
export const isAdminAgency = () => getCookies().get(PERMISSION_IS_ADMIN_AGENCY) === true;

// Return true if the user belongs to an agency that can create tasks:
export const canCreateTasksAgency = () => getCookies().get(PERMISSION_CAN_CREATE_TASKS_AGENCY) === true;

// Return true if we are an app admin: This is stored as a string in the cookies.
export const isAppAdmin = () => getCookies().get(PERMISSION_IS_APP_ADMIN) === true;

// Return true if the user is a superuser (i.e. a SADA admin).
// This should be reserved only for SADA users, NOT for app admins (admin agency admins),
// because we might want to have UI functionality only available for SADA admins.
export const isSuperuser = () => getCookies().get(PERMISSION_IS_SUPERUSER) === true;

// Verifies if the user is in the specified group.
export const isInGroup = group => {
  const groups = getCookies().get(PERMISSION_GROUPS);
  return includes(groups, group);
};

// Returns true if the user belong to any of the specified groups,
// or if the user is an app admin.
export const allowedFor = groups => {
  // App admins has access to everything.
  if (isAppAdmin()) {
    return true;
  }

  // If the user has any of the specified groups, then allow access.
  return R.any(isInGroup)(groups);
};

// Alias for allowedFor.
// We'll use allowedFor for JSX, and hasGroups for javascript code.
export const hasGroups = allowedFor;

// Like allowedFor, but it also checks that the specified agencyId matches the user one:
export const allowedForAgency = (groups, agencyId) => {
  // This repeated app admin check is required (it's issued again inside the allowedFor() below),
  // since we don't want app admins to be subject to the agency id check.
  if (isAppAdmin()) {
    return true;
  }

  // Call allowedFor() to know if we have access based on the groups, then allow this only if we
  // belong to the specified agency (or grant access if our agency is an admin one).
  return allowedFor(groups) && (agencyId === getAgencyId() || isAdminAgency());
};

// Check if we have read only access to an entity:
export const isReadOnly = type => {
  const permissions = R.propOr(null, type, getConfigKey('permissions'));
  if (isAppAdmin() && !includes(permissions, 'roedit')) {
    return false;
  }
  return (
    // If it doesn't have add/change/delete permissions, it's read-only:
    !includes(permissions, 'add') &&
    !includes(permissions, 'change') &&
    !includes(permissions, 'delete')
  ) || includes(permissions, 'roedit');
};

export const isRoedit = type => {
  const permissions = R.propOr(null, type, getConfigKey('permissions'));
  return includes(permissions, 'roedit');
};

// Helper method for checking access to entities, based on the configuration:
export const canAccessEntity = (agencyId, type, permission) => {
  if (permission === 'add') {
    const entityType = getEntityType(type);
    if (entityType?.config?.attributes?.disableUiAdd) {
      return false;
    }
  }
  const permissions = R.propOr(null, type, getConfigKey('permissions'));
  // For add/change/delete permissions, disable access if 'roedit' is present:
  if (permissions && permission !== 'view' && includes(permissions, 'roedit')) {
    return false;
  }
  if (isAppAdmin()) {
    return true;
  }
  if (type === 'agency' && permission === 'add') {
    // Only app admins can add agencies, which is handled with
    // the code above.
    return false;
  }
  if (permissions) {
    // If the 'agency' permission exists, it means access must be restricted within
    // the user's agency. Only entities that are assigned to agencies will have
    // the 'agency' permission set (i.e. projects).
    const agencyCheck = agencyId && includes(permissions, 'agency') ?
      allowedForAgency([PERMISSION_GROUP_ADMIN, PERMISSION_GROUP_MANAGER], agencyId) : true;
    // If the 'pcco' permission exists, check that the user is a PCCO one in order
    // to grant him access:
    const pccoCheck = includes(permissions, 'pcco') ? isAdminAgency() : true;
    // Check for the requested permission (add, change, delete, etc):
    const permissionCheck = includes(permissions, permission);
    return agencyCheck && pccoCheck && permissionCheck;
  }
  return false;
};

// Like canAccessEntity() but for groups:
export const canAccessGroup = (type, permission) => {
  const permissions = R.propOr(null, type, getConfigKey('permissions'));
  // For add/change/delete permissions, disable access if 'roedit' is present:
  if (permission !== 'view' && includes(permissions, 'roedit')) {
    return false;
  }
  // App admins can do anything (but check this after 'roedit').
  if (isAppAdmin()) {
    return true;
  }
  if (permissions) {
    return includes(permissions, permission) && isAdminAgency();
  }
  return false;
};

// Return true if the user has permissions to edit/remove this group type.
//
// Groups were a PCCO-only functionality in Coordinate, where only PCCO admins role
// users had write access to groups (i.e. create/edit/remove) and only PCCO users
// (regardless of their role) can see the groups listing in the library.
//
// Non-PCCO users were only able to see groups in the main map, by enabling the filter
// (but the groups listing was not available on the library and they had no write access at all).
//
// Now, group permissions are verified by group type. The 'boundary' groups are the ones
// that have the same permissions as the old groups (PCCO admins can edit, PCCO users can
// view them in read-only mode, and non PCCO users can only view them on the map).
//
// For the 'relational' groups, PCCO admins and manages have write access, while all other
// roles and non-PCCO users have read-only access. The library page will always show
// the group entry, although only relational groups will appear there for non PCCO users.
export const canEditGroups = (type = 'boundary') => canAccessGroup(type, 'change');

export const canAddEntities = (agencyId, type) => canAccessEntity(agencyId, type, 'add');
// Entities edit access if defined by backend configuration
// (it's enabled if there's no such configuration).
export const canEditEntities = (agencyId, type) => canAccessEntity(agencyId, type, 'change');

// Check for delete access to an entity type.
export const canDeleteEntities = (agencyId, type) => canAccessEntity(agencyId, type, 'delete');

// Returns true if the user has permissions to create or delete attachments.
//
// Attachments can only be created or deleted by:
//
// 1. App admins.
// 2. PCCO admins and managers.
// 3. non-PCCO admins and managers but only if it's an entity which is listed
// as allowed for modification by non-PCCO users, and in that case, it must
// also belong to the user's agency.
//
// For example, for Seattle, non-PCCO users can add/remove attachments,
// but only for projects (and projects belonging to the user's agency).
// And since only projects are listed as allowed, these non-PCCO users
// can't change attachments for events.
export const canEditAttachments = (agencyId, isEnabled) => {
  // This is almost the same code as allowedForAgency(), but adds the check for 'isEnabled'.
  return (
    isAppAdmin() || ( // App admins can do anything.
      // If not an app admin, the user must be an admin or manager:
      allowedFor([PERMISSION_GROUP_ADMIN, PERMISSION_GROUP_MANAGER]) &&
      // If he's a PCCO admin or manager, then he can edit/delete it:
      (isAdminAgency() ||
        // Else the entity agency must be the same as the user's one
        // and that entity must exist in the attachment modification
        // allowed list for this city.
        (agencyId === getAgencyId() && isEnabled)
      )
    )
  );
};

// Returns true if the user has the specific 'upload' permission.
export const canUploadAttachments = () => {
  const permissions = R.propOr(null, 'attachment', getConfigKey('permissions'));
  return includes(permissions, 'upload');
};

// Returns true if the user is a PCCO user (i.e. a user from and admin agency).
export const isPCCO = () => isAdminAgency() || isAppAdmin();

const overlapAndFileAccess = agencyId => isPCCO() || agencyId === getAgencyId();

// PCCO users can see all overlaps, non-PCCO users can only see overlaps within their agency.
export const canAccessOverlaps = agencyId => overlapAndFileAccess(agencyId);

export const canAccessFiles = agencyId => overlapAndFileAccess(agencyId);

// Check if the specified entity type allow resolve/revoke:
const _allowResolution = typeName => getEntityType(typeName).overlaps['allow-resolution'];

// Same as allowResolution, but checks for two types:
export const allowResolution = (type1, type2) => _allowResolution(type1) && _allowResolution(type2);

// Only managers can resolve/revoke (if the entity belongs to their agency).
const canResolveRevoke = agencyId => isAppAdmin() || allowedForAgency([PERMISSION_GROUP_ADMIN, PERMISSION_GROUP_MANAGER], agencyId);

// Returns an object with resolve/revoke boolean props, telling if we are allowed
// to resolve or revoke.
export const getResolutionPermissions = (entity1, entity2, status) => {
  // First, check if both entity types allows resolve/revoke:
  if (!allowResolution(entity1.type_name, entity2.type_name)) {
    return { resolve: false, revoke: false };
  }
  // Then, we can resolve, if we are admins or managers and we are either users of the admin
  // agency, or users of the agency of the main entity.
  const canResolve = canResolveRevoke(entity2.agency);
  // For revoking is the same as resolve, however if the overlap is cleared
  // (i.e. resolved on both sides), to be able to revoke it, the user must
  // be an app admin).
  // STR - 20220510 - Added a revoke permission. This will alow others than admins to revoke if requested by client.
  const permissions = R.propOr(null, 'overlapentity', getConfigKey('permissions'));
  const revoke_permission = includes(permissions, 'revoke');
  const canRevoke = canResolve && (isAppAdmin() || status !== FRONTEND_CONFLICT_STATUS.resolved || revoke_permission);
  return {
    resolve: canResolve,
    revoke: canRevoke
  };
};

export const isInRole = taskRoles => {
  if (!taskRoles || taskRoles.length === 0) {
    return false;
  }
  const userRoles = getCookies().get(PERMISSION_TEAMS) || [];
  const foundRoles = userRoles.filter(role => taskRoles.includes(role));
  return foundRoles.length > 0;
};

// Check if the user can respond to a task because he's either on
// one of the task's assigned teams or he's the activity assignee.
export const canRespondTaskActivity = (activity, roles) => isAssignee(activity) || isInRole(roles);

export const canAccessWorkflow = (type, permission) => {
  if (isAppAdmin()) {
    return true;
  }
  const permissions = R.propOr(null, type, getConfigKey('permissions'));
  if (permissions) {
    // Check for the requested permission (add, change, delete, etc):
    const permissionCheck = includes(permissions, permission);
    if (permissionCheck && canCreateTasksAgency()) {
      return true;
    }
  }
  return false;
};

// If user can access the entity detail page, with edit or read only access allowed by the type.
export const canViewEntityPage = (agencyId, type) => {
  if (['cycle', 'task', 'activity', 'taskactivity'].includes(type)) {
    if (type === 'activity') {
      return canAccessWorkflow('taskactivity', 'change');
    }
    return canAccessWorkflow(type, 'change');
  }
  return canAccessEntity(agencyId, type, 'change') || isRoedit(type);
};

// Email settings can only be edited by admins and managers.
const canEditEmailSettings = () => isAppAdmin() || allowedFor([PERMISSION_GROUP_ADMIN, PERMISSION_GROUP_MANAGER]);

export const canViewWorkflows = () => (
  isWorkflowEnabled() &&
  (
    isAppAdmin() || (
      isAdminAgency() && allowedFor([PERMISSION_GROUP_ADMIN, PERMISSION_GROUP_MANAGER])
    )
  )
);

const canStartWorkflows = () => isWorkflowEnabled() && canAccessWorkflow('cycle', 'add');

export const canCreateTasks = () => (
  isAppAdmin() || (
    (canCreateTasksAgency() || isAdminAgency()) && allowedFor([PERMISSION_GROUP_ADMIN, PERMISSION_GROUP_MANAGER])
  )
);

const PERMISSIONS = {
  can_access_group: canAccessGroup,
  can_edit_email_settings: canEditEmailSettings,
  can_edit_groups: canEditGroups,
  can_view_workflows: canViewWorkflows,
  can_start_workflows: canStartWorkflows,
  is_app_admin: isAppAdmin,
  is_superuser: isSuperuser,
  can_create_tasks: canCreateTasks
};

export const verifyPermissions = permissions => {
  if (!permissions) {
    return true;
  }
  return permissions.every(permission => {
    if (isString(permission)) {
      return PERMISSIONS[permission]();
    }
    return PERMISSIONS[permission.function](...permission.params);
  });
};

// Filter agencies returning only the agencies that allow SSO login, and
// if there are user attributes defined, the ones that matches the user type.
export const filterAgencies = agencies => {
  const agencyList = Object.values(agencies);
  const userAttrs = getUserAttributes();

  return agencyList.filter(
    agency => agency.active && agency.sso_join &&
              (!userAttrs.user_type || userAttrs.user_type === agency.config.user_type)
  );
};
