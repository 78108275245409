import React from 'react';
import PropTypes from 'prop-types';
import FlexSeparator from '@shared/helpers/flex-separator';
import ActionItems from '@components/calendar-old/top-bar-action-items';
import Navigation from '@components/calendar-old/top-bar-navigation';
import View from '@components/calendar-old/top-bar-view';
import './top-bar.scss';

const TopBar = ({
  navNext, navPrevious, navToday, selectedDate,
  viewDay, viewWeek, viewMonth, selectedView,
  resetAllFilters
}) => (
  <div styleName="calendar-top-bar-container">
    <div name="title" styleName="title">Calendar</div>
    <Navigation
      next={navNext}
      previous={navPrevious}
      today={navToday}
      selectedDate={selectedDate}
    />
    <FlexSeparator />
    <View
      day={viewDay}
      week={viewWeek}
      month={viewMonth}
      selected={selectedView}
    />
    <ActionItems resetAllFilters={resetAllFilters} />
  </div>
);

TopBar.propTypes = {
  navNext: PropTypes.func,
  navPrevious: PropTypes.func,
  navToday: PropTypes.func,
  resetAllFilters: PropTypes.func,
  selectedDate: PropTypes.object,
  selectedView: PropTypes.string,
  viewDay: PropTypes.func,
  viewMonth: PropTypes.func,
  viewWeek: PropTypes.func
};

export default TopBar;
