import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toggleTraffic, toggleBicycle, toggleTransit } from '@actions/map-actions';
import { textColor, secondaryColor } from '@constants/colors';
import { getToolbarConfig } from '@selectors/map-selector';
import MapToolbarIconButton from '@shared/map-toolbar-icon-button';
import { getMapStylers } from '@utils/map-utils';
import { shallowEqual } from '@utils/react-utils';
import './transportation-layers.scss';

class TransportationLayers extends Component {
  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState) {
    return (
      !shallowEqual(nextProps.bicycle, this.props.bicycle) ||
      !shallowEqual(nextProps.poisOn, this.props.poisOn) ||
      !shallowEqual(nextProps.traffic, this.props.traffic) ||
      !shallowEqual(nextProps.transit, this.props.transit)
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.transit !== prevProps.transit) {
      window.mapInstance.setOptions({styles: getMapStylers(this.props.poisOn, this.props.transit)});
    }
  }

  render() {
    const { traffic, transit, bicycle, toolsConfig } = this.props;
    const {
      traffic: trafficConfig = true,
      transit: transitConfig = true,
      bicycle: bicycleConfig = true
    } = toolsConfig;
    return (
      <div styleName="container">
        {trafficConfig && (
          <MapToolbarIconButton
            onClick={this.props.toggleTraffic}
            color={traffic ? secondaryColor : textColor}
            icon="traffic"
            iconInactive={!traffic}
            tooltip="Traffic"
          />
        )}
        {transitConfig && (
          <MapToolbarIconButton
            onClick={this.props.toggleTransit}
            color={transit ? secondaryColor : textColor}
            icon="directions_transit"
            iconInactive={!transit}
            tooltip="Transit"
          />
        )}
        {bicycleConfig && (
          <MapToolbarIconButton
            onClick={this.props.toggleBicycle}
            color={bicycle ? secondaryColor : textColor}
            icon="directions_bike"
            iconInactive={!bicycle}
            tooltip="Bike"
          />
        )}
      </div>
    );
  }
}

TransportationLayers.propTypes = {
  bicycle: PropTypes.bool,
  poisOn: PropTypes.bool,
  toggleBicycle: PropTypes.func,
  toggleTraffic: PropTypes.func,
  toggleTransit: PropTypes.func,
  toolsConfig: PropTypes.object,
  traffic: PropTypes.bool,
  transit: PropTypes.bool
};

const mapStateToProps = state => {
  const { traffic, transit, bicycle } = state.map;
  const { poisOn } = state.map.markers;
  const toolsConfig = getToolbarConfig(state);
  return { traffic, transit, bicycle, poisOn, toolsConfig };
};

export default connect(mapStateToProps, {
  toggleTraffic,
  toggleTransit,
  toggleBicycle
})(TransportationLayers);

export { TransportationLayers as PureTransportationLayers };
