import React from 'react';
import { connect } from 'react-redux';
import { setDatatableFilter } from '@actions/data-table-actions';
import { getFiltersDashboard } from '@constants/config';
import LegendAvatar from '@shared/legend-avatar';
import { getAgencyIcon } from '@utils/icon-utils';
import { baseMapStateToProps, DashboardBaseDropDownFilter } from './dashboard-base-dropdown-filter';

const SOURCE_DATA_TYPE = 'agency_type';

class AgencyTypeFilter extends DashboardBaseDropDownFilter {
  negateFilterIfEmpty = () => true;

  getDisplayName() {
    return 'Agency Type';
  }

  getPrimaryTextIcon(item) {
    return <LegendAvatar src={getAgencyIcon(item)} />;
  }
}

const mapStateToProps = (state, props) => {
  const { dataType, subType } = props;
  const params = {
    dataType,
    subType,
    sourceDataType: SOURCE_DATA_TYPE,
    lazyName: () => getFiltersDashboard()[dataType].find(filter => filter.name === SOURCE_DATA_TYPE).params[0]
  };
  return baseMapStateToProps(state, params);
};

export default connect(mapStateToProps, { setFilter: setDatatableFilter })(AgencyTypeFilter);
