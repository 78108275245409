/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable dot-notation */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import * as colors from '@constants/colors';
import { Toolbar, ToolbarGroup } from 'material-ui/Toolbar';
import {
  getEntitiesListConfig,
  getEntityTypeLabel
} from '@constants/config';
import {
  canAddEntities,
  isReadOnly
} from '@utils/permission-utils';
import { topBarStyles } from '@constants/mui-theme';
import LogoutIcon from '@icons/header/logout-icon';
import SettingsIcon from '@icons/header/settings-icon';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Popover from '@material-ui/core/Popover';
import { Icon, IconButton } from '@mui';
import IconMenu from '@shared/icon-menu';
import IconMenuItem from '@shared/icon-menu-item';
import NotificationsList from '@shared/header/notifications-list';
import { getUserAvatar } from '@utils/data-table/user';
import { openHelpPage } from '@utils/shared-utils';
import { withRouter } from '@utils/router-utils';
import EntityTypeIcon from '@icons/entity-type-icon';
import styles from './action-items.scss';

const CustomBadge = withStyles({
  root: {
    color: topBarStyles.icons.color,
    '& .MuiBadge-badge': {
      backgroundColor: colors.dotmapsBadgeRed
    }
  }
})(Badge);

const ActionItems = ({ notificationsCount, user }) => {
  const dispatch = useDispatch();

  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const [userOpen, setUserOpen] = useState(false);
  const [userAnchorEl, setUserAnchorEl] = useState(null);

  const openNotifications = event => {
    setNotificationsOpen(true);
    setNotificationsAnchorEl(event.currentTarget);
  };

  const closeNotifications = () => setNotificationsOpen(false);

  const openUser = event => {
    setUserOpen(true);
    setUserAnchorEl(event.currentTarget);
  };

  const closeUser = () => setUserOpen(false);

  const goTo = pathname => {
    const source = window.location.pathname;
    closeUser();
    dispatch(push({ pathname, state: { source } }));
  };

  const goToSettings = () => goTo('/settings/profile');

  const goToLogout = () => goTo('/logout');

  const goToAdd = dataType => goTo(`/${dataType}/new`);

  const entities = [];
  getEntitiesListConfig().forEach(entity => {
    if (canAddEntities(null, entity, 'add') && !isReadOnly(entity)) {
      entities.push(entity);
    }
  });

  if (!user) {
    return null;
  }

  const userAvatarIcon = getUserAvatar(user, 'large');
  return (
    <Toolbar style={topBarStyles.toolbar}>
      <ToolbarGroup>
        <IconButton onClick={openNotifications}>
          <CustomBadge
            badgeContent={notificationsCount}
            color="secondary"
            max={9}
          >
            <NotificationsIcon />
          </CustomBadge>
        </IconButton>
        <Popover
          open={notificationsOpen}
          anchorEl={notificationsAnchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={closeNotifications}
          classes={{paper: styles['notifications-list-container']}}
        >
          <NotificationsList visible={notificationsOpen} />
        </Popover>
        <IconButton onClick={openHelpPage}>
          <Icon color={topBarStyles.icons.color}>help</Icon>
        </IconButton>
        {entities.length > 0 &&
          <IconMenu
            icon={<Icon color={colors.overlayTextColor}>add</Icon>}
            iconButtonStyles={topBarStyles.addIcon}
            MenuProps={{
              anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
              transformOrigin: { horizontal: 'center', vertical: 'top' }
            }}
            tooltip="Add"
          >
            {entities.map(entity => (
              <IconMenuItem
                key={entity}
                icon={<EntityTypeIcon type={entity} size="1.5rem" />}
                onClick={() => goToAdd(entity)}
              >
                New {getEntityTypeLabel(entity)}
              </IconMenuItem>
            ))}
          </IconMenu>
        }
        <div style={{ padding: '1rem 0 1rem 1rem' }}>
          {getUserAvatar(user, 'medium', openUser)}
        </div>
        <Popover
          open={userOpen}
          anchorEl={userAnchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={closeUser}
        >
          <div className={styles['action-items-container']}>
            <div className={styles['user-card']}>
              <div className={styles.avatar}>{userAvatarIcon}</div>
              <div className={styles.content}>
                <div className={styles.name}>{user.name}</div>
                <div className={styles.email}>{user.email}</div>
                <div>{user.permission}</div>
              </div>
            </div>
            <div className={styles.divider} />
            <div className={styles.list} >
              <div className={styles['list-item']}>
                <div className={styles.icon}>
                  <SettingsIcon />
                </div>
                <div className={styles['link']} onClick={goToSettings} role="presentation">Settings</div>
              </div>
              <div className={styles['list-item']}>
                <div className={styles.icon}>
                  <LogoutIcon />
                </div>
                <div className={styles['link']} onClick={goToLogout} role="presentation">Logout</div>
              </div>
            </div>
          </div>
        </Popover>
      </ToolbarGroup>
    </Toolbar>
  );
};


ActionItems.propTypes = {
  notificationsCount: PropTypes.number,
  push: PropTypes.func,
  user: PropTypes.object
};

const mapStateToProps = state => {
  const { config: { user } } = state;
  const { unread } = state.notifications;
  return { notificationsCount: unread, user };
};

export default connect(mapStateToProps, {})(withRouter(ActionItems));
