import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { dotmapsGreen } from '@constants/colors';
import DotmapsLoader from '@shared/dotmaps-loader';
import { NotImplemented } from '@utils/shared-utils';
import './data-detail.scss';

// Base class for data details
// (it's not intended to be used directly, it's a base abstract class).
class DataDetail extends PureComponent {
  componentDidMount() {
    const { dataId } = this.props.params;
    if (dataId) {
      this.loadData(dataId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { error } = nextProps;
    const { dataId } = nextProps.params;
    if (dataId && !error && (
      this.props.params.dataId !== dataId ||
       // If there's no data and we are not waiting for a data fetch,
       // trigger loading the data (this happens when we reach this
       // component with react-router and it's reused, not mounted).
       (!nextProps.loading && !nextProps.data))) {
      this.loadData(dataId);
    }
  }

  // Loads the data for this data type.
  loadData = dataId => {
    const dataType = this.getDataType();
    this.props.fetchDataDetail(dataType, dataId);
  };

  // Return the data type for the data-detail implementation.
  getDataType = () => NotImplemented();

  // Related to data type, but this returns a name to show on the UI.
  getDataTypeDisplayName = () => NotImplemented();

  // Returns the drawer element (for render).
  getDrawer = () => null;

  // Returns the map element (for render).
  getMap = () => NotImplemented();

  // Save function:
  saveData = () => null;

  getSegments = () => this.props.data?.segments || null;

  render() {
    const { data } = this.props;
    return (
      <div styleName="data-details-container">
        {<DotmapsLoader display={!data} color={dotmapsGreen} />}
        <div styleName="data-details-top-bar">
          {data && this.getTopBar()}
        </div>
        <div styleName="data-details-body">
          {this.getDrawer()}
          {this.getMap()}
        </div>
      </div>
    );
  }
}

DataDetail.propTypes = {
  data: PropTypes.object,
  entityType: PropTypes.string,
  error: PropTypes.object,
  fetchDataDetail: PropTypes.func,
  groups: PropTypes.object,
  /**
   * Tells whether the data detail is still loading or not.
   */
  loading: PropTypes.bool,
  location: PropTypes.object,
  maxOwners: PropTypes.number,
  modified: PropTypes.bool,
  options: PropTypes.object,
  /**
   * The URL parameters set by React-Router.
   */
  params: PropTypes.object,
  push: PropTypes.func,
  saving: PropTypes.bool,
  trays: PropTypes.array
};

export const mapStateToProps = state => {
  const { data, error, loading, options, saving, modified } = state.dataDetail;
  const { discard } = state.confirmation;
  return {
    data,
    error,
    loading,
    options,
    saving,
    modified,
    discard
  };
};

export default DataDetail;
