import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'material-ui/SvgIcon';

const DrawLineIcon = props => (
  <SvgIcon {...props} style={{...props.style, height: '21px', width: '22px'}} viewBox="0 -2 23 23">
    <path
      d={`
          M18.2222222,3.77777778 C18.2222222,1.69136872 16.5308535,0 14.4444444,0 C12.3580354,0
          10.6666667,1.69136872 10.6666667,3.77777778 C10.6666667,5.86418683 12.3580354,7.55555556
          14.4444444,7.55555556 C16.5308535,7.55555556 18.2222222,5.86418683 18.2222222,3.77777778 Z
          M14.4444444,5.33333333 C13.5853348,5.33333333 12.8888889,4.63688739 12.8888889,3.77777778
          C12.8888889,2.91866817 13.5853348,2.22222222 14.4444444,2.22222222 C15.3035541,2.22222222
          16,2.91866817 16,3.77777778 C16,4.63688739 15.3035541,5.33333333 14.4444444,5.33333333 Z
          M3.77777778,14.7777778 C1.69136872,14.7777778 0,13.0864091 0,11 C0,8.91359095 1.69136872,7.22222222
          3.77777778,7.22222222 C5.86418683,7.22222222 7.55555556,8.91359095 7.55555556,11 C7.55555556,13.0864091
          5.86418683,14.7777778 3.77777778,14.7777778 Z M3.77777778,12.5555556 C2.91866817,12.5555556
          2.22222222,11.8591096 2.22222222,11 C2.22222222,10.1408904 2.91866817,9.44444444 3.77777778,9.44444444
          C4.63688739,9.44444444 5.33333333,10.1408904 5.33333333,11 C5.33333333,11.8591096 4.63688739,12.5555556
          3.77777778,12.5555556 Z M14.4444444,21 C12.3580354,21 10.6666667,19.3086313 10.6666667,17.2222222
          C10.6666667,15.1358132 12.3580354,13.4444444 14.4444444,13.4444444 C16.5308535,13.4444444
          18.2222222,15.1358132 18.2222222,17.2222222 C18.2222222,19.3086313 16.5308535,21 14.4444444,21 Z
          M14.4444444,18.7777778 C13.5853348,18.7777778 12.8888889,18.0813318 12.8888889,17.2222222
          C12.8888889,16.3631126 13.5853348,15.6666667 14.4444444,15.6666667 C15.3035541,15.6666667 16,16.3631126
          16,17.2222222 C16,18.0813318 15.3035541,18.7777778 14.4444444,18.7777778 Z M10.8912354,5.09338379
          L11.4333496,6.0423584 L12.2340088,6.85583496 L6.86085632,10.6595484 L5.73028953,8.74641212
          L10.8912354,5.09338379 Z M5.62933942,13.4949462 L6.81336891,11.6144288 L12.8133689,15.3922066
          L11.6293394,17.272724 L5.62933942,13.4949462 Z M17.7695544,16.450553 C17.275261,16.8142011
          16.5797619,16.7082922 16.2161137,16.2139988 C15.8524655,15.7197054 15.9583744,15.0242063
          16.4526678,14.6605582 L20.2295776,11.8819123 C20.723871,11.5182641 21.4193701,11.624173
          21.7830182,12.1184664 C22.1466664,12.6127599 22.0407575,13.3082589 21.5464641,13.6719071
          L17.7695544,16.450553 Z
         `}
    />
  </SvgIcon>
);

DrawLineIcon.propTypes = {
  style: PropTypes.object
};

export default DrawLineIcon;
