/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { ListItem } from 'material-ui/List';
import { Checkbox } from '@mui';
import './filter-item.scss';

// A base class for dialogs that shows a list of checkboxes.
const FilterItem = ({ checked, id, item, onCheck }) => {
  const color = item.attrs ? item.attrs.color : null;
  return (
    <ListItem
      styleName="items"
      primaryText={
        <div styleName="filter-item">
          {color && <div styleName="color-icon" style={{ backgroundColor: color }} /> }
          {item.name}
        </div>
      }
      leftCheckbox={
        <Checkbox
          checked={checked}
          onChange={() => onCheck(id)}
          style={{ padding: 0 }}
        />
      }
    />
  );
};

FilterItem.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string,
  item: PropTypes.object,
  onCheck: PropTypes.func
};

export default FilterItem;
