import React, { Fragment, memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import ListHeader from '@components/shared/ui-library/list/list-header';
import { isNewLayout } from '@constants/config';

import Empty from '../empty';
import GoogleItem from './google-item';
import GroupItem from './group-item';
import RecordItem from './record-item';
import ShowMore from './show-more';

import styles from './map-search-results.scss';
import '../../tray.scss';

const MAX_RECORD_RESULTS = 7;
const MAX_GROUPS_RESULTS = 7;
const MAX_GOOGLE_RESULTS = 5;

const MapSearchResults = ({results}) => {
  const [showMoreRecords, setShowMoreRecords] = useState(false);
  const [showMoreGroups, setShowMoreGroups] = useState(false);
  const [showMoreGoogle, setShowMoreGoogle] = useState(false);

  const recordResults = useMemo(
    () => results?.backend?.filter?.(result => result.type !== 'Group') || [],
    [results]
  );
  const groupResults = useMemo(
    () => results?.backend?.filter?.(result => result.type === 'Group') || [],
    [results]
  );
  const googleResults = useMemo(
    () => results?.google || [],
    [results]
  );

  const hasRecords = recordResults.length > 0;
  const hasGroups = groupResults.length > 0;
  const hasGoogle = googleResults.length > 0;
  const hasMoreRecords = recordResults.length > MAX_RECORD_RESULTS;
  const hasMoreGroups = groupResults.length > MAX_GROUPS_RESULTS;
  const hasMoreGoogle = googleResults.length > MAX_GOOGLE_RESULTS;

  const displayRecords = useMemo(() => {
    if (!showMoreRecords) {
      return recordResults.slice(0, MAX_RECORD_RESULTS);
    }
    return recordResults;
  }, [recordResults, showMoreRecords]);
  const displayGroups = useMemo(() => {
    if (!showMoreGroups) {
      return groupResults.slice(0, MAX_GROUPS_RESULTS);
    }
    return groupResults;
  }, [groupResults, showMoreGroups]);
  const displayGoogle = useMemo(() => {
    if (!showMoreGoogle) {
      return googleResults.slice(0, MAX_GOOGLE_RESULTS);
    }
    return googleResults;
  }, [googleResults, showMoreGoogle]);

  const handleToggleShowMoreRecords = useCallback(() => {
    setShowMoreRecords(!showMoreRecords);
  }, [showMoreRecords, setShowMoreRecords]);
  const handleToggleShowMoreGroups = useCallback(() => {
    setShowMoreGroups(!showMoreGroups);
  }, [showMoreGroups, setShowMoreGroups]);
  const handleToggleShowMoreGoogle = useCallback(() => {
    setShowMoreGoogle(!showMoreGoogle);
  }, [showMoreGoogle, setShowMoreGoogle]);
  return (
    <div styleName={isNewLayout() ? 'map-tray-body' : 'map-tray-body map-tray-body-old'}>
      <ul className={styles.results}>
        <ListHeader className={styles.resultHeader} text="Entities" />
        { hasRecords ?
          <Fragment>
            {displayRecords.map((result, index) => (
              <RecordItem key={index} result={result} />
            ))}
            {hasMoreRecords && (
              <ShowMore onClick={handleToggleShowMoreRecords} open={showMoreRecords} />
            )}
          </Fragment> :
          <div className={styles.emptyResult}>No results found</div>
        }
        <ListHeader className={styles.resultHeader} text="Groups" />
        { hasGroups ?
          <Fragment>
            {displayGroups.map((result, index) => (
              <GroupItem key={index} result={result} />
            ))}
            {hasMoreGroups && (
              <ShowMore onClick={handleToggleShowMoreGroups} open={showMoreGroups} />
            )}
          </Fragment> :
          <div className={styles.emptyResult}>No results found</div>
        }
        <ListHeader className={styles.resultHeader} text="Go to a location" />
        { hasGoogle ?
          <Fragment>
            {displayGoogle.map((prediction, index) => (
              <GoogleItem key={index} prediction={prediction} />
            ))}
            {hasMoreGoogle && (
              <ShowMore onClick={handleToggleShowMoreGoogle} open={showMoreGoogle} />
            )}
          </Fragment> :
          <div className={styles.emptyResult}>No results found</div>
        }
        {!hasRecords && !hasGroups && !hasGoogle &&
          <Empty />
        }
      </ul>
    </div>
  );
};

MapSearchResults.propTypes = {
  results: PropTypes.object
};

export default memo(MapSearchResults);
