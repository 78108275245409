import React, { memo } from 'react';
import PropTypes from 'prop-types';
import DataTable from '@shared/data-table';

const DashboardDataTable = ({ match: { params: { baseType, dataType } } }) => {
  const baseDataType = baseType === 'overlaps' ? 'overlap' : baseType;

  if (baseType) {
    return <DataTable dataType={baseDataType} subType={dataType} />;
  }

  return <DataTable dataType={dataType} />;
};

DashboardDataTable.propTypes = {
  match: PropTypes.object
};

export default memo(DashboardDataTable);
