import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { errorDataField, updateDataFieldInline } from '@actions/data-detail-actions';
import { updateActivity } from '@actions/workflow-actions';
import CustomEditField from '@components/workflow/section/custom-edit-field';
import {
  getCustomFieldValue,
  getCustomFieldValueIndex,
  isResponderReadOnly
} from '@utils/workflow-utils';

const InlineEditField = ({ data, error, field, fieldPrefix, section }) => {
  const { loading } = useSelector(state => state.dataDetail);

  const dispatch = useDispatch();

  const fieldPath = useMemo(
    () => {
      let index = getCustomFieldValueIndex(data, field.id, section);
      if (index === -1) {
        index = field.order - 1;
      }
      return fieldPrefix ? `${fieldPrefix}.field_values.${index}` : `field_values.${index}`;
    },
    [data, field.id, field.order, fieldPrefix, section]
  );

  // When onBlur() input finished, send the full entered value to the backend.
  const onBlur = useCallback(
    (event, val) => {
      let value = val;  // Use supplied value if present, else take it from the store
                        // (this is useful when we call onBlur chained after onChange,
                        // something that doesn't give time to store the value on the store).
      if (!value) {
        value = getCustomFieldValue(data, field.id, section);
      } else {
        value = { value };
      }
      if (value.value) {
        const payload = { id: field.id, value: value.value };
        // Only save, if the data is dirty (i.e. it was modified), or if a value (val)
        // was supplied (i.e. onBlur was a chained to onChange).
        if (value.dirty || val) {
          dispatch(updateActivity(data.id, payload, field.name));
          // Unset dirty flag:
          dispatch(updateDataFieldInline(`${fieldPath}.dirty`, false));
        }
      } else
        if (field.required) {
          dispatch(errorDataField(fieldPath, 'This field is required', true));
        }
    },
    [data, dispatch, field.id, field.name, field.required, fieldPath, section]
  );

  // When typing on an inline field, only update the value on the store, don't send
  // the full value to the backend, until onBlur().
  const onChange = useCallback(
    (event, value) => {
      dispatch(updateDataFieldInline(`${fieldPath}.field`, field.id));
      dispatch(updateDataFieldInline(`${fieldPath}.value`, value));
      // Set flag to tell there are modifications that needs save:
      dispatch(updateDataFieldInline(`${fieldPath}.dirty`, true));
    },
    [dispatch, field.id, fieldPath]
  );

  const errors = error ? error[fieldPath] : null;

  const isReadOnly = isResponderReadOnly(data) || loading;

  return (
    <CustomEditField
      data={data}
      errors={errors}
      isInline
      isReadOnly={isReadOnly}
      field={field}
      onBlur={onBlur}
      onChange={onChange}
      section={section}
    />
  );
};

InlineEditField.propTypes = {
  data: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  field: PropTypes.object,
  fieldPrefix: PropTypes.string,
  section: PropTypes.number
};

export default memo(InlineEditField);
