import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import Logo from '@icons/logo';
import FlexSeparator from '@shared/helpers/flex-separator';
import ActionItems from './action-items';
import City from './city';
import './header.scss';

const Header = () => (
  <div styleName="header-container">
    <Link to="/map">
      <Logo />
    </Link>
    <City />
    <FlexSeparator />
    <ActionItems />
  </div>
);

export default memo(Header);
