import React from 'react';
import { dotmapsInactiveBlue } from '@constants/colors';
import { topBarStyles } from '@constants/mui-theme';
import { Icon } from '@mui';
import './breadcrumb.scss';

const Separator = () => (
  <div styleName="breadcrumb-entry breadcrumb-separator">
    <Icon
      color={dotmapsInactiveBlue}
      style={topBarStyles.breadCrumb.separator}
    >
      chevron_right
    </Icon>
  </div>
);

export default Separator;
