import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toggleLegend } from '@actions/map-actions';
import { textColor, secondaryColor } from '@constants/colors';
import MapToolbarIconButton from '@shared/map-toolbar-icon-button';
import { shallowEqual } from '@utils/react-utils';
import './legend-icon.scss';

class LegendIcon extends Component {
  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState) {
    return !shallowEqual(nextProps.legendOpen, this.props.legendOpen);
  }

  render() {
    const { legendOpen } = this.props;
    return (
      <div styleName="map-legend-icon">
        <MapToolbarIconButton
          onClick={this.props.toggleLegend}
          color={legendOpen ? secondaryColor : textColor}
          icon="info"
          iconInactive={!legendOpen}
          tooltip="Legend"
        />
      </div>
    );
  }
}

LegendIcon.propTypes = {
  legendOpen: PropTypes.bool,
  toggleLegend: PropTypes.func
};

const mapStateToProps = state => {
  const { legendOpen } = state.map;
  return { legendOpen };
};

export default connect(mapStateToProps, { toggleLegend })(LegendIcon);
