import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'react-flexbox-grid';
import { colors } from '@constants/colors';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { getAgencyIcon } from '@utils/icon-utils';
import DetailsBlock from '@shared/details-block';
import './header.scss';

const useStyles = makeStyles(theme => ({
  bigger: ({ style }) => ({
    border: '1px solid #9B9B9B',
    fontSize: 30,
    margin: '10px 60px',
    width: theme.spacing(7),
    height: theme.spacing(7),
    ...style
  })
}));

const HeaderAvatar = ({ src, style, text }) => {
  const classes = useStyles({ style });
  return (
    <Avatar className={classes.bigger} src={src}>
      {text}
    </Avatar>
  );
};

HeaderAvatar.propTypes = {
  src: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.string
};

class Header extends Component {
  getAvatar = () => {
    const { agencyTypes, data, dataType, fields } = this.props;

    if (dataType === 'agency' && agencyTypes) {
      const dataTypeObject = agencyTypes[data.type];
      const icon = getAgencyIcon(dataTypeObject);
      return <HeaderAvatar src={icon} style={{ backgroundColor: colors.neutral.white }} />;
    }

    const { title } = fields;
    const titleInitial = data[title] ? data[title][0] : '';
    return <HeaderAvatar text={titleInitial} style={{ backgroundColor: '#D8D8D8' }} />;
  };

  render() {
    const { data, info, fields } = this.props;
    const { title, altTitle, subtitle, subtitleFields } = fields;
    const titleValue = data[title] || data[altTitle];
    // eslint-disable-next-line no-nested-ternary
    const subtitleValue = subtitle ? (data[subtitle] ? 'Active' : 'Inactive') : false;

    if (title === null) {
      return null;
    }
    if (!titleValue) {
      return <div>Incorrect or missing required title key</div>;
    }
    if (!subtitleFields) {
      return <div>Incorrect or missing required subtitleFields key</div>;
    }

    return (
      <Row middle="xs">
        <Col>
          {this.getAvatar()}
        </Col>
        <Col xs md={4}>
          <Row>
            <Col>
              <p data-testid="dashboard-details-header-title" styleName="details-title">{titleValue}</p>
              {subtitleValue && <p styleName="details-subtitle">{subtitleValue}</p>}
            </Col>
          </Row>
          {subtitleFields &&
            <Row between="xs">
              {subtitleFields.map(({ label, key, select }) => (
                <Col key={key}>
                  <DetailsBlock label={label} info={info(key, select)} />
                </Col>
              ))}
            </Row>}
        </Col>
      </Row>
    );
  }
}

Header.propTypes = {
  agencyTypes: PropTypes.object,
  data: PropTypes.object,
  dataType: PropTypes.string,
  fields: PropTypes.object,
  info: PropTypes.func
};

const mapStateToProps = state => {
  const { agency_type: agencyTypes } = state.dataTypes;
  return { agencyTypes };
};

export default connect(mapStateToProps, { })(Header);
