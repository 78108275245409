import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Marker, Polyline } from '@react-google-maps/api';
import { segmentDirections } from '@constants/component-configs';
import {
  detailEditCircleStyle,
  detailActiveCircleStyle,
  detailEditLineStyle,
  detailActiveLineStyle,
  detailPolyLineStyle
} from '@constants/data-detail';
import {
  getOneDirectionIcon,
  getBothDirectionIcon
} from '@icons/map-icons';
import MapFigure from '@shared/map-figure';
import wrapSymbolPath from '@shared/symbol-path-wrapper';
import { generatePath } from '@utils/map-utils';

const SymbolPathMarker = wrapSymbolPath(Marker);

export default class EditMapFigure extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dragging: false,
      start: this.props.start,
      end: this.props.end,
      geometryLocked: this.props.geometryLocked
    };

    const lineInteractions = {
      clickable: false,
      draggable: false,
      editable: false
    };
    this.draggableStyle = {...detailEditCircleStyle, draggable: true};
    this.endDraggableStyle = {...this.draggableStyle, zIndex: this.draggableStyle.zIndex * 2};
    this.lineStyle = {...detailEditLineStyle, ...lineInteractions};
    this.centeredElementStyle = {
      ...detailActiveLineStyle,
      ...detailActiveCircleStyle,
      ...lineInteractions,
      ...detailPolyLineStyle
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newValues = {};
    if (nextProps.start !== this.state.start) {
      newValues.start = nextProps.start;
    }
    if (nextProps.end !== this.state.end) {
      newValues.end = nextProps.end;
    }
    this.setState(newValues);
  }

  handleChanged = () => {
    this.setState({dragging: false});
    this.props.onChange(this.state);
  };

  handleStartChange = ({latLng}) => {
    this.setState({start: latLng.toJSON(), dragging: true});
  };

  handleEndChange = ({latLng}) => {
    this.setState({end: latLng.toJSON(), dragging: true});
  };

  handleStartChanged = event => {
    this.handleStartChange(event);
    this.handleChanged();
  };

  handleEndChanged = event => {
    this.handleEndChange(event);
    this.handleChanged();
  };

  getSelectedLineOptions = () => {
    const { direction } = this.props;
    const { dragging } = this.state;
    const options = this.lineStyle;
    if (direction && !dragging) {
      if (direction === segmentDirections.one.value) {
        return {
          ...options,
          icons: [{
            ...options.icons[0],
            icon: getOneDirectionIcon()
          }]
        };
      }
      if (direction === segmentDirections.both.value) {
        return {
          ...options,
          icons: [{
            ...options.icons[0],
            icon: getBothDirectionIcon()
          }]
        };
      }
    }
    return options;
  };

  getPolylinePath = () => {
    const { shape } = this.props;
    const { start, end} = this.state;

    if (shape && shape.coordinates) {
      return generatePath(shape.coordinates);
    }
    return [start, end];
  };

  render() {
    const { shape } = this.props;
    const { start, end, geometryLocked } = this.state;
    const selectedLineOptions = this.getSelectedLineOptions();

    return (
      <div>
        { shape &&
          <MapFigure shape={this.props.shape} options={this.centeredElementStyle} />
        }
        { start && !geometryLocked &&
          <SymbolPathMarker
            position={start}
            options={this.draggableStyle}
            onDrag={this.handleStartChange}
            onDragEnd={this.handleStartChanged}
          />
        }
        { end && !geometryLocked &&
          <SymbolPathMarker
            position={end}
            options={this.endDraggableStyle}
            onDrag={this.handleEndChange}
            onDragEnd={this.handleEndChanged}
          />
        }
        {
          start && end &&
          <Polyline
            path={this.getPolylinePath()}
            options={selectedLineOptions}
          />
        }
      </div>
    );
  }
}

EditMapFigure.propTypes = {
  direction: PropTypes.number,
  end: PropTypes.object,
  geometryLocked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  shape: PropTypes.object,
  start: PropTypes.object
};
