/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { downloadCSVExport } from '@actions/data-table-actions';
import { colors } from '@constants/colors';
import { Icon, IconButton } from '@mui';
import { getFilters } from '@selectors/data-table-selector';
import './action-items.scss';

const ActionItems = ({ dataType, subType }) => {
  const filters = useSelector(state => getFilters(state, { dataType, subType }));
  const dispatch = useDispatch();

  const onExport = () => {
    dispatch(downloadCSVExport(dataType, subType, filters));
  };

  return (
    <div styleName="action-items">
      <IconButton onClick={onExport} tooltip="Export">
        <Icon color={colors.neutral.white}>file_download</Icon>
      </IconButton>
    </div>
  );
};

ActionItems.propTypes = {
  dataType: PropTypes.string,
  subType: PropTypes.string
};

export default memo(ActionItems);
