/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { capitalize, isEmpty } from 'lodash';
import Toggle from 'material-ui/Toggle';
import TextField from 'material-ui/TextField';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import { openDashboardDialog } from '@actions/dashboard-actions';
import {
  clearSchedule,
  cloneSegment,
  deleteSegment,
  swapSegmentFields,
  updatePolygon,
  updateSegmentEndpoints,
  updateSegmentField
} from '@actions/data-detail-actions';
import SnapTooltip from '@forms/segment-list/snap-tooltip';
import { colors } from '@constants/colors';
import { displaySegmentField, displaySegmentType, getEntityTypeLabel, getSegmentFieldConfig } from '@constants/config';
import * as dialog from '@constants/dialogs';
import { detailEdit, formStyles } from '@constants/mui-theme';
import DataTypesSelect from '@forms/data-types-select';
import InputAutocomplete from '@forms/input-autocomplete';
import { Button, Checkbox, Icon, IconButton } from '@mui';
import {
  getSegmentTitle,
  renderScheduleString,
  renderWarning,
  showMissingDatesWarning,
  showScheduleExceedsWarning
} from '@utils/segment-schedule/common';
import './segment-list.scss';

const MAX_TITLE_LEN = 50;
const MAX_DESCRIPTION_LEN = 200;

class SegmentEditListItem extends Component {
  onShapeTypeChange = (event, type) => {
    const {
      formCallbacks: { setActiveEnd, setActiveType },
      formState: { activeSegmentId, activeStart, activeEnd },
      segment
    } = this.props;
    setActiveType(type);
    if (type === 'Point') {
      this.props.updateSegmentEndpoints({ start: activeStart, end: null }, activeSegmentId, segment.override_street_centering);
      setActiveEnd(null);
    }
    if (type === 'LineString' && activeEnd) {
      this.props.updateSegmentEndpoints({ start: activeStart, end: activeEnd }, activeSegmentId, segment.override_street_centering);
    }
    if (type === 'Polygon') {
      this.props.updateSegmentEndpoints({ start: null, end: null }, activeSegmentId, segment.override_street_centering);
    }
  };

  onFieldChange = (name, value) => {
    const { formState: { activeSegmentId, activeStart, activeEnd }, segment } = this.props;
    this.props.updateSegmentField(segment.id, name, value);
    if (name === 'override_street_centering' && segment.id === activeSegmentId) {
      this.props.updateSegmentEndpoints({ start: activeStart, end: activeEnd }, activeSegmentId, value);
    }
  };

  onActiveSegmentChanged = endPoints => {
    const {
      formCallbacks: { setActiveEnd, setActiveStart },
      formState: { activeSegmentId, activeStart, activeEnd },
      segment
    } = this.props;

    const newEndPoints = {
      ...{ start: activeStart, end: activeEnd },
      ...endPoints
    };
    setActiveStart(newEndPoints.start);
    setActiveEnd(newEndPoints.end);
    this.props.updateSegmentEndpoints(newEndPoints, activeSegmentId, segment.override_street_centering, 'segmentForm');
  };

  onFieldError = (name, error) => {
    const { segment } = this.props;
    this.props.updateSegmentField(segment.id, name, null, error);
  };

  onCheck = event => {
    const { segment } = this.props;
    const name = event.target.name;
    this.onFieldChange(name, !segment[name]);
  };

  onFromChange = value => this.onFieldChange('display_from', value);

  onToChange = value => this.onFieldChange('display_to', value);

  onTitleChange = (event, value) => this.onFieldChange('title', value.substring(0, MAX_TITLE_LEN));

  onDescriptionChange = (event, value) => this.onFieldChange('description', value.substring(0, MAX_DESCRIPTION_LEN));

  onDirectionChange = (event, index, value) => this.onFieldChange('direction', value);

  onImpactChange = (event, index, value) => this.onFieldChange('impact_type', value);

  onFromGeocode = latLng => this.onActiveSegmentChanged({ start: latLng });

  onToGeocode = latLng => this.onActiveSegmentChanged({ end: latLng });

  onFromGeocodeError = status => this.onFieldError('display_from', status);

  onToGeocodeError = status => this.onFieldError('display_to', status);

  swapAddresses = () => {
    const {
      formCallbacks: { setActiveEnd, setActiveStart },
      formState: { activeStart, activeEnd },
      segment
    } = this.props;
    this.props.swapSegmentFields(segment.id, 'display_from', 'display_to');
    // Swap start/end too:
    setActiveStart(activeEnd);
    setActiveEnd(activeStart);
  };

  cloneSegment = () => {
    const { segment } = this.props;
    this.props.cloneSegment(segment.id);
  };

  deleteSegment = () => {
    const { segment } = this.props;
    this.props.deleteSegment(segment.id);
  };

  editSchedule = () => {
    const { data, segment } = this.props;
    const params = {
      id: segment.id,
      start_date: data.start_date,
      end_date: data.end_date
    };
    this.props.openDashboardDialog(dialog.EDIT_SCHEDULE, params);
  };

  clearSchedule = () => {
    const { segment } = this.props;
    this.props.clearSchedule(segment.id);
  };

  clearPolygon = () => {
    this.props.updatePolygon(this.props.segment.id, null, 'map');
  };

  renderShapeType = () => {
    // It could be simpler to have in this method a single RadioButtonGroup
    // and only switch on/off the polygon RadioButton, however, for
    // some reason (some code inside RadioButtonGroup), we cannot
    // add conditional JSX within RadioButtonGroups, thus we
    // have to separate this in two.
    const { formState: { activeType }, dataType, readOnly } = this.props;
    if (!displaySegmentField(dataType, 'shape-type')) {
      return null;
    }
    if (displaySegmentType(dataType, 'polygon')) {
      return (
        <div styleName="shape-type">
          <RadioButtonGroup
            name="shapeType"
            defaultSelected={activeType}
            valueSelected={activeType}
            onChange={this.onShapeTypeChange}
            {...formStyles().radioGroup}
          >
            <RadioButton
              disabled={readOnly}
              value="Point"
              label={<div styleName="label">Point</div>}
              {...formStyles(readOnly).radio}
            />
            <RadioButton
              disabled={readOnly}
              value="LineString"
              label={<div styleName="label">Segment</div>}
              {...formStyles(readOnly).radio}
            />
            <RadioButton
              disabled={readOnly}
              value="Polygon"
              label={<div styleName="label">Polygon</div>}
              {...formStyles(readOnly).radio}
            />
          </RadioButtonGroup>
        </div>
      );
    }
    return (
      <div styleName="shape-type">
        <RadioButtonGroup
          name="shapeType"
          defaultSelected={activeType}
          valueSelected={activeType}
          onChange={this.onShapeTypeChange}
          {...formStyles().radioGroup}
        >
          <RadioButton
            disabled={readOnly}
            value="Point"
            label={<div styleName="label">Point</div>}
            {...formStyles(readOnly).radio}
          />
          <RadioButton
            disabled={readOnly}
            value="LineString"
            label={<div styleName="label">Segment</div>}
            {...formStyles(readOnly).radio}
          />
        </RadioButtonGroup>
      </div>
    );
  };

  renderAddress = () => {
    const { formState: { activeType }, dataType, checkAddressErrors, readOnly, segment } = this.props;
    if (activeType === 'Polygon') {
      return null;
    }
    if (!displaySegmentField(dataType, 'addresses')) {
      return null;
    }
    const fromName = activeType === 'LineString' ? 'From address*' : 'Address*';
    const toName = 'To address*';
    let fromError = segment.display_from_error;
    let toError = segment.display_to_error;
    if (checkAddressErrors &&
       (isEmpty(segment.display_from) ||
       isEmpty(segment.display_to))) {
      fromError = 'Enter an address';
      toError = 'Enter an address';
    }
    return (
      <div styleName="address">
        <div styleName="from-to">
          <div>
            <InputAutocomplete
              initName="placeFrom"
              inputProps={{
                disabled: readOnly,
                errorText: fromError,
                floatingLabelText: fromName,
                name: fromName,
                onChange: this.onFromChange,
                value: segment.display_from || '',
                ...detailEdit.segmentEdit.textField
              }}
              onGeocodeCompleted={this.onFromGeocode}
              onGeocodeError={this.onFromGeocodeError}
            />
          </div>
          {activeType === 'LineString' &&
            <div>
              <InputAutocomplete
                initName="placeTo"
                inputProps={{
                  disabled: readOnly,
                  errorText: toError,
                  floatingLabelText: toName,
                  onChange: this.onToChange,
                  value: segment.display_to || '',
                  ...detailEdit.segmentEdit.textField
                }}
                onGeocodeCompleted={this.onToGeocode}
                onGeocodeError={this.onToGeocodeError}
              />
            </div>
          }
        </div>
        {activeType === 'LineString' && !readOnly &&
          <div styleName="swap">
            <IconButton
              onClick={this.swapAddresses}
              tooltip="Swap from/to"
            >
              <Icon>swap_vert</Icon>
            </IconButton>
          </div>
        }
      </div>
    );
  };

  renderSnapAndAlley = () => {
    const { formState: { activeType }, dataType, readOnly, segment } = this.props;
    if (activeType === 'Polygon') {
      return null;
    }
    if (!displaySegmentField(dataType, 'snap_n_alley')) {
      return null;
    }
    return (
      <div styleName="snap-n-alley">
        <Toggle
          label="Snap to streets"
          labelPosition="right"
          name="override_street_centering"
          disabled={readOnly}
          toggled={!segment.override_street_centering}
          onToggle={this.onCheck}
          {...formStyles(readOnly).toggle}
        />
        <SnapTooltip />
        {segment.override_street_centering &&
          <span style={{ paddingLeft: '1.5rem' }}>
            <Checkbox
              checked={(segment.override_street_centering || false) && (segment.is_alley || false)}
              disabled={!segment.override_street_centering || readOnly}
              label="This is an alley"
              name="is_alley"
              onChange={this.onCheck}
              size="small"
              style={{ padding: '0 10px 0 10px' }}
            />
          </span>
        }
      </div>
    );
  };

  renderImpact = () => {
    const { dataType, readOnly, segment } = this.props;
    if (!displaySegmentField(dataType, 'impact')) {
      return null;
    }
    return (
      <div styleName="impact">
        <DataTypesSelect
          dataName="segment_direction"
          disabled={readOnly}
          floatingLabelText="Direction of impact"
          name="direction"
          onChange={this.onDirectionChange}
          styleName="direction"
          value={segment.direction}
        />
        <DataTypesSelect
          dataName="segment_impact_type"
          disabled={readOnly}
          floatingLabelText="Type of impact"
          name="impact_type"
          onChange={this.onImpactChange}
          styleName="impact-type"
          value={segment.impact_type}
        />
      </div>
    );
  };

  renderTitle = () => {
    const { dataType, readOnly, segment } = this.props;
    if (!displaySegmentField(dataType, 'title')) {
      return null;
    }
    const segmentFieldConfig = getSegmentFieldConfig(dataType, 'title');
    return (
      <div styleName="title">
        <TextField
          {...detailEdit.segmentEdit.textField}
          disabled={readOnly}
          name="Title"
          floatingLabelText={'label' in segmentFieldConfig ? segmentFieldConfig.label : 'Title'}
          onChange={this.onTitleChange}
          value={segment.title}
        />
        <div styleName="text-field-footer">
          <div styleName="hint">
            Replaces the header with a custom title instead of On Street value.
          </div>
          <div styleName="counter">
            {segment.title ? segment.title.length : 0}/{MAX_TITLE_LEN}
          </div>
        </div>
      </div>
    );
  };

  renderDescription = () => {
    const { dataType, readOnly, segment } = this.props;
    if (!displaySegmentField(dataType, 'description')) {
      return null;
    }
    const segmentFieldConfig = getSegmentFieldConfig(dataType, 'description');
    return (
      <div styleName="description">
        <TextField
          {...detailEdit.segmentEdit.textField}
          disabled={readOnly}
          multiLine
          name="Description"
          floatingLabelText={'label' in segmentFieldConfig ? segmentFieldConfig.label : 'Description'}
          onChange={this.onDescriptionChange}
          value={segment.description}
        />
        <div styleName="text-field-footer">
          <div styleName="hint" />
          <div styleName="counter">
            {segment.description ? segment.description.length : 0}/{MAX_DESCRIPTION_LEN}
          </div>
        </div>
      </div>
    );
  };

  renderCNN = () => {
    const { dataType, readOnly, segment } = this.props;
    if (!displaySegmentField(dataType, 'cnn')) {
      return null;
    }
    return (
      <div styleName="description">
        <TextField
          {...detailEdit.segmentEdit.textField}
          disabled={readOnly}
          multiLine
          name="CNN"
          floatingLabelText="CNN"
          value={segment.cnn}
          readOnly
        />
      </div>
    );
  };

  displaySchedule = () => displaySegmentField(this.props.dataType, 'schedule');

  renderSchedule = () => {
    const { readOnly, segment } = this.props;
    if (!this.displaySchedule()) {
      return null;
    }
    if (isEmpty(segment.schedules)) {
      return null;
    }
    return (
      <div styleName="schedule">
        {renderScheduleString(segment.schedules)}
        {!readOnly && !isEmpty(segment.schedules) && (
          <div styleName="schedule-edit-icon">
            <IconButton
              onClick={this.editSchedule}
              size="small"
              tooltip="Edit Schedule"
            >
              <Icon color={colors.opacity.black0_6}>edit</Icon>
            </IconButton>
          </div>
        )}
        {!readOnly && !isEmpty(segment.schedules) && (
          <div styleName="schedule-remove-icon">
            <IconButton
              onClick={this.clearSchedule}
              size="small"
              tooltip="Remove Schedule"
            >
              <Icon color={colors.opacity.black0_6}>close</Icon>
            </IconButton>
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      formState: { activeType },
      checkAddressErrors,
      data,
      dataType,
      onClick,
      readOnly,
      segment
    } = this.props;
    const polygonInfoStyle = `polygon-info ${checkAddressErrors ? 'polygon-error' : ''}`;
    return (
      <div styleName="segment-edit-list-item">
        <div
          onClick={onClick}
          role="presentation"
          styleName="action-header"
        >
          <div styleName="label">{getSegmentTitle(segment)}</div>
          <div styleName="icon">
            <Icon>unfold_less</Icon>
          </div>
        </div>
        {this.renderShapeType()}
        {this.renderAddress()}
        {this.renderSnapAndAlley()}
        {activeType === 'Polygon' && !segment.shape &&
          <div styleName={polygonInfoStyle}><span>Draw a polygon on the map</span></div>
        }
        {activeType === 'Polygon' && segment.shape &&
          <div styleName="polygon-info">
            <span>Polygon</span>
            {!readOnly && <Button onClick={this.clearPolygon}>CLEAR</Button>}
          </div>
        }
        {this.renderImpact()}
        {this.renderTitle()}
        {this.renderDescription()}
        {this.renderCNN()}
        {this.displaySchedule() && showMissingDatesWarning(data.start_date, data.end_date) &&
          <div styleName="segment-edit-warning">
            {renderWarning(`${capitalize(getEntityTypeLabel(dataType))} schedule is missing.`)}
          </div>
        }
        {this.displaySchedule() && showScheduleExceedsWarning(data.start_date, data.end_date, segment) &&
          <div styleName="segment-edit-warning">
            {renderWarning(`Schedule exceeds the ${getEntityTypeLabel(dataType)} schedule.`)}
          </div>
        }
        {this.renderSchedule()}
        {this.displaySchedule() && !readOnly && isEmpty(segment.schedules) &&
          <div styleName="schedule-button">
            <Button color="primary" onClick={this.editSchedule}>
              SET SCHEDULE
            </Button>
          </div>
        }
        {!readOnly && <div styleName="separator" />}
        {!readOnly &&
          <div styleName="action">
            <div styleName="empty" />
            <IconButton
              onClick={this.cloneSegment}
              tooltip="Duplicate"
              style={{ opacity: '0.6' }}
            >
              <Icon>content_copy</Icon>
            </IconButton>
            <IconButton
              onClick={this.deleteSegment}
              tooltip="Delete"
              style={{ opacity: '0.6' }}
            >
              <Icon>delete</Icon>
            </IconButton>
          </div>
        }
      </div>
    );
  }
}

SegmentEditListItem.propTypes = {
  // If true, check if addresses are empty and show the error.
  checkAddressErrors: PropTypes.bool,
  clearSchedule: PropTypes.func,
  cloneSegment: PropTypes.func,
  data: PropTypes.object,
  dataType: PropTypes.string,
  deleteSegment: PropTypes.func,
  formCallbacks: PropTypes.object,
  formState: PropTypes.object,
  onClick: PropTypes.func,
  openDashboardDialog: PropTypes.func,
  readOnly: PropTypes.bool,
  segment: PropTypes.object,
  segmentImpactTypes: PropTypes.object,
  swapSegmentFields: PropTypes.func,
  updatePolygon: PropTypes.func,
  updateSegmentEndpoints: PropTypes.func,
  updateSegmentField: PropTypes.func
};

const mapStateToProps = (state, props) => {
  const segment = state.dataDetail.data.segments && state.dataDetail.data.segments.find(seg => seg.id === props.formState.activeSegmentId);
  const segmentImpactTypes = state.dataTypes.segment_impact_type;
  return { segment, segmentImpactTypes };
};

const mapDispatchToProps = {
  clearSchedule,
  cloneSegment,
  deleteSegment,
  openDashboardDialog,
  swapSegmentFields,
  updatePolygon,
  updateSegmentEndpoints,
  updateSegmentField
};

export default connect(mapStateToProps, mapDispatchToProps)(SegmentEditListItem);
