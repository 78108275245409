/* eslint-disable react/jsx-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFiltersDashboard } from '@constants/config';
import DateFilter from './date-filter';
import PastDateFilter from './past-date-filter';
import AgencyFilter from './agency-filter';
import AgencyTypeFilter from './agency-type-filter';
import CheckboxFilter from './checkbox-filter';
import FieldFilter from './field-filter';
import CategoryFilter from './category-filter';
import './dashboard-filters.scss';

class DashboardFilters extends Component {
  getFilters = () => {
    const { dataType, subType } = this.props;
    const filtersConfig = getFiltersDashboard()[dataType];
    if (Array.isArray(filtersConfig)) {
      return filtersConfig;
    }
    // If the filters config is not an array, it means it
    // contains sub-types (i.e. groups and overlaps):
    return filtersConfig[subType];
  };
  render() {
    const { categoryTypes, dataType, subType } = this.props;
    const filters = this.getFilters();
    return (
      <div styleName="dashboard-filters-container">
        {filters.map(filter => {
          if (filter.type === 'category') {
            const categoryType = categoryTypes.find(type => type.name === filter.name);
            return (
              <CategoryFilter
                categoryTypeId={categoryType.id}
                categoryType={filter.name}
                dataType={dataType}
                filter={filter.params}
                key={filter.name}
                label={categoryType.label}
                showIcon={filter.showIcon}
                subType={subType}
              />
            );
          } else
            if (filter.type === 'date') {
              return <DateFilter dataType={dataType} subType={subType} />;
            } else
              if (filter.type === 'past-date') {
                return <PastDateFilter dataType={dataType} fieldName={filter.name} label={filter.label} subType={subType} />;
              } else
                if (filter.type === 'agency') {
                  return <AgencyFilter dataType={dataType} subType={subType} />;
                } else
                  if (filter.type === 'agency-type') {
                    return <AgencyTypeFilter dataType={dataType} subType={subType} />;
                  } else
                    if (filter.type === 'field') {
                      return (
                        <FieldFilter
                          dataType={dataType}
                          items={filter.items}
                          label={filter.label}
                          name={filter.name}
                          sort={filter.sort}
                          sourceDataType={filter.sourceDataType}
                          subType={subType}
                        />
                      );
                    } else
                      if (filter.type === 'checkbox') {
                        return (
                          <CheckboxFilter
                            dataType={dataType}
                            label={filter.label}
                            name={filter.name}
                            onValue={filter.onValue}
                            offValue={filter.offValue}
                            subType={subType}
                          />
                        );
                      }
          return null;
        })}
      </div>
    );
  }
}

DashboardFilters.propTypes = {
  categoryTypes: PropTypes.array,
  dataType: PropTypes.string,
  subType: PropTypes.string
};


const mapStateToProps = state => {
  const categoryTypes = state.dataTypes.map_category_type;
  return {
    categoryTypes: Object.values(categoryTypes)
  };
};

export default connect(mapStateToProps, {})(DashboardFilters);
