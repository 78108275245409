import React, { forwardRef, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import BatchUploadForm from '@shared/batch-upload-form';

const BatchMenuItem = forwardRef(({ onClick, ...passThroughProps }, ref) => {
  const [batchOpen, setBatchOpen] = React.useState(false);
  const closeBatch = useCallback(() => {
    setBatchOpen(false);
  }, [setBatchOpen]);

  const handleClick = useCallback(() => {
    setBatchOpen(true);
    if (onClick) {
      onClick();
    }
  }, [onClick, setBatchOpen]);

  return (
    <MenuItem onClick={handleClick} {...passThroughProps} ref={ref}>
      Upload Batch
      <BatchUploadForm
        open={batchOpen}
        onClose={closeBatch}
      />
    </MenuItem>
  );
});

BatchMenuItem.displayName = 'BatchMenuItem';

BatchMenuItem.propTypes = {
  onClick: PropTypes.func
};

export default memo(BatchMenuItem);
