/* eslint-disable react/display-name */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undefined */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isString } from 'lodash';
import moment from 'moment-timezone';
import { SAFE_ZINDEX } from '@constants/mui-theme';
import CalendarToday from '@material-ui/icons/CalendarToday';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { getConfig } from '@utils/config-utils';

const FormattedDatePicker = ({
  className = '',
  clearable,
  disabled,
  errorText,
  floatingLabelText,
  fullWidth,
  id,
  minDate,
  maxDate,
  onChange,
  style = {},
  styleName = '',
  value
}) => {
  useEffect(() => {
    if (!value && !clearable && (minDate || maxDate)) {
      onChange(minDate || maxDate);
    }
  }, [clearable, maxDate, minDate, onChange, value]);
  const { timezoneName } = getConfig();

  const onAccept = date => {
    if (isEmpty(date)) {
      onChange(null);
      return;
    }
    let newDate = date;
    if (isString(date)) {
      newDate = moment(date, 'MM/DD/YYYY');
    }
    const fixedDate = moment.tz(newDate, timezoneName).startOf('day');
    onChange(fixedDate);
  };

  // Add these props if they are not null (since the date picker component
  // can't handle null values for these props correctly).
  const pickerProps = {};
  if (clearable) {
    pickerProps.clearable = clearable;
  }
  if (maxDate) {
    pickerProps.maxDate = maxDate;
  }
  if (minDate) {
    pickerProps.minDate = minDate;
  }
  if (errorText) {
    pickerProps.error = true;
    pickerProps.helperText = errorText;
  }

  return (
    <div className={className} data-testid="date-picker" style={style} styleName={styleName}>
      <KeyboardDatePicker
        {...pickerProps}
        DialogProps={{ style: SAFE_ZINDEX }}
        KeyboardButtonProps={{ 'aria-label': 'change date' }}
        autoOk
        disabled={disabled}
        format="MM/DD/YYYY"
        fullWidth={fullWidth}
        id={id}
        keyboardIcon={<CalendarToday style={{ fontSize: '1.125rem' }} />}
        label={floatingLabelText}
        margin="normal"
        onChange={onAccept}
        onAccept={onAccept}
        onBlur={event => onAccept(event.target.value)}
        value={typeof value === 'undefined' ? null : value}
        inputProps={{style: disabled ? {color: 'rgba(0,0,0,0.3)'} : {}}}
      />
    </div>
  );
};

FormattedDatePicker.propTypes = {
  className: PropTypes.string,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  floatingLabelText: PropTypes.string,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  onChange: PropTypes.func,
  style: PropTypes.object,
  styleName: PropTypes.string,
  value: PropTypes.object
};

export default FormattedDatePicker;
