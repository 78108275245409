import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { push } from 'connected-react-router';
import { showConfirmationDialog } from '@actions/confirmation-actions';
import {
  requestDashboardSave,
  updateDashboardDetailsData,
  confirmableDeleteDashboardEntry
} from '@actions/dashboard-actions';
import { colors } from '@constants/colors';
import { Button } from '@mui';
import {
  dashboardDetailsDataValidSelector
} from '@selectors/dashboard-details-selector';
import { isAppAdmin } from '@utils/permission-utils';

class EditActions extends Component {
  constructor(props) {
    super(props);
    this.save = () => {
      const { dataType, location } = props;
      const source = R.path(['state', 'source'], location);
      props.updateDashboardDetailsData(dataType, source || `/list/data/${dataType}`);
    };
    this.cancel = () => {
      props.requestDashboardSave(false);
      this.props.push(`/list/data/${props.dataType}`);
    };
    this.displayWarning = () => props.requestDashboardSave(true);
    const displayDataType = props.dataType.replace(/_/g, ' ');
    this.deleteDashboardEntry = () => this.props.showConfirmationDialog(
      `Delete ${displayDataType}`,
      `Are you sure you want to delete this ${displayDataType}?`,
      confirmableDeleteDashboardEntry(props.dataType, Number(props.dataId))
    );
  }

  render() {
    const { dataValid, dataId, dataType, saving } = this.props;
    // The Delete button will only appear if we are editing
    // an existing record and the user is an app admin.
    //
    // This button is also disabled for the user data type, since
    // for users we display the delete option in a dropdown menu.
    const showDelete = dataId !== 'new' && dataType !== 'user' && isAppAdmin();
    const styles = {
      backgroundColor: colors.neutral.white,
      color: colors.brand.blue
    };

    return (
      <div>
        {showDelete &&
          <Button
            onClick={this.deleteDashboardEntry}
            style={{ marginRight: '0.5rem' }}
          >
            Delete
          </Button>
        }
        <Button
          disabled={saving}
          onClick={this.cancel}
          style={{ marginRight: '0.5rem' }}
        >
          Cancel
        </Button>
        <Button
          disabled={saving}
          onClick={dataValid ? this.save : this.displayWarning}
          style={styles}
        >
          Save
        </Button>
      </div>
    );
  }
}

EditActions.propTypes = {
  dataId: PropTypes.string,
  dataType: PropTypes.string,
  dataValid: PropTypes.bool,
  location: PropTypes.object,
  push: PropTypes.func,
  requestDashboardSave: PropTypes.func,
  saving: PropTypes.bool,
  showConfirmationDialog: PropTypes.func,
  updateDashboardDetailsData: PropTypes.func
};

const mapStateToProps = (state, {dataType}) => {
  const { saving } = state.dashboard;
  return {
    dataValid: dashboardDetailsDataValidSelector(state, dataType),
    saving: saving[dataType] ? saving[dataType] : false
  };
};

export default connect(mapStateToProps, {
  requestDashboardSave,
  showConfirmationDialog,
  push,
  updateDashboardDetailsData
})(EditActions);
