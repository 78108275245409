/*eslint-disable max-len*/
import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'material-ui/SvgIcon';

const MapIcon = props => (
  <SvgIcon {...props} style={{...props.style}} viewBox="0 0 26 26">
    <path fillRule="evenodd" clipRule="evenodd" d="M25.2778 0L25.0467 0.0433333L17.3333 3.03333L8.66667 0L0.52 2.74444C0.216667 2.84556 0 3.10556 0 3.43778V25.2778C0 25.6822 0.317778 26 0.722222 26L0.953333 25.9567L8.66667 22.9667L17.3333 26L25.48 23.2556C25.7833 23.1544 26 22.8944 26 22.5622V0.722222C26 0.317778 25.6822 0 25.2778 0Z" fill="#26A69A"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M25.2778 0L25.0467 0.0433333L17.3333 3.03333L8.66667 0L0.52 2.74444C0.216667 2.84556 0 3.10556 0 3.43778V25.2778C0 25.6822 0.317778 26 0.722222 26L0.953333 25.9567L8.66667 22.9667L17.3333 26L25.48 23.2556C25.7833 23.1544 26 22.8944 26 22.5622V0.722222C26 0.317778 25.6822 0 25.2778 0Z" fill="#1DAF54"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.3334 3.03333L8.66675 0V22.9667L17.3334 26V3.03333Z" fill="#0F8479"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.3334 3.03333L8.66675 0V22.9667L17.3334 26V3.03333Z" fill="#009337"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.6604 19.0667H17.3333V11.375L12.6604 19.0667Z" fill="#1F8479" fillOpacity="0.5"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.6534 5.19995C9.97018 5.19995 7.80005 7.37008 7.80005 10.0533C7.80005 13.6933 12.6534 19.0666 12.6534 19.0666C12.6534 19.0666 17.5067 13.6933 17.5067 10.0533C17.5067 7.37008 15.3366 5.19995 12.6534 5.19995ZM12.6534 11.7866C11.6966 11.7866 10.92 11.0101 10.92 10.0533C10.92 9.09648 11.6966 8.31995 12.6534 8.31995C13.6102 8.31995 14.3867 9.09648 14.3867 10.0533C14.3867 11.0101 13.6102 11.7866 12.6534 11.7866Z" fill="white"/>
  </SvgIcon>
);

MapIcon.propTypes = {
  style: PropTypes.object
};

export default MapIcon;
