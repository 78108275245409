import moment from 'moment-timezone';
import BaseDateFilter from './base-date-filter';
import * as dialog from '@constants/dialogs';

// Enhancement note:
//
//   The logic between the Future and Past date filters is similar.
//   If won't add different types of date filters, we should think about
//   merging more the Past and Futre filter classes.
class FutureDateFilter extends BaseDateFilter {
  buildSelectedId(start, end) {
    if (start === null && end === null) {
      return 1; // Any time:
    }
    const startDate = moment(start, 'YYYY-MM-DD');
    const endDate = moment(end, 'YYYY-MM-DD');
    if (startDate.isSame(endDate, 'day')) {
      if (startDate.isSame(moment(), 'day')) {
        return 2; // Today
      }
      if (startDate.isSame(moment().add(1, 'days'), 'day')) {
        return 3; // Tomorrow
      }
    }
    if (startDate.isSame(moment(), 'day')) {
      if (endDate.isSame(moment().add(7, 'days'), 'day')) {
        return 4; // Next 7 days
      }
      if (endDate.isSame(moment().add(30, 'days'), 'day')) {
        return 5; // Next 30 days
      }
      if (endDate.isSame(moment().add(90, 'days'), 'day')) {
        return 6; // Next 90 days
      }
    }
    // Else it's a custom range date:
    return this.getCustomRangeId();
  }

  filterChange(event) {
    const value = event.target.value;
    let end = null;
    let start = null;
    switch (value) {
    case 1: { // Any time:
      break;
    }
    case 2: { // Today:
      start = moment();
      end = moment();
      break;
    }
    case 3: { // Tomorrow:
      start = moment().add(1, 'days');
      end = moment().add(1, 'days');
      break;
    }
    case 4: { // Next 7 days:
      start = moment();
      end = moment().add(7, 'days');
      break;
    }
    case 5: { // Next 30 days:
      start = moment();
      end = moment().add(30, 'days');
      break;
    }
    case 6: { // Next 90 days;
      start = moment();
      end = moment().add(90, 'days');
      break;
    }
    case this.getCustomRangeId(): {
      // The date dialog will set the new date on
      // the store, thus this component will be
      // updated since we listen for start/end/contains.
      this.props.openDialog(dialog.DATE_RANGE);
      return;
    }
    default:
      break;
    }
    const newState = {
      contain: false,
      end,
      selected: value,
      start
    };
    this.setState(newState, () => {
      this.filterClose();
    });
  }

  getDateItems() {
    return [
      {id: 1, name: 'Any time'},
      {id: 2, name: 'Today'},
      {id: 3, name: 'Tomorrow'},
      {id: 4, name: 'Next 7 days'},
      {id: 5, name: 'Next 30 days'},
      {id: 6, name: 'Next 90 days'},
      {id: this.getCustomRangeId(), name: 'Custom range'}
    ];
  }
}

export default FutureDateFilter;

