import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@constants/colors';
import Avatar from '@material-ui/core/Avatar';
import { Button, Icon, IconButton } from '@mui';
import FlexSeparator from '@shared/helpers/flex-separator';
import './top-filter-bar.scss';

const TopFilterBar = ({
  children,
  filterTopBarOpen,
  resetFilters,
  toggleFilterTopBar
}) => {
  if (!filterTopBarOpen) {
    return null;
  }
  return (
    <div styleName="top-filter-bar-container">
      <div name="title">Filters</div>
      {children}
      <FlexSeparator />
      <Button color="primary" onClick={resetFilters}>RESET</Button>
      <div name="last" />
      <IconButton onClick={toggleFilterTopBar} style={{ padding: 0 }}>
        <Avatar>
          <Icon color={colors.neutral.white}>filter_list</Icon>
        </Avatar>
      </IconButton>
    </div>
  );
};

TopFilterBar.propTypes = {
  // Children contains the filter components
  // used by the classes that reuse this component.
  children: PropTypes.node,
  // Flag to tell whether to render this component or not.
  filterTopBarOpen: PropTypes.bool,
  // Function to call to reset the filters.
  resetFilters: PropTypes.func,
  // Function to call to show/hide this filter bar.
  toggleFilterTopBar: PropTypes.func
};

export default TopFilterBar;
