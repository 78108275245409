import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Grid } from 'react-flexbox-grid';
import Header from './header';
import Sections from './sections';
import {
  fetchDashboardDetailsData,
  createEmptyDataSet
} from '@actions/dashboard-actions';
import { dotmapsGray } from '@constants/colors';
import { PERMISSION_GROUP_ANALYST } from '@constants/permission';
import { Icon, IconButton } from '@mui';
import {
  dashboardDetailsDataSelector
} from '@selectors/dashboard-details-selector';
import {
  getFieldInfo,
  getInitialValues,
  labeizeDataType
} from '@utils/dashboard-details-utils';
import { isInGroup } from '@utils/permission-utils';
import './details.scss';

const Details = () => {
  const location = useLocation();
  const { dataId, dataType } = useParams();
  const dataTypes = useSelector(state => state.dataTypes);
  const dashboard = useSelector(state => state.dashboard);
  const { details } = useSelector(state => state.config);
  const data = useSelector(state => dashboardDetailsDataSelector(state, dataType));
  const dispatch = useDispatch();

  const detailData = dashboard.details[dataType] || {};
  const apiError = detailData.error || {};
  const fields = useMemo(
    () => details[dataType],
    [dataType, details]
  );

  useEffect(() => {
    if (!data) {
      if (dataId === 'new') {
        const initialValues = getInitialValues(fields);
        dispatch(createEmptyDataSet(dataType, initialValues));
      } else {
        dispatch(fetchDashboardDetailsData(dataType, dataId));
      }
    }
  }, [data, dataId, dataType, dispatch, fields]);

  const info = (key, select, defaultValue) => getFieldInfo(key, select, defaultValue, data, dataTypes);

  const isEdit = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.has('edit') || dataId === 'new';
  };

  const edit = isEdit();
  if (!fields) {
    return <div data-testid="dashboard-details-error">Missing details fields config</div>;
  }

  const { sections } = fields;
  // Don't show the back arrow on the agency details page
  // for analysts (since they don't see the agency list, they only see the details
  // for their agency, thus they don't have a place to return.
  const showBackArrow = !(isInGroup(PERMISSION_GROUP_ANALYST) && dataType === 'agency');
  return (
    <div data-testid="dashboard-details" styleName="dashboard-details-container">
      {showBackArrow && (
        <div styleName="dashboard-details-top-bar">
          <Link to={`/list/data/${dataType}`}>
            <IconButton>
              <Icon color={dotmapsGray}>arrow_back</Icon>
            </IconButton>
          </Link>
          <div>
           Back to {labeizeDataType(dataType)}
          </div>
        </div>
      )}
      {!showBackArrow && <div styleName="dashboard-details-separator" />}
      {data &&
        <Grid data-testid="dashboard-details-grid" fluid>
          {!edit && <Header {...{ dataType, data, info, fields }} />}
          {sections && <Sections {...{ apiError, dataType, data, edit, info, fields }} />}
        </Grid>}
    </div>
  );
};

export default memo(Details);
