import React, { memo } from 'react';
import PropTypes from 'prop-types';
import * as colors from '@constants/colors';
import { getCalendarEntity } from '@constants/config';
import IconMenu from '@shared/icon-menu';
import IconMenuItem from '@shared/icon-menu-item';
import { canEditEntities } from '@utils/permission-utils';
import './top-bar-action-items.scss';

const ActionItems = ({ resetAllFilters }) => {
  const calendarEntity = getCalendarEntity();
  if (!canEditEntities(null, calendarEntity)) {
    return null;
  }
  return (
    <div styleName="action-items-container">
      <IconMenu iconStyles={{ color: colors.overlayTextColor }}>
        <IconMenuItem onClick={resetAllFilters}>
          Reset filters
        </IconMenuItem>
      </IconMenu>
    </div>
  );
};

ActionItems.propTypes = {
  resetAllFilters: PropTypes.func
};

export default memo(ActionItems);
