/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { openDashboardDialog } from '@actions/dashboard-actions';
import { downloadCSVFromURL } from '@actions/data-table-actions';
import { setGroupPreFill } from '@actions/groups-actions';
import DeleteGroupDialog from '@components/group/dialogs/delete';
import GroupsAddRecordsDialog from '@components/group/dialogs/add-records';
import { colors } from '@constants/colors';
import { getEntitiesListConfig, isNewLayout } from '@constants/config';
import { GROUP_DATA_TYPE } from '@components/config/constants';
import * as dialog from '@constants/dialogs';
import { BASE_URL } from '@constants/endpoints';
import DrawLineIcon from '@icons/draw-line-icon';
import GanttChartIcon from '@icons/gantt-chart-icon';
import GroupTypeIcon from '@icons/group-type-icon';
import Divider from '@material-ui/core/Divider';
import { Icon, IconButton } from '@mui';
import ActionDownloadIconButton from '@shared/action-download-icon-button';
import IconMenu from '@shared/icon-menu';
import IconMenuItem from '@shared/icon-menu-item';
import FlexSeparator from '@shared/helpers/flex-separator';
import { getDashboardBackURL } from '@utils/navigation-utils';
import { canEditGroups } from '@utils/permission-utils';
import { withRouter } from '@utils/router-utils';
import './sub-nav.scss';

class SubNav extends Component {
  getGroupType = () => this.props.params.groupType;

  onBackClick = () => {
    const customBackLink = isNewLayout() ? `/library/${GROUP_DATA_TYPE}/${this.getGroupType()}` : `/list/data/group/${this.getGroupType()}`;
    const source = getDashboardBackURL(this.props.location, 'group', customBackLink);
    this.props.push(source);
  };

  deleteGroup = () => {
    const params = { group: this.props.group };
    this.props.openDashboardDialog(dialog.DELETE_GROUP, params);
  };

  // Set the group pre-fill data for the main map 'Add to group' form.
  setGroupPreFill = () => {
    const { id, type } = this.props.group;
    this.props.setGroupPreFill({ id, type });
  };

  goTo = params => {
    const { id } = this.props.group;
    this.props.push({
      ...params,
      state: {
        source: `/group/${this.getGroupType()}/${id}`
      }
    });
  };

  goToMapSelection = () => {
    this.setGroupPreFill();
    this.goTo({ pathname: '/map', search: '?open=shape' });
  };

  openSearch = () => {
    const params = {
      group: this.props.group,
      groupTypeName: this.getGroupType()
    };
    this.props.openDashboardDialog(dialog.ADD_RECORDS_TO_GROUP, params);
  };

  goToActivityLog = () => {
    const { id } = this.props.group;
    this.goTo({ pathname: `/logs/group/group/${id}` });
  };

  goToGanttChart = () => {
    const { id } = this.props.group;
    this.goTo({ pathname: `/group/gantt/${this.getGroupType()}/${id}` });
  };

  downloadCSV = entityType => {
    const url = `${BASE_URL}/be/group/export_csv/${entityType}/${this.props.group.id}/`;
    const filename = `group-${this.getGroupType()}-${this.props.group.id}-${entityType}.csv`;
    this.props.downloadCSVFromURL(url, filename, 'group');
  };

  hasRecords = () => {
    const { group } = this.props;
    return group && group.entities && group.entities.length > 0;
  };

  exportCSV = () => {
    const { group } = this.props;
    if (group.entities.length > 0) {
      getEntitiesListConfig().forEach(entity => {
        const found = group.entities.find(groupEntity => groupEntity.type_name === entity);
        if (found) {
          this.downloadCSV(entity);
        }
      });
    }
  };

  render() {
    const { group: { area_capture, id, name, status, type_name } } = this.props;
    return (
      <div styleName="group-sub-nav">
        <IconButton onClick={this.onBackClick}>
          <Icon color={colors.neutral.white}>arrow_back</Icon>
        </IconButton>
        <div styleName="sub-nav-icon">
          <GroupTypeIcon type={this.getGroupType()} size="1.5rem" color="FFFFFF" />
        </div>
        <div>{name}</div>
        <FlexSeparator />
        {/* Only show the '+' icon if the status is 'Open'.
            Check for the status id (i.e. '1') instead of the 'Open' string,
            since the status_name is set on the backend and we can
            edit the status on the frontend, and that doesn't set status_name
            only the id. */}
        {id && canEditGroups(type_name) && status === 1 && (
          <IconMenu
            icon={<Icon color={colors.neutral.white}>add</Icon>}
            iconButtonStyles={{}}
            MenuProps={{
              anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
              transformOrigin: { horizontal: 'center', vertical: 'top' }
            }}
            tooltip="Add records"
          >
            {area_capture === 0 && (
              <IconMenuItem icon={<DrawLineIcon />} onClick={this.goToMapSelection}>
                Add records by map selection
              </IconMenuItem>
            )}
            <IconMenuItem icon={<Icon>search</Icon>} onClick={this.openSearch}>
              Add records by searching
            </IconMenuItem>
          </IconMenu>
        )}
        {id && <ActionDownloadIconButton disabled={!this.hasRecords()} onClick={this.exportCSV} />}
        {id && (
          <IconMenu
            icon={<Icon color={colors.neutral.white}>more_vert</Icon>}
            iconButtonStyles={{}}
            MenuProps={{
              anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
              transformOrigin: { horizontal: 'center', vertical: 'top' }
            }}
            tooltip="more actions"
          >
            <IconMenuItem icon={<GanttChartIcon />} onClick={this.goToGanttChart}>
              View gantt chart
            </IconMenuItem>
            <IconMenuItem icon={<Icon>history</Icon>} onClick={this.goToActivityLog}>
              View activity log
            </IconMenuItem>
            {canEditGroups(type_name) && <Divider />}
            {canEditGroups(type_name) &&
              <IconMenuItem icon={<Icon>delete</Icon>} onClick={this.deleteGroup}>
                Delete group
              </IconMenuItem>
            }
          </IconMenu>
        )}
        <DeleteGroupDialog />
        <GroupsAddRecordsDialog />
      </div>
    );
  }
}

SubNav.propTypes = {
  downloadCSVFromURL: PropTypes.func,
  group: PropTypes.object,
  location: PropTypes.object,
  openDashboardDialog: PropTypes.func,
  push: PropTypes.func,
  setGroupPreFill: PropTypes.func
};

const mapStateToProps = ({ groups }) => ({
  group: groups.edit.group
});

const mapDispatchToProps = {
  downloadCSVFromURL,
  openDashboardDialog,
  push,
  setGroupPreFill
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SubNav));
