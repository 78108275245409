import React, { useCallback, useContext, useMemo } from 'react';
import { get, includes } from 'lodash';
import { CONFLICT_FILTERS } from '@components/overlap/constants';
import OverlapContext from '@components/overlap/overlap-context';
import { getEntityType } from '@constants/config';
import Divider from '@material-ui/core/Divider';
import FilterSelect from '@shared/filter-select';
import { buildMenuItem } from '@utils/overlap-utils';
import { pluralize } from '@utils/shared-utils';
import '../data-detail/data-detail.scss';

const DrawerFilters = () => {
  const {
    filter,
    filteredEntities,
    mainEntity,
    nonActionableOverlappingEntityTypes,
    setFilter,
    sticky
  } = useContext(OverlapContext);

  const nonActionableEntityMenuItems = useMemo(() => nonActionableOverlappingEntityTypes.map(entityType => ({
    id: entityType,
    name: getEntityType(entityType).label
  })), [nonActionableOverlappingEntityTypes]);

  const mainEntityType = useMemo(() => getEntityType(mainEntity?.type_name), [mainEntity?.type_name]);

  const menuItems = useMemo(() => [
    {id: CONFLICT_FILTERS.OPEN, name: 'Open conflicts'},
    {id: CONFLICT_FILTERS.OPPORTUNITIES, name: 'Opportunities'},
    {id: CONFLICT_FILTERS.PENDING, name: 'Pending conflicts'},
    {id: CONFLICT_FILTERS.RESOLVED, name: 'Resolved conflicts'},
    ...nonActionableEntityMenuItems
  ].filter(item => {
    // Remove these menu item types if the main entity doesn't allow resolve/revoke:
    if (!mainEntityType?.overlaps?.['allow-resolution'] &&
      includes([CONFLICT_FILTERS.OPEN, CONFLICT_FILTERS.PENDING, CONFLICT_FILTERS.RESOLVED], item.id)
    ) {
      return false;
    }
    // Also remove it, if it doesn't allow opportunities:
    if (!mainEntityType?.overlaps?.['allow-opportunities'] && item.id === CONFLICT_FILTERS.OPPORTUNITIES) {
      return false;
    }
    return true;
  }), [mainEntityType?.overlaps, nonActionableEntityMenuItems]);

  const currentFilterValue = useMemo(
    () => menuItems.filter(item => get(filter, item.id, true)).map(item => item.id),
    [menuItems, filter]
  );

  const onFilterChange = useCallback(event => {
    const itemIds = menuItems.map(item => item.id);
    const newFilter = {};
    itemIds.forEach(id => {
      newFilter[id] = includes(event.target.value, id);
    });
    setFilter(newFilter);
  }, [menuItems, setFilter]);

  const renderValue = useCallback(values => {
    const totalCount = menuItems.length;
    return `${values.length === totalCount ? 'All ' : ''}${values.length} selected`;
  }, [menuItems.length]);

  if (filteredEntities?.length > 0) {
    return (
      <div styleName={`details-content-list-subheader ${sticky ? 'details-toolbar-sticky' : ''}`}>
        <div styleName="details-content-list-subheader-title">
          {filteredEntities.length - 1}&nbsp;{pluralize(filteredEntities.length - 1, 'overlap')}
        </div>
        <div styleName="details-content-list-subheader-filter">
          {menuItems.length > 0 && (
            <FilterSelect
              displayEmpty
              isValueSelected
              multiple
              onChange={onFilterChange}
              renderValue={renderValue}
              value={currentFilterValue}
            >
              {menuItems.map(item => buildMenuItem(filter, item))}
            </FilterSelect>
          )}
        </div>
        <Divider />
      </div>
    );
  }
  return null;
};

export default DrawerFilters;
