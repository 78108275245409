import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'material-ui/SvgIcon';

const DrawCircleIcon = props => (
  <SvgIcon {...props} style={{...props.style, height: '22px', width: '24px'}}>
    <g transform="translate(2.000000, 2.000000)">
      <circle
        cx="10"
        cy="10"
        fill="#D8D8D8"
        fillOpacity="0.3"
        r="10"
        stroke={props.color}
        strokeDasharray="4"
        strokeWidth="3"
      />
      <circle cx="10" cy="10" fill={props.color} r="3.07692308" />
    </g>
  </SvgIcon>
);

DrawCircleIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object
};

export default DrawCircleIcon;
