/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { includes, isEmpty } from 'lodash';
import InputSearch from '@shared/input-search';
import { renderUserListItem } from '@utils/autocomplete-utils';
import { getUserAvatar } from '@utils/data-table/user';
import { shallowEqual } from '@utils/react-utils';
import './input-datatype-search.scss';

class InputDataTypeSearch extends Component {
  shouldComponentUpdate(nextProps, nextState) {  // eslint-disable-line no-unused-vars
    return (
      // If agency changes, re-render, else the filterFunc() will use the old
      // agency, since we didn't re-render.
      !shallowEqual(nextProps.agency, this.props.agency) ||
      !shallowEqual(nextProps.errors, this.props.errors) ||
      !shallowEqual(nextProps.values, this.props.values)
    );
  }

  renderInfoCard = entry => (
    <div styleName="input-datatype-search-info-container">
      <div styleName="info-icon">
        {getUserAvatar(entry, 'large')}
      </div>
      <div styleName="info-data">
        <div styleName="info-data-top">
          {entry.name}
          {!entry.is_active && (
            <div styleName="info-data-inactive">
              (Inactive)
            </div>
          )}
        </div>
        <div styleName="info-data-bottom">
          {entry.email}
          {entry.phone && <div styleName="bull">&bull;</div>}
          {entry.phone}
        </div>
      </div>
    </div>
  );

  renderInfoCards = () => {
    const { entries, values } = this.props;
    const selected = [];
    Object.keys(entries).forEach(key => {
      if (includes(values, parseInt(key, 10))) {
        selected.push(entries[key]);
      }
    });
    return selected.map(entry => this.renderInfoCard(entry));
  };

  render() {
    return (
      <div styleName="input-datatype-search-container">
        <InputSearch
          autocompleteContainerStyles={{ maxWidth: 'none' }}
          filterFunc={this.props.filterFunc}
          {...this.props}
          renderOption={(option, state) => { // eslint-disable-line no-unused-vars
            return renderUserListItem(option, this.props.entries, 'large');
          }}
        />
        {!isEmpty(this.props.values) && this.renderInfoCards()}
      </div>
    );
  }
}

InputDataTypeSearch.propTypes = {
  agency: PropTypes.number,
  dataType: PropTypes.string,
  disabled: PropTypes.bool,
  entries: PropTypes.object,
  errors: PropTypes.array,
  fieldName: PropTypes.string,
  filterFunc: PropTypes.func,
  limit: PropTypes.number,
  onChange: PropTypes.func,
  placeHolder: PropTypes.string,
  renderOption: PropTypes.func,
  values: PropTypes.any  // eslint-disable-line react/forbid-prop-types
};

const mapStateToProps = (state, props) => {
  const entries = state.dataTypes[props.dataType];
  return { entries };
};

export default connect(mapStateToProps, {})(InputDataTypeSearch);
