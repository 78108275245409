/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { uploadAttachment, storeAttachment } from '@actions/attachment-actions';
import {
  UPLOAD_RUNNING,
  UPLOAD_FINISHED
} from '@constants/file';
import { uploadStyle } from '@constants/mui-theme';
import { Icon, IconButton, Tooltip } from '@mui';
import Dialog from '@shared/dialogs/dialog';
import Dropzone from './dropzone';
import './file-upload.scss';

const FileUpload = ({
  children,
  contentType,
  objectId,
  parentId,
  isPublic
}) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState([]);
  const [status, setStatus] = useState([]);
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);

  const onOpen = useCallback(
    () => {
      setErrors([]);
      setFiles([]);
      setStatus([]);
      setOpen(true);
    },
    [setFiles, setOpen]
  );

  const onClose = useCallback(() => setOpen(false), [setOpen]);

  const onProgress = (event, index) => {
    // When using axio's onUploadProgress(), we must use functional-updates:
    //
    //     https://legacy.reactjs.org/docs/hooks-reference.html#functional-updates
    //
    // In order for setStatus() to work with the previous state.
    setStatus(prevStatus => {
      const newStatus = [...prevStatus];
      if (event) {
        newStatus[index] = event.loaded === event.total ? UPLOAD_FINISHED : UPLOAD_RUNNING;
      } else {
        // No event set, means it's an upload to the public API, which doesn't track progress.
        newStatus[index] = UPLOAD_FINISHED;
      }
      return newStatus;
    });
  };

  const onError = (error, index) => {
    setErrors(prevErrors => {
      const newErrors = [...prevErrors];
      newErrors[index] = error;
      return newErrors;
    });
  };

  const onFileReplace = (file, index) => {
    const attachment = {
      fileName: file.name,
      file,
      contentType,
      objectId,
      parentId
    };
    onError(null, index);
    dispatch(uploadAttachment(attachment, event => onProgress(event, index), error => onError(error, index), true));
  };

  const onFileChange = (acceptedFiles) => {
    // Set the uploaded files on the state:
    setFiles(acceptedFiles);

    acceptedFiles.forEach((file, index) => {
      const attachment = {
        fileName: file.name,
        file,
        contentType,
        objectId,
        parentId
      };
      if (isPublic) {
        dispatch(storeAttachment(attachment, event => onProgress(event, index)));
      } else {
        dispatch(uploadAttachment(attachment, event => onProgress(event, index), error => onError(error, index)));
      }
    });
  };

  const buildDialogTitle = () => {
    if (files.length > 0) {
      let finishedCount = 0;
      for (let index = 0; index < files.length; index++) {  // eslint-disable-line no-plusplus
        if (status[index] === UPLOAD_FINISHED) {
          finishedCount += 1;
        }
      }

      if (files.length === finishedCount) {
        // remove blank errors for check
        if (errors.filter(err => err).length > 0) {
          return 'Upload finished with errors';
        }
        return 'Upload finished';
      }
      return 'Uploading files';
    }
    return 'Upload files';
  };

  return (
    <div styleName="attachment-upload-container">
      {children ?
        <div styleName="attachment-upload-container" onClick={onOpen} role="presentation">
          {children}
        </div> :
        <div styleName="attachment-upload-container" onClick={onOpen} role="presentation">
          <Tooltip title="Upload File">
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton size="small">
                <Icon color={uploadStyle.uploadIconButton.color}>file_upload</Icon>
              </IconButton>
              <div styleName="attachment-icon-upload-label">
                UPLOAD
              </div>
            </span>
          </Tooltip>
        </div>
      }
      <Dialog dividers onClose={onClose} open={open} title={buildDialogTitle()}>
        <Dropzone onFilesChange={onFileChange} onFileReplace={onFileReplace} files={files} errors={errors} status={status} />
      </Dialog>
    </div>
  );
};

FileUpload.propTypes = {
  children: PropTypes.element,
  contentType: PropTypes.string,
  isPublic: PropTypes.bool,
  objectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default FileUpload;
