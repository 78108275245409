/**
 * Methods shared by all data-table data types.
 */
import React from 'react';
import BatchUploadForm from '@shared/batch-upload-form';
import TruncatedCell from '@shared/data-table/cells/truncated-cell';
import ListMore from '@utils/data-table/column-renderers/list-more';
import {
  getCategoryIcon,
  renderIconAndLabel,
  renderPriorityIconAndLabel
} from '@utils/icon-utils';
import { pluralize } from '@utils/shared-utils';

export const getCustomComponent = componentType => {
  switch (componentType) {
  case 'batch-upload-form': {
    return <BatchUploadForm />;
  }
  default:
    return null;
  }
};

const getDefinedStreets = streets => {
  if (streets) {
    return streets.split(',').filter(street => street !== '');
  }
  return [];
};

const getStreets = entity => {
  if (entity) {
    let streets = [];
    const polygons = entity.polygons;
    if (polygons > 0) {
      streets.push(`${polygons} ${pluralize(polygons, 'polygon')}`);
    }
    streets = streets.concat(getDefinedStreets(entity.on_street));
    const undefinedStreets = entity.undefined_streets;
    if (undefinedStreets > 0) {
      streets.push(`${undefinedStreets} undefined ${pluralize(undefinedStreets, 'street')}`);
    }
    return streets;
  }
  return [];
};

export const getOnStreet = (entity, dataType) => <ListMore dataType={dataType} list={getStreets(entity)} />;

export const getOwners = (entity, dataType) => (
  <TruncatedCell>
    <ListMore dataType={dataType} list={entity.owner_list} />
  </TruncatedCell>
);

const getGroupsTooltip = groups => (
  <div>
    {groups && groups.map((group, index) => (
      <div key={index}>
        {group}{index < groups.length - 1 ? ',' : ''}
      </div>
    ))}
  </div>
);

export const renderGroupsCell = (dataType, groups) => (
  <TruncatedCell tooltip={getGroupsTooltip(groups)}>
    <ListMore list={groups} dataType={dataType} />
  </TruncatedCell>
);

export const insertCategories = (entityType, categories) => {
  const keys = {};
  const values = {};
  if (categories) {
    categories.forEach(category => {
      const { icon, id, name, value } = category;
      // If there's an icon, use it to render this column:
      if (entityType && icon) {
        const categoryIcon = getCategoryIcon(entityType, id);
        keys[name] = <TruncatedCell>{renderIconAndLabel(value, categoryIcon)}</TruncatedCell>;
      } else
        // Also handle the 'priority' category:
        if (name === 'priority') {
          keys[name] = renderPriorityIconAndLabel(id);
        } else {
          // Else add the category value.

          // In case several category values of the same type exists
          // for a record, join them with a comma.
          if (!values[name]) {
            values[name] = [];
          }
          values[name].push(value);
          keys[name] = <TruncatedCell>{values[name].join(', ')}</TruncatedCell>;
        }
    });
  }
  return keys;
};
