/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import DetailsTextField from '@shared/details-text-field';
import DetailsSelectField from '@shared/details-select-field';

const Input = props => {
  const { select } = props;
  return (
    <div style={{ marginTop: '1rem' }}>
      { select ?
        <DetailsSelectField {...props} /> :
        <DetailsTextField {...props} />
      }
    </div>
  );
};

Input.propTypes = {
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  label: PropTypes.string,
  model: PropTypes.string,
  required: PropTypes.bool,
  select: PropTypes.object
};

export default Input;
