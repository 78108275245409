/*eslint-disable max-len*/
import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'material-ui/SvgIcon';

const WorkflowIcon = props => (
  <SvgIcon {...props} style={{...props.style}} viewBox="0 0 26 26">
    <path d="M21.9375 0H4.0625C1.81884 0 0 1.94004 0 4.3332V21.666C0 24.0591 1.81884 25.9992 4.0625 25.9992H21.9375C24.1812 25.9992 26 24.0591 26 21.666V4.3332C26 1.94004 24.1812 0 21.9375 0Z" fill="#8E24AA"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M13 20L5 6H13L16 10.5H17.5L20 15.5H21.5L26.0005 20.5V21.6663C26.0005 24.0597 24.181 26 21.9319 26L19.5 26L13 20Z" fill="#6A0D82"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M5 6V9.5H6.5V13.5H9V14.75H11.5V18.5H13V20H21V16.5H13V17.5H12.5V14.75H17V11.25H9V12.5H7.5V9.5H13V6H5Z" fill="#F5F5F5"/>
  </SvgIcon>
);

WorkflowIcon.propTypes = {
  style: PropTypes.object
};

export default WorkflowIcon;
