import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import EntityTypeIcon from '@icons/entity-type-icon';
import GroupTypeIcon from '@icons/group-type-icon';
import { getDashboardMenuConfig } from '@constants/config';
import { List, ListItem } from 'material-ui/List';
import Subheader from 'material-ui/Subheader';
import Divider from '@material-ui/core/Divider';
import { Icon } from '@mui';
import FlexSeparator from '@shared/helpers/flex-separator';
import OverflowTooltipableLabel from '@shared/overflow-tooltipable-label';
import { getDataType, getPortalPath } from '@utils/dashboard-utils';
import { isMenuSectionActive } from '@utils/dashboard-details-utils';
import { verifyPermissions } from '@utils/permission-utils';
import './menu.scss';

class Menu extends Component {
  constructor(props) {
    super(props);

    // Set the open state for all types with children, to false.
    const open = {};
    const types = this.getExpandableTypes();
    types.forEach(type => {
      open[type] = false;
    });
    this.state = { open };
  }

  componentDidMount = () => {
    // Expand each expandable type on mount if any children within that type is selected.
    const types = this.getExpandableTypes();
    const open = this.state.open;
    types.forEach(type => {
      if (this.getTopLevelDataType() === type && !this.state.open[type]) {
        open[type] = true;
      } else {
        // Check for when the top level type is plural:
        const singularType = type.replace(/s+$/, '');
        if (this.getTopLevelDataType() === singularType && !this.state.open[type]) {
          open[type] = true;
        }
      }
    });
    this.setState({ open: { ...open } });
  };

  // Return all menu items that are expandable (i.e. with children nodes):
  getExpandableTypes = () => {
    const types = [];
    getDashboardMenuConfig().forEach(section => {
      section.items.forEach(({ children, type }) => {
        if (children) {
          types.push(type);
        }
      });
    });
    return types;
  };

  getTopLevelDataType = () => getDataType(this.props.params, this.props.location);

  handleNestedClick = (listItem, type) => {
    this.setState({ open: { ...this.state.open, [type]: !this.state.open[type]} });
  };

  getIcon = (icon, type, active) => {
    if (icon === '[entity]') {
      const color = active ? '204ECB' : '757575';
      return <EntityTypeIcon type={type} size="1.5rem" color={color} />;
    }
    if (icon === '[group]') {
      const color = active ? '204ECB' : '757575';
      return <GroupTypeIcon type={type} size="1.5rem" color={color} />;
    }
    return (
      <Icon styleName="list-icon" style={{ fontSize: '1.5rem' }}>
        {icon}
      </Icon>
    );
  };

  buildLink = (dataType, subType) => {
    const subTypeField = subType ? `/${subType}` : '';
    const portal = getPortalPath();
    const to = `/${portal}/data/${dataType}${subTypeField}`;
    return <Link to={to} />;
  };

  getListItemLabel = label => <OverflowTooltipableLabel>{label}</OverflowTooltipableLabel>;

  renderNestedSectionItem = (item, type) => {
    if (!verifyPermissions(item.permissions)) {
      return null;
    }
    const { dataType } = this.props.params || {};
    const itemActive = item.type === dataType && this.getTopLevelDataType() !== dataType;
    const labelText = this.getListItemLabel(item.label);
    return (
      <ListItem
        containerElement={this.buildLink(type, item.type)}
        key={item.type}
        leftIcon={<div />}
        primaryText={labelText}
        styleName={itemActive ? 'active' : ''}
      />
    );
  };

  renderSectionSubheader = section => {
    const { dataType } = this.props.params || {};
    return (
      <Subheader
        styleName={
          isMenuSectionActive(section, dataType) ? 'title active-header' : 'title'
        }
        style={{ fontSize: '12px' }}
      >
        {section.title}
      </Subheader>
    );
  };

  renderSectionItem = sectionItem => {
    const { dataType } = this.props.params || {};
    const { children, icon, label, permissions, subtype, type } = sectionItem;

    if (!verifyPermissions(permissions)) {
      return null;
    }

    const effectiveType = subtype ? subtype : type;
    const active = subtype ? subtype === dataType && this.getTopLevelDataType() === type : type === this.getTopLevelDataType();

    let listProps = {
      containerElement: subtype ? this.buildLink(type, subtype) : this.buildLink(type),
      leftIcon: this.getIcon(icon, effectiveType, active),
      primaryText: this.getListItemLabel(label)
    };

    if (children) {
      listProps = {
        ...listProps,
        autoGenerateNestedIndicator: true,
        containerElement: <span />,
        onNestedListToggle: listItem => this.handleNestedClick(listItem, type),
        open: this.state.open[type],
        nestedItems: children.map(item => this.renderNestedSectionItem(item, type)),
        objectid: type,
        primaryTogglesNestedList: true
      };
    }
    return (
      <ListItem data-testid="dashboard-menu-list-item" {...listProps} key={effectiveType} styleName={active ? 'active' : ''} />
    );
  };

  renderSection = section => {
    const { items, permissions, title } = section;

    if (!verifyPermissions(permissions)) {
      return null;
    }

    return (
      <div data-testid="dashboard-menu-list" key={title}>
        <List style={{ backgroundColor: 'white' }}>
          {this.renderSectionSubheader(section)}
          {items.map(item => this.renderSectionItem(item))}
        </List>
        <Divider />
      </div>
    );
  };

  render() {
    return (
      <div data-testid="dashboard-menu" styleName="dashboard-menu-container">
        {getDashboardMenuConfig().map(section => this.renderSection(section))}
        <FlexSeparator />
      </div>
    );
  }
}

Menu.propTypes = {
  changeActiveDataTable: PropTypes.func,
  location: PropTypes.object,
  params: PropTypes.object
};

export default Menu;
