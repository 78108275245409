import React, { Fragment, useContext } from 'react';
import MarkersModal from '@components/map/markers-modal';
import MapSegment from '@components/overlap/map-segment';
import OverlapContext from '@components/overlap/overlap-context';
import { dotmapsBlue } from '@constants/colors';
import DotmapsLoader from '@shared/dotmaps-loader';
import '../data-detail/data-detail.scss';

const MapComponents = () => {
  const { segments } = useContext(OverlapContext);

  if (segments) {
    return (
      <Fragment>
        <MarkersModal small />
        {segments.filter(segment => segment?.shape).map(segment => (
          <MapSegment key={segment.id} segment={segment} />
        ))}
      </Fragment>
    );
  }

  return <DotmapsLoader color={dotmapsBlue} display key="loader" />;
};

export default MapComponents;
