/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { setDatatableFilter, setAutocompleteSelection } from '@actions/data-table-actions';
import { getTopBarConfig } from '@constants/config';
import { autocompleteSearchStyles } from '@constants/mui-theme';
import { getFilters } from '@selectors/data-table-selector';
import AsyncInputSearch from '@shared/async-input-search';
import {
  getUniqueSearchKeys,
  getFiltersFromSuggestions,
  getDeletedFilters,
  renderSearchGroup,
  renderSearchListItem
} from '@utils/autocomplete-utils';
import './search-autocomplete.scss';

class SearchAutocomplete extends Component {
  getNewFilters = (suggestions, removedKeys) => {
    if (removedKeys.length) {
      return getDeletedFilters(removedKeys);
    }
    return getFiltersFromSuggestions(suggestions);
  };

  // Build a key to store/retrieve the selected value for the autocomplete results.
  getKey = () => {
    const { dataType, subType } = this.props;
    // There's no sub type (i.e. dataType='permit'):
    if (dataType === subType) {
      return dataType;
    }
    // If there's a sub type (i.e. dataType='group', subType='relational'):
    return `${dataType}-${subType}`;
  };

  onChange = suggestions => {
    const { selection, dataType, subType } = this.props;
    const key = this.getKey();
    const removedKeys = R.difference(R.propOr({}, key, selection), suggestions);
    const selected = getUniqueSearchKeys(suggestions);
    this.props.setAutocompleteSelection(key, selected);
    const newFilters = this.getNewFilters(suggestions, removedKeys);
    this.props.setDatatableFilter(dataType, subType, { ...newFilters, offset: 0 });
  };

  getPlaceHolder = () => {
    const { dataType, subType } = this.props;
    const config = getTopBarConfig()[dataType];
    if (config.nested) {
      return `Search ${config[subType].searchLabel}`;
    }
    return `Search ${config.searchLabel}`;
  };

  toOptions = results => results.suggestions.map(
    (
      {
        arg,
        display,
        extra,
        id,
        icon,
        type,
        type_label
      }
    ) => (
      {
        arg,
        id,
        icon,
        extra,
        title: display,
        type,
        type_label,
        value: `${type}-${id}`
      }
    )
  );

  render() {
    const key = this.getKey();
    return (
      <div data-testid="search-autocomplete" styleName="search-autocomplete-container">
        <AsyncInputSearch
          {...autocompleteSearchStyles.topBar}
          avatarProps={{ type: 'search' }}
          dataType={this.props.dataType}
          fieldName="top-bar-search"
          filters={this.props.filters}
          groupBy={option => option.type_label}
          limitTags={1}
          onChange={this.onChange}
          placeHolder={this.getPlaceHolder()}
          renderGroup={renderSearchGroup}
          renderOption={(option, state) => { // eslint-disable-line no-unused-vars
            return renderSearchListItem(this.props.dataType, option, this.props.agencyTypes, this.props.taskStatuses);
          }}
          subType={this.props.subType}
          toOptions={this.toOptions}
          value={this.props.selection[key]}
          variant="outlined"
        />
      </div>
    );
  }
}

SearchAutocomplete.propTypes = {
  agencyTypes: PropTypes.object,
  dataType: PropTypes.string,
  filters: PropTypes.object,
  selection: PropTypes.object,
  setAutocompleteSelection: PropTypes.func,
  setDatatableFilter: PropTypes.func,
  subType: PropTypes.string,
  suggestions: PropTypes.array,
  taskStatuses: PropTypes.object
};

const mapStateToProps = (state, props) => ({
  ...state.dashboard.autocomplete,
  agencyTypes: state.dataTypes.agency_type,
  filters: getFilters(state, props),
  selection: state.dataTables.autocomplete,
  taskStatuses: state.dataTypes.task_status
});

const mapDispatchToProps = {
  setDatatableFilter,
  setAutocompleteSelection
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchAutocomplete);
